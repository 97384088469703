export const ClickCollect = () => (
  <svg
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M24 22v2H0v-2h24ZM12 0c4.423 0 8 3.443 8 7.7 0 5.775-8 14.3-8 14.3S4 13.475 4 7.7C4 3.443 7.577 0 12 0Zm0 2.2c-3.154 0-5.714 2.464-5.714 5.5 0 3.135 3.337 7.931 5.714 10.868 2.423-2.959 5.714-7.7 5.714-10.868 0-3.036-2.56-5.5-5.714-5.5Zm0 2.75c1.02 0 1.964.524 2.474 1.375.51.85.51 1.9 0 2.75-.51.85-1.453 1.375-2.474 1.375-1.578 0-2.857-1.231-2.857-2.75S10.422 4.95 12 4.95Z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fill="#000" fillOpacity={0.54}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default ClickCollect;
