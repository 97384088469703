import cn from 'classnames';
import { ComponentType, FC, ReactNode, useCallback, useMemo } from 'react';

export interface IChildrenWrapperProps {
  isOpen?: boolean;
  children?: ReactNode;
  contentClassName?: string;
}

export interface IAccordionBaseProps {
  isOpen?: boolean;

  onChange?(isOpen: boolean): void;

  title?: ReactNode;
  children?: ReactNode;

  className?: string;
  headerClassName?: string;
  titleClassName?: string;
  iconClassName?: string;
  openedClassName?: string;
  closedClassName?: string;
  contentClassName?: string;
  'data-testid'?: string;

  openedIcon?: ComponentType;
  closedIcon?: ComponentType;

  renderIcon?: (isOpen?: boolean) => ReactNode;

  childrenWrapper?: ComponentType<IChildrenWrapperProps>;
}

export const DefaultChildrenWrapper: FC<IChildrenWrapperProps> = ({
  isOpen,
  contentClassName,
  children
}) => (isOpen ? <div className={contentClassName}>{children}</div> : null);

export const AccordionBase: FC<IAccordionBaseProps> = ({
  className,
  isOpen,
  'data-testid': dataTestId,
  title,
  children,
  headerClassName,
  titleClassName,
  iconClassName,
  openedClassName,
  closedClassName,
  contentClassName,
  onChange,
  openedIcon: Opened,
  closedIcon: Closed,
  childrenWrapper: ChildrenWrapper = DefaultChildrenWrapper,
  renderIcon
}) => {
  const onClick = useCallback(() => {
    onChange?.(!isOpen);
  }, [onChange, isOpen]);

  const rendecIconContent = (open?: boolean) => {
    if (renderIcon) {
      return renderIcon(open);
    }

    const Icon = open ? Opened : Closed;

    return Icon ? <Icon /> : null;
  };

  return (
    <div
      data-testid={dataTestId || 'accordion-base'}
      className={cn(
        'w-full',
        isOpen ? openedClassName : closedClassName,
        className
      )}
    >
      <div
        className={cn(
          'flex flex-row items-center justify-between w-full',
          headerClassName
        )}
        onClick={onClick}
      >
        <div className={titleClassName}>{title}</div>
        <div className={iconClassName}>{rendecIconContent(isOpen)}</div>
      </div>
      <ChildrenWrapper isOpen={isOpen} contentClassName={contentClassName}>
        {children}
      </ChildrenWrapper>
    </div>
  );
};
