import cn from 'classnames';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useScrollerClick } from './hooks';
import { ScrollItemsState } from './ScrollItemsState';

export interface IPageScrollerProps {
  activeIndex: number;
  indexes: number[];
}

export function PageScroller(props: IPageScrollerProps) {
  const { indexes } = props;
  const scrollItems = useAtomValue(ScrollItemsState);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(true);
  const onClick = useScrollerClick();

  const toggleClass = (item: HTMLDivElement, idx: number) => {
    setActiveIndex(idx);
    onClick(item);
    setIsScrolling(true);
  };

  const indexMap = useMemo(() => {
    const map = new Map();
    const sortedIndexes = indexes.sort((a, b) => a - b);
    scrollItems.map((_, idx) => {
      map.set(sortedIndexes[idx], idx);
    });

    return map;
  }, [scrollItems, indexes]);

  useEffect(() => {
    if (isScrolling && indexMap.get(props.activeIndex) === activeIndex) {
      setIsScrolling(false);
    }
  }, [isScrolling, props.activeIndex, activeIndex]);

  useEffect(() => {
    if (!isScrolling) {
      setActiveIndex(indexMap.get(props.activeIndex));
    }
  }, [props.activeIndex, isScrolling]);

  return (
    <>
      {scrollItems.map((item, idx) => (
        <button
          className={cn(
            'home-scrollto',
            idx === activeIndex ? 'block-active' : null
          )}
          onClick={() => toggleClass(item, idx)}
          key={`scrollbutton${idx}`}
        />
      ))}
    </>
  );
}

export default PageScroller;
