export interface IShadeProps {
  color: string;
  width: number;
  height: number;
}

export const Shade = ({ color, width, height }: IShadeProps) => (
  <div
    className={`inline-block mr-2 rounded-full w-${width} h-${height} bg-[${color}]`}
  />
);

export default Shade;
