export const ADD_TO_BAG_BTN = 'addToBagBtn';
export const PRODUCT_GRID = 'productGrid';
export const PRODUCT_SORTING_DESKTOP = 'productSortingDesktop';
export const PRODUCT_SORTING_MOBILE = 'productSortingMobile';
export const PRODUCT_FILTERS = 'productFilters';
export const PRODUCT_LISTING = 'productListing';
export const PRODUCT_CARD = 'productCard';
export const PRODUCT_TOUT = 'productTout';
export const PRODUCT_RATING = 'productRating';
export const ORIGINAL_PRICE_FORMATTED = 'originalPriceFormatted';
export const PRICE_FORMATTED = 'priceFormatted';
export const ADD_TO_FAV = 'addToFav';
export const QUICK_SHOP_BTN = 'quickShopBtn';
export const QUICK_SHOP_MODAL = 'quickShopModal';
export const PRODUCT_FAVORITE_SUCCESS_DIALOG = 'productFavoriteDialog';
export const PRODUCT_FAVORITE_CONTINUE_SHOPPING_BTN =
  'productfavoriteDialogContinueShopping';
export const PRODUCT_FAVORITE_MANAGE_LIST = 'productfavoriteDialogManageList';
