export const FacebookSquared = () => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs></defs>
    <g transform="translate(-3 -3)" fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M20.375 3.97v15.435c0 .259-.09.485-.272.68a.912.912 0 0 1-.698.29h-4.42v-6.748h2.249l.349-2.599h-2.599V9.36c0-.44.078-.75.233-.93.207-.233.556-.35 1.047-.35h1.396V5.716a21.85 21.85 0 0 0-2.017-.078c-1.008 0-1.822.298-2.443.892-.62.595-.93 1.448-.93 2.56v1.94h-2.25v2.598h2.25v6.748h-8.3a.912.912 0 0 1-.699-.29.963.963 0 0 1-.271-.68V3.97c0-.285.09-.518.271-.699A.945.945 0 0 1 3.97 3h15.435c.259 0 .485.09.68.271.193.181.29.414.29.699Z" />
      </g>
    </g>
  </svg>
);

export default FacebookSquared;
