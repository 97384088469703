import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Minus,
  Plus
} from '@gemini/shared/ui/atoms/icons';

export enum IconTypes {
  default = 'default',
  arrowsRightLeft = 'arrows-right-left',
  arrowsUpDown = 'arrows-up-down',
  plusMinus = 'plus-minus'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ITogglesMap = Record<string, any>;

const DefaultOpened = () => null;
const DefaultClosed = () => null;

export const togglesMap: ITogglesMap = {
  default: {
    opened: DefaultOpened,
    closed: DefaultClosed
  },
  'arrows-right-left': {
    closed: ArrowRight,
    opened: ArrowLeft
  },
  'arrows-up-down': {
    closed: ArrowDown,
    opened: ArrowUp
  },
  'plus-minus': {
    opened: Minus,
    closed: Plus
  }
};
