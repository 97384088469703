import { dynamicConditional } from '@gemini/shared/ui/utils/dynamic-load';
import type {
  INavMobile as INavMobileOriginal,
  INavMobileProps as INavMobilePropsOriginal
} from './NavMobile';

export type INavMobile = INavMobileOriginal;
export type INavMobileProps = INavMobilePropsOriginal;
export const NavMobile = dynamicConditional<INavMobile>(
  ({ isOpenHamburger }) => !!isOpenHamburger,
  () => import('./NavMobile')
);
