import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  ITemplateFieldCheckbox,
  ITemplateFieldElcTextFormat,
  ITemplateFieldMantleCustomTextFormat,
  ITemplateFieldMantleTemplate,
  ITemplateFieldString,
  TemplateType,
  usePageDataContext
} from '@gemini/shared/services/content/next-mantlecms';
import { TemplateRenderer } from '@gemini/shared/ui/templates/template-renderer';
import { getQuestionAndAnswer, getSuitableTemplate } from './FaqItem';

interface IFormatter {
  data: {
    header?:
      | ITemplateFieldString
      | ITemplateFieldElcTextFormat
      | ITemplateFieldMantleCustomTextFormat;
    subheader?:
      | ITemplateFieldString
      | ITemplateFieldElcTextFormat
      | ITemplateFieldMantleCustomTextFormat;
    openedOnLoad?: ITemplateFieldCheckbox;
    widthRules?: ITemplateFieldMantleTemplate;
  };
}

export interface IFaqProps {
  data: {
    formatter: IFormatter;
    templates?: TemplateType[];
  };
}

export const Faq = ({ data: { formatter, templates } }: IFaqProps) => {
  const { header, subheader, openedOnLoad } = formatter.data;

  const [faqOpened, setFaqOpened] = useState<boolean>(
    Boolean(openedOnLoad?.data)
  );
  const router = useRouter();

  useEffect(() => setFaqOpened(Boolean(openedOnLoad?.data)), [router?.asPath]);

  const { metadata, title, language, created, modified } = usePageDataContext();
  const questions: Array<object> = [];
  const mainEntity: Array<object> = [];
  const langcode = language.replace(/-e-/g, '-');

  templates?.forEach((template: TemplateType, index: number) => {
    if (template.component === 'FaqItem' && metadata) {
      mainEntity.push({
        '@id': `${metadata.canonical}#question-${index + 1}`
      });
      const { question, answer } = getQuestionAndAnswer(template);
      questions.push({
        '@type': 'Question',
        '@id': `${metadata.canonical}#question-${index + 1}`,
        position: index + 1,
        name: question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: answer,
          inLanguage: langcode
        },
        inLanguage: langcode
      });
    }
  });

  const schemaData = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': ['FAQPage'],
        '@id': `${metadata?.canonical}#faq`,
        url: metadata?.canonical,
        name: metadata?.title || title,
        datePublished: created || '',
        dateModified: modified || '',
        description: metadata?.description,
        mainEntity,
        inLanguage: langcode
      },
      questions
    ]
  };

  return (
    <>
      {questions.length && (
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
          />
        </Head>
      )}
      <div className="faqs">
        {header && getSuitableTemplate(header, 'faqs__header text-center')}
        <div
          className={`faqs-container flex items-center justify-center text-center cursor-pointer`}
          onClick={() => setFaqOpened(!faqOpened)}
        >
          <div className={`faq__ghost-plus`}></div>
          {subheader && getSuitableTemplate(subheader, 'faqs__subheader', 'h2')}
          <span className={`faq-plus ${faqOpened ? 'open' : ''}`} />
        </div>
        <div className={`faqs-content ${!faqOpened ? 'hidden' : ''}`}>
          {templates?.map((template: TemplateType, index: number) => (
            <TemplateRenderer
              key={`${template.component}-${index}`}
              {...template}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Faq;
