export const MoreVertical = () => (
  <svg
    width={4}
    height={16}
    viewBox="0 0 4 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-10 -4)" fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Z" />
      </g>
    </g>
  </svg>
);

export default MoreVertical;
