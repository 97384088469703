import Script from 'next/script';
import {
  BANNER_VIEWED_SESSION_STORAGE_KEY,
  BANNER_VIEWED_VALUE
} from './banner';
import { HIDE_PROMO_BANNER_CSS } from './styles';

export function PromoBannerScript() {
  return (
    <Script id="hide-banner" strategy="beforeInteractive">
      {`
      const value = sessionStorage.getItem(${JSON.stringify(
        BANNER_VIEWED_SESSION_STORAGE_KEY
      )});
      if (value === ${JSON.stringify(BANNER_VIEWED_VALUE)}) {
        const style = ${JSON.stringify(HIDE_PROMO_BANNER_CSS)};
        const styleElem = document.createElement('style');
        styleElem.setAttribute('id', 'banner_viewed_style');
        styleElem.innerHTML = style;
        document.head.appendChild(styleElem);
      }
    `}
    </Script>
  );
}
