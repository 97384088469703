import { atom } from 'jotai';

export enum ModalPosition {
  TOP = 'TOP',
  CENTER = 'CENTER'
}

export const loyaltySignupModal = atom<{
  isOpen: boolean;
  position: ModalPosition;
}>({ isOpen: false, position: ModalPosition.TOP });
