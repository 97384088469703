export const Lock = () => {
  return (
    <svg
      width={14}
      height={19}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(-5 -2)" fill="none" fillRule="evenodd">
        <g fill="#000" fillOpacity={0.54}>
          <path d="M14.58 8.68V7a2.5 2.5 0 0 0-.35-1.28c-.233-.4-.55-.717-.95-.95A2.5 2.5 0 0 0 12 4.42a2.5 2.5 0 0 0-1.28.35c-.4.233-.717.55-.95.95A2.5 2.5 0 0 0 9.42 7v1.68h5.16ZM12 16.18c.453 0 .847-.167 1.18-.5.333-.333.5-.727.5-1.18 0-.453-.167-.847-.5-1.18a1.614 1.614 0 0 0-1.18-.5c-.453 0-.847.167-1.18.5-.333.333-.5.727-.5 1.18 0 .453.167.847.5 1.18.333.333.727.5 1.18.5Zm5-7.5c.453 0 .847.16 1.18.48.333.32.5.707.5 1.16v8.36c0 .453-.167.84-.5 1.16-.333.32-.727.48-1.18.48H7c-.453 0-.847-.16-1.18-.48a1.55 1.55 0 0 1-.5-1.16v-8.36c0-.453.167-.84.5-1.16.333-.32.727-.48 1.18-.48h.82V7c0-.76.187-1.46.56-2.1.373-.64.88-1.147 1.52-1.52s1.34-.56 2.1-.56 1.46.187 2.1.56c.64.373 1.147.88 1.52 1.52s.56 1.34.56 2.1v1.68H17Z" />
        </g>
      </g>
    </svg>
  );
};

export default Lock;
