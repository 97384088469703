import ChatBubbleFilled from './ChatBubbleFilled';
import Email from './Email';
import Location from './Location';
import Phone from './Phone';

interface ISVGMapperProps {
  type?: string;
  height?: string;
  width?: string;
  fill?: string;
}

export const SVGMapper = ({ type }: ISVGMapperProps) => {
  let svg = null;

  switch (type) {
    case 'Chat':
      svg = <ChatBubbleFilled />;
      break;
    case 'Mail':
      svg = <Email />;
      break;
    case 'Phone':
      svg = <Phone />;
      break;
    case 'LocationFull':
      svg = <Location />;
      break;
    default:
      return null;
  }

  return <div>{svg}</div>;
};

export default SVGMapper;
