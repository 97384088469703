import elcAutoReplenishModal from './elc-styles/elc-auto-replenish-modal.module.scss';
import elcServiceSearch from './elc-styles/elc-service-search.module.scss';
import pgServiceCart from './elc-styles/pg-service-cart.module.scss';

export { ReactComponent as LogoEstee } from './public/assets/logo-estee.svg';
export { ReactComponent as IconShoppingBag } from './public/assets/icons/ShoppingBag.svg';
export { ReactComponent as IconSearch } from './public/assets/icons/Search.svg';
export { ReactComponent as IconArrowLeft } from './public/assets/icons/ArrowLeft.svg';
export { ReactComponent as IconArrowRight } from './public/assets/icons/ArrowRight.svg';
export { ReactComponent as IconArrowLeftLarge } from './public/assets/icons/ArrowLeftLarge.svg';
export { ReactComponent as IconArrowRightLarge } from './public/assets/icons/ArrowRightLarge.svg';
export { ReactComponent as IconClose } from './public/assets/icons/Close.svg';
export { ReactComponent as IconMenu } from './public/assets/icons/Menu.svg';
export { ReactComponent as LogoMobile } from './public/assets/icons/logo_mobile.svg';
export { ReactComponent as IconPlay } from './public/assets/icons/Play.svg';
export { ReactComponent as IconPlaySmall } from './public/assets/icons/PlaySmall.svg';
export { ReactComponent as InfoFilled } from './public/assets/icons/InfoFilled.svg';
export { ReactComponent as IconHeart } from './public/assets/icons/Heart.svg';

export { elcServiceSearch, elcAutoReplenishModal, pgServiceCart };
