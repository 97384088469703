import cn from 'classnames';
import { CSSProperties } from 'react';
import styles from '../basic-tout.module.scss';

interface IBasicToutMediaWrapper {
  children: React.ReactElement | React.ReactElement[];
  bgColor: string | undefined;
}

const cl = (className: string) => styles[className] ?? '';

export const BasicToutMediaWrapper = ({
  children,
  bgColor
}: IBasicToutMediaWrapper) => {
  const backgroundColor: CSSProperties = bgColor
    ? { backgroundColor: bgColor }
    : {};

  return (
    <div
      className={cn(
        cl('basic-tout__media-wrapper'),
        'basic-tout__media-wrapper'
      )}
      style={backgroundColor}
    >
      {children}
    </div>
  );
};
