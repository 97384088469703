export const CheckCircle = () => (
  <svg
    width={20}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 2c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8Zm4.59 3.58L10 14.17l-2.59-2.58L6 13l4 4 8-8-1.41-1.42Z"
        id="a"
      />
    </defs>
    <g transform="translate(-2 -2)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fill="#000" fillOpacity={0.54}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default CheckCircle;
