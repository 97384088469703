import React from 'react';
import { ITemplateFieldMantleCustomTextFormat } from '@gemini/shared/services/content/next-mantlecms';

export interface IMantleCustomTextProps {
  options: ITemplateFieldMantleCustomTextFormat;
  className?: string;
}

export const MantleCustomText = (props: IMantleCustomTextProps) => {
  const {
    options: {
      data: {
        html_tag = 'div',
        font_classes = '',
        font_color = '',
        font_style = '',
        text_content = ''
      }
    },
    className: classes = ''
  } = props;

  if (!html_tag || !text_content) {
    return null;
  }

  const Element = html_tag as unknown as typeof React.Component;

  return (
    <Element
      className={`${classes} ${font_classes}`}
      dangerouslySetInnerHTML={{ __html: text_content }}
    />
  );
};

export default MantleCustomText;
