import { useMemo } from 'react';
import {
  IItemType,
  ProductType,
  SkuType
} from '@gemini/shared/services/products/catalog';
import { IGridItem } from '@gemini/shared/services/products/next-sd-prodcat';
import { useProductContext } from './productContext';

export const useProductGridData = (items: IGridItem[]) => {
  const { products: allProducts, useUpdateProducts } = useProductContext();

  const contextProductsObject = useMemo(
    () =>
      allProducts.reduce(
        (prs: Record<string, ProductType>, pr: ProductType) => {
          prs[pr.productId] = pr;

          return prs;
        },
        {}
      ),
    [allProducts]
  );

  const { pendingIds, productData } = useMemo(() => {
    const configItems = items || [];
    const productIds = configItems.reduce((ids: string[], item) => {
      if (item.type === IItemType.PRODUCT) {
        ids.push(item.params.id);
      }

      return ids;
    }, []);

    if (allProducts.length === 0) {
      return { pendingIds: productIds, productData: [] };
    }

    const productsArray =
      productIds.length === configItems.length
        ? []
        : Object.values(contextProductsObject);

    return configItems.reduce(
      (products, { type, params: { id } }) => {
        if (type === IItemType.PRODUCT) {
          const product = contextProductsObject[id];

          if (product) {
            products.productData.push(product);
          } else {
            products.pendingIds.push(id);
          }
        } else if (type === IItemType.SKU) {
          const product = productsArray.find(({ skus }) =>
            skus?.some((elem: SkuType) => elem.materialCode?.includes(id))
          );

          if (product) {
            products.productData.push({
              ...product,
              skus:
                product.skus.length > 1
                  ? [
                      {
                        ...product.skus.find((sku) =>
                          sku.materialCode?.includes(id)
                        )
                      } as SkuType
                    ]
                  : product.skus
            });
          }
        }

        return products;
      },
      { pendingIds: [] as string[], productData: [] as ProductType[] }
    );
  }, [items, contextProductsObject]);

  return {
    useUpdateProducts,
    pendingIds,
    productData
  };
};
