export const ArrowDropDown = () => (
  <svg
    width={11}
    height={6}
    viewBox="0 0 11 6"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon id="path-3" points="10 8.8817842e-16 5 5 0 8.8817842e-16" />
    </defs>
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g transform="translate(0.500000, 0.500000)">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-3" />
        </mask>
        <g id="path-3" />
        <g
          mask="url(#mask-2)"
          fill="#000000"
          fillOpacity={0.54}
          fillRule="nonzero"
        >
          <g transform="translate(-7.000000, -10.000000)" id="Rectangle">
            <rect x={0} y={0} width={24} height={24} />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowDropDown;
