import { useEffect } from 'react';
import { useBreakpoint } from './useBreakpoint';

export type ScreenBreakpoint = 'sm' | 'md' | 'lg' | 'xl';

export const SCREEN_BREAKPOIT_CLASS_MAPPING: Record<ScreenBreakpoint, string> =
  {
    sm: 'block md:hidden',
    md: 'hidden md:block lg:hidden',
    lg: 'hidden lg:block xl:hidden',
    xl: 'hidden xl:block'
  };

export const SCREEN_BREAKPOINT_CLASS_AND_UP_MAPPING: Record<
  ScreenBreakpoint,
  string
> = {
  sm: 'block',
  md: 'hidden md:block',
  lg: 'hidden lg:block',
  xl: 'hidden xl:block'
};

const BREAKPOINTS_ACTIVE_PRIORITY_MAP: Record<ScreenBreakpoint, number> = {
  xl: 1,
  lg: 2,
  md: 3,
  sm: 4
};

const BREAKPOINTS_SIZE_SMALL_TO_BIG_MAP: Record<ScreenBreakpoint, number> = {
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4
};

const BREAKPOINTS_ACTIVE_PRIORITY: ScreenBreakpoint[] = Object.keys(
  BREAKPOINTS_ACTIVE_PRIORITY_MAP
)
  .map<ScreenBreakpoint>((k: string) => k as unknown as ScreenBreakpoint)
  .sort(
    (bp1: ScreenBreakpoint, bp2: ScreenBreakpoint) =>
      BREAKPOINTS_ACTIVE_PRIORITY_MAP[bp1] -
      BREAKPOINTS_ACTIVE_PRIORITY_MAP[bp2]
  );

export const BREAKPOINTS_SIZE_SMALL_TO_BIG: ScreenBreakpoint[] = Object.keys(
  BREAKPOINTS_SIZE_SMALL_TO_BIG_MAP
)
  .map<ScreenBreakpoint>((k: string) => k as unknown as ScreenBreakpoint)
  .sort(
    (bp1: ScreenBreakpoint, bp2: ScreenBreakpoint) =>
      BREAKPOINTS_SIZE_SMALL_TO_BIG_MAP[bp1] -
      BREAKPOINTS_SIZE_SMALL_TO_BIG_MAP[bp2]
  );

export type IUseScreenBreakpointResult = {
  [key in ScreenBreakpoint]: boolean;
} & {
  active: ScreenBreakpoint;
};

export const useScreenBreakpoint = (): IUseScreenBreakpointResult => {
  const result: IUseScreenBreakpointResult = {
    xl: false,
    lg: false,
    md: false,
    sm: true,
    active: BREAKPOINTS_ACTIVE_PRIORITY[0]
  };

  for (const bp of BREAKPOINTS_ACTIVE_PRIORITY) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isMobile } = useBreakpoint(SCREEN_BREAKPOIT_CLASS_MAPPING[bp], {
      name: bp
    });

    result[bp] = isMobile;
  }

  const active =
    BREAKPOINTS_ACTIVE_PRIORITY.find((bp) => result[bp]) ??
    BREAKPOINTS_SIZE_SMALL_TO_BIG[0];

  result.active = active;

  return result;
};

export const useOnWindowResize = (fn: EventListener) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', fn);

      return () => window.removeEventListener('resize', fn);
    }

    return () => {
      /* noop */
    };
  }, [fn]);
};
