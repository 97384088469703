export const SignOut = (props: any) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M24 24H0V0h24z" />
      <path
        d="M13.91 8.41 12.5 7l-5 5 5 5 1.41-1.41L11.33 13H21v-2h-9.67l2.58-2.59ZM5 21h14a2 2 0 0 0 2-2v-4h-2v4H5V5h14v4h2V5a2 2 0 0 0-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2Z"
        fill="#707070"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SignOut;
