export const CreditCard = () => (
  <svg
    width={20}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M16.37 4.068a1.171 1.171 0 0 1 1.494.71l1.222 3.424h1.465c.833 0 1.448.74 1.448 1.562v8.49a1.48 1.48 0 0 1-1.448 1.544H6.49a1.51 1.51 0 0 1-.8-.246 1.1 1.1 0 0 0 .724.083 1.171 1.171 0 0 1-1.317-.759l-3.03-8.455a1.171 1.171 0 0 1 .715-1.5Zm4.853 8.907H5.793v5.284a.727.727 0 0 0 .698.76h14.06a.702.702 0 0 0 .666-.76l.006-5.284ZM9.338 14.097a.26.26 0 0 1 .26.26v1.868a.26.26 0 0 1-.26.26H7.469a.26.26 0 0 1-.26-.26v-1.868a.26.26 0 0 1 .26-.26h1.869Zm9.808 1.581a.318.318 0 1 1 0 .636H14.77a.318.318 0 0 1 0-.636h4.377Zm.255-1.433c.032 0 .057.072.057.11v.459a.061.061 0 0 1-.057.065h-8.309a.061.061 0 0 1-.06-.065v-.46c0-.037.028-.109.06-.109Zm1.15-5.24H6.484a.723.723 0 0 0-.697.759v1.308h15.43V9.764a.7.7 0 0 0-.666-.759Z"
        id="a"
      />
    </defs>
    <g transform="translate(-2 -4)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fill="#000" fillOpacity={0.64}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default CreditCard;
