import { useMemo } from 'react';
import { ITemplateFieldString } from '@gemini/shared/services/content/next-mantlecms';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import { FOOTER_EMAIL_SIGN_UP } from '@gemini/shared/utils/testing';
import { SignUpForm } from './SignUpForm';

interface IEmailSignUpData {
  newsletterPlaceholder: ITemplateFieldString;
  newsletterSubmit: ITemplateFieldString;
  emailTermsText: ITemplateFieldString;
  newsletterSuccessText: ITemplateFieldString;
  newsletterSignupHeader: ITemplateFieldString;
}

export interface IEmailSignUpCMSData {
  component: string;
  data: IEmailSignUpData;
  dataMobile: IEmailSignUpData | null;
}

export const emailSignUpDataMapper = (data: IEmailSignUpData) => {
  const {
    newsletterPlaceholder,
    newsletterSubmit,
    emailTermsText,
    newsletterSuccessText,
    newsletterSignupHeader
  } = data;

  return {
    placeholder: newsletterPlaceholder.data,
    submitText: newsletterSubmit.data,
    termsText: emailTermsText.data,
    successText: newsletterSuccessText.data,
    headerText: newsletterSignupHeader.data || 'Sign Up For Updates'
  };
};

interface IEmailSignUpProps {
  emailSignUpData: IEmailSignUpCMSData;
}

const params = {
  _SUBMIT: 'email_signup',
  LAST_SOURCE: 'gnav',
  COUNTRY_ID: '1',
  LANGUAGE_ID: '48',
  redirect_or_text: 'redirect',
  redirect: '',
  PC_EMAIL_PROMOTIONS: '1',
  VALIDATE_TERMS: '1'
};

export const EmailSignUp = ({ emailSignUpData }: IEmailSignUpProps) => {
  const { isMobile, isDesktop } = useBreakpoint('block md:hidden');
  const emailSignUpProps = useMemo(
    () => emailSignUpDataMapper(emailSignUpData.data),
    [emailSignUpData]
  );
  const emailSignUpPropsMobile = useMemo(
    () =>
      emailSignUpDataMapper(
        emailSignUpData.dataMobile
          ? emailSignUpData.dataMobile
          : emailSignUpData.data
      ),
    [emailSignUpData]
  );

  return (
    <>
      {isMobile && (
        <SignUpForm
          {...emailSignUpPropsMobile}
          type="footerEmail"
          formFieldKey="PC_EMAIL_ADDRESS"
          params={params}
          dataTestId={FOOTER_EMAIL_SIGN_UP}
          classes="md:hidden"
        />
      )}
      {isDesktop && (
        <SignUpForm
          {...emailSignUpProps}
          type="footerEmail"
          formFieldKey="PC_EMAIL_ADDRESS"
          params={params}
          dataTestId={FOOTER_EMAIL_SIGN_UP}
          classes="hidden md:block"
        />
      )}
    </>
  );
};
