import { useState } from 'react';
import { FooterOffers } from '@gemini/brand/el/ui/organisms/footer-offers';
import type { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import { LivePersonLibraries } from '@gemini/shared/services/utils/next-liveperson';
import { InnerHtml } from '@gemini/shared/ui/atoms/inner-html';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import type { IMenuReferenceProps } from '@gemini/shared/ui/templates/menu-reference';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import { getLoyaltyMemberStatus } from '@gemini/shared/utils/browser-storage';
import styles from './sticky-footer.module.scss';

export interface IStickyFooterProps {
  className?: string;
  footerLeftTemplates?: TemplateType[];
  footerRightTemplates?: TemplateType[];
  stickyFooterMenuReference?: IMenuReferenceProps;
  isDesktopFooterVisible?: boolean;
  isMobileFooterVisible?: boolean;
  language?: string;
}

const DesktopView = (props: IStickyFooterProps) => {
  const [showFeed, setShowFeed] = useState<boolean>(false);
  const {
    className = '',
    footerLeftTemplates,
    footerRightTemplates,
    stickyFooterMenuReference,
    isDesktopFooterVisible = false,
    language
  } = props;

  const isLoyaltyMember = getLoyaltyMemberStatus();

  const loyaltyTemplate = footerLeftTemplates?.find(
    (template) => template.data.title === 'Sticky Footer Loyalty Link'
  );
  const feedTemplate = footerLeftTemplates?.find(
    (template) => template.data.title === 'My Feed'
  );
  const loyaltyFooterTemplate = feedTemplate?.data.templates?.find(
    (template: TemplateType) => template.component === 'LoyaltyFooter'
  );
  const offersTemplate = footerLeftTemplates?.find(
    (template) => template.data.title === 'Generic Offers - user tray'
  );

  const loyaltyLinkTemplate = isLoyaltyMember
    ? loyaltyTemplate?.data.templates[1]
    : loyaltyTemplate?.data.templates[0];
  const specialOffersTemplate = footerRightTemplates?.[0]; // @todo use TemplateRenderer to render a `SpecialOffers` component

  const showOpenFeeds = () => {
    setShowFeed(true);
  };

  const setTemplate = (lang: string) => {
    return (
      <>
        <div className="flex pt-11px">
          <div className="ml-10 mr-5">
            {lang === 'en-US' && (
              <div id="footer_live_chat" className="live-chat-container">
                <span className="font-bold tracking-normal live-chat-placeholder">
                  Live Chat
                </span>
              </div>
            )}
            {lang === 'en-GB' && (
              <div
                id="LP_DIV_1602842241555"
                className="page-sticky-footer__livechat"
              ></div>
            )}
          </div>

          <span className="border-l text-gray-550 h-22px border-inherit" />

          <div className="font-bold mx-14px">
            <SmartLink
              href={loyaltyLinkTemplate?.data.url.data}
              classes={loyaltyLinkTemplate?.data.class.data}
            >
              {isLoyaltyMember
                ? 'MY LOYALTY'
                : loyaltyLinkTemplate?.data.text.data}
            </SmartLink>
          </div>
        </div>

        <div className="flex font-bold pt-12px">
          <div className="mx-[14px] text-base" onMouseEnter={showOpenFeeds}>
            <SmartLink
              href={specialOffersTemplate?.data.url.data}
              classes={specialOffersTemplate?.data.class.data}
            >
              OFFERS
            </SmartLink>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={className}>
      {loyaltyFooterTemplate?.data && (
        <FooterOffers
          data={loyaltyFooterTemplate.data}
          isDesktopFooterVisible={isDesktopFooterVisible}
          showFeed={showFeed}
          setShowFeed={setShowFeed}
          language={language}
        />
      )}

      {offersTemplate?.data && (
        <FooterOffers
          data={offersTemplate.data.templates[0].data}
          isDesktopFooterVisible={isDesktopFooterVisible}
          showFeed={showFeed}
          setShowFeed={setShowFeed}
          language={language}
        />
      )}
      {!isDesktopFooterVisible && <div className="h-43px bg-navy"></div>}
      <div
        className={`flex justify-between sticky-chat group left-0 bottom-0 text-center text-[14px] leading-[1.3] w-full uppercase bg-navy h-43px tracking-[.05em] ${
          !isDesktopFooterVisible && 'fixed'
        }`}
      >
        {language && setTemplate(language)}
      </div>
    </div>
  );
};

const MobileView = (props: IStickyFooterProps) => {
  const {
    className = '',
    footerLeftTemplates,
    footerRightTemplates,
    isMobileFooterVisible,
    language
  } = props;
  const isLoyaltyMember = getLoyaltyMemberStatus();

  const loyaltyTemplate = footerLeftTemplates?.find(
    (template) => template.data.title === 'Sticky Footer Loyalty Link'
  );

  const loyaltyLinkTemplate = isLoyaltyMember
    ? loyaltyTemplate?.data.templates[1]
    : loyaltyTemplate?.data.templates[0];

  const specialOffersTemplate = footerRightTemplates?.[0]; // @todo use TemplateRenderer to render a `SpecialOffers` component

  const setTemplate = (lang: string) => {
    return (
      <>
        {lang === 'en-US' && (
          <div className="ml-14px mr-8px mt-9px">
            <div id="footer_sticky_chat" className="live-chat-container">
              <span className="font-bold tracking-normal live-chat-placeholder">
                Live Chat
              </span>
            </div>
          </div>
        )}
        {lang === 'en-GB' && (
          <div className="ml-14px mr-8px">
            <div
              id="LP_DIV_1602842533080"
              className="page-sticky-footer__livechat"
            ></div>
          </div>
        )}
        <span className="border-l h-22px border-inherit text-independence" />
        <div className="ml-14px mr-8px">
          <SmartLink href={loyaltyLinkTemplate?.data.url.data}>
            <InnerHtml
              classes="sticky-footer-menus"
              content={
                isLoyaltyMember
                  ? 'MY LOYALTY'
                  : loyaltyLinkTemplate?.data.text.data
              }
            />
          </SmartLink>
        </div>

        <span className="border-l h-22px border-inherit text-independence" />

        <div className="ml-14px">
          <SmartLink href={specialOffersTemplate?.data.url.data}>
            <InnerHtml classes="sticky-footer-menus" content="Offers" />
          </SmartLink>
        </div>
      </>
    );
  };

  return (
    <div className={className}>
      {!isMobileFooterVisible && <div className="h-37px bg-navy"></div>}
      <div
        className={`${
          !isMobileFooterVisible ? 'fixed' : 'relative'
        } bottom-0 left-0 w-full text-center uppercase sticky-chat group h-37px text-link leading-37px bg-navy`}
      >
        <div className="flex items-center justify-center h-full">
          {language && setTemplate(language)}
        </div>
      </div>
    </div>
  );
};

export function StickyFooter(props: IStickyFooterProps) {
  const {
    footerLeftTemplates,
    footerRightTemplates,
    stickyFooterMenuReference,
    isDesktopFooterVisible,
    isMobileFooterVisible,
    language
  } = props;
  const { isMobile } = useBreakpoint('block md:hidden');

  return (
    <>
      <LivePersonLibraries />

      {isMobile ? (
        <MobileView
          className={`${styles['sticky-footer']} z-10`}
          footerLeftTemplates={footerLeftTemplates}
          footerRightTemplates={footerRightTemplates}
          isMobileFooterVisible={isMobileFooterVisible}
          stickyFooterMenuReference={stickyFooterMenuReference}
          language={language}
        />
      ) : (
        <DesktopView
          className={`${styles['sticky-footer']} ${
            !isDesktopFooterVisible && 'z-10'
          }`}
          footerLeftTemplates={footerLeftTemplates}
          footerRightTemplates={footerRightTemplates}
          isDesktopFooterVisible={isDesktopFooterVisible}
          stickyFooterMenuReference={stickyFooterMenuReference}
          language={language}
        />
      )}
    </>
  );
}

export default StickyFooter;
