export const EmailOutlined = () => (
  <svg
    width={20}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6Zm-2 0-8 5-8-5h16Zm0 12H4V8l8 5 8-5v10Z"
        id="a"
      />
    </defs>
    <g transform="translate(-2 -4)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fill="#000" fillOpacity={0.64}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default EmailOutlined;
