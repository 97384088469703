export const AfterPayExpress = () => (
  <svg
    width="134px"
    height="15px"
    viewBox="0 0 134 15"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{'049FAA18-278D-4181-B9CA-39AC682EA2AA@1x'}</title>
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g
        transform="translate(-712.000000, -2051.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(708.000000, 2042.000000)">
          <g transform="translate(4.000000, 9.000000)">
            <path
              d="M74.370408,2.91883179 L72.09258,1.6194507 L69.784323,0.304783626 C68.255628,-0.56656383 66.359853,0.53408691 66.359853,2.29207167 L66.359853,2.58251889 C66.359853,2.75067534 66.451623,2.88825789 66.589278,2.97997476 L67.659606,3.59144793 C67.949889,3.75960438 68.331942,3.54558708 68.331942,3.20927901 L68.331942,2.50608414 C68.331942,2.15448912 68.713995,1.94047182 69.004761,2.10862827 L71.114022,3.31628766 L73.20831,4.52394705 C73.514049,4.69209867 73.514049,5.1354009 73.20831,5.3035815 L71.114022,6.5112264 L69.004761,7.7188713 C68.699022,7.8870519 68.331942,7.6730346 68.331942,7.3214106 L68.331942,6.9698349 C68.331942,5.2118598 66.421194,4.1111994 64.907955,4.9825314 L62.599698,6.2972091 L60.32187,7.5965757 C58.793175,8.4832188 58.793175,10.6845396 60.32187,11.5711827 L62.599698,12.8705493 L64.907955,14.185227 C66.43665,15.056559 68.331942,13.9558986 68.331942,12.1979235 L68.331942,11.9074956 C68.331942,11.739315 68.240172,11.6017566 68.102517,11.5100349 L67.032672,10.8985569 C66.742389,10.7303763 66.359853,10.9443936 66.359853,11.2807065 L66.359853,11.9839062 C66.359853,12.3355302 65.9778,12.5495475 65.687517,12.3813669 L63.577773,11.173722 L61.483485,9.9660771 C61.177746,9.7978965 61.177746,9.3545991 61.483485,9.1864185 L63.577773,7.9787736 L65.687517,6.7711287 C65.993256,6.6029481 66.359853,6.8169654 66.359853,7.1685894 L66.359853,7.5201651 C66.359853,9.2781402 68.271084,10.3788006 69.784323,9.5074686 L72.09258,8.1927909 L74.370408,6.8934243 C75.899103,6.0067812 75.899103,3.80546523 74.370408,2.91883179 Z"
              id="Path"
            />
            <polygon
              id="Path"
              points="59.450538 3.34686156 54.11532 14.3533593 51.898833 14.3533593 53.901351 10.2412422 50.767647 3.34686156 53.030019 3.34686156 55.047993 7.9634625 57.249024 3.34686156"
            />
            <path
              d="M6.2064534,7.2603111 C6.2064534,5.9456334 5.2586625,5.0284164 4.08158184,5.0284164 C2.90449635,5.0284164 1.95671028,5.9609445 1.95671028,7.2603111 C1.95671028,8.5444149 2.90449635,9.4922058 4.08158184,9.4922058 C5.2586625,9.4922058 6.2064534,8.5749888 6.2064534,7.2603111 Z M6.2217162,11.1584592 L6.2217162,10.1495205 C5.6408604,10.8527202 4.78477188,11.2960176 3.76055589,11.2960176 C1.62039738,11.2960176 0,9.5839275 0,7.2603111 C0,4.9673169 1.68154518,3.20930799 3.80641191,3.20930799 C4.800054,3.20930799 5.6561232,3.65262471 6.2217162,4.34053263 L6.2217162,3.36217749 L8.1478719,3.36217749 L8.1478719,11.173722 L6.2217162,11.1584592 Z"
              id="Shape"
            />
            <path
              d="M17.4726699,9.4310097 C16.8000441,9.4310097 16.6166007,9.1864185 16.6166007,8.5291038 L16.6166007,5.0589903 L17.8548195,5.0589903 L17.8548195,3.34687122 L16.6166007,3.34687122 L16.6166007,1.45130391 L14.6446083,1.45130391 L14.6446083,3.36215817 L12.1069746,3.36215817 L12.1069746,2.58252855 C12.1069746,1.92519453 12.3515658,1.68060816 13.0395027,1.68060816 L13.467489,1.68060816 L13.467489,0.167210253 L12.5197464,0.167210253 C10.8993297,0.167210253 10.1349822,0.70224819 10.1349822,2.3226504 L10.1349822,3.36215817 L9.0343218,3.36215817 L9.0343218,5.0589903 L10.1349822,5.0589903 L10.1349822,11.173722 L12.1069746,11.173722 L12.1069746,5.0589903 L14.6446083,5.0589903 L14.6446083,8.8806795 C14.6446083,10.4705223 15.2560863,11.1584109 16.8458808,11.1584109 L17.8548195,11.1584109 L17.8548195,9.4310097 L17.4726699,9.4310097 Z"
              id="Path"
            />
            <path
              d="M24.5352891,6.5571114 C24.3976824,5.5481727 23.5721871,4.9366947 22.5938706,4.9366947 C21.6307686,4.9366947 20.8358472,5.5329099 20.6218299,6.5571114 L24.5352891,6.5571114 Z M20.6218299,7.7800674 C20.7594366,8.9418273 21.5849319,9.5991903 22.6397073,9.5991903 C23.4652026,9.5991903 24.1072545,9.2017296 24.4894041,8.5902516 L26.5072815,8.5902516 C26.0333619,10.2412422 24.5505519,11.3113287 22.6091334,11.3113287 C20.2549431,11.3113287 18.5886897,9.6603381 18.5886897,7.290885 C18.5886897,4.9366947 20.331402,3.22458045 22.6550184,3.22458045 C24.9785865,3.22458045 26.6754138,4.9366947 26.6754138,7.290885 C26.6754138,7.4590173 26.660151,7.6271979 26.6295771,7.7953302 L20.6218299,7.7953302 L20.6218299,7.7800674 Z"
              id="Shape"
            />
            <path
              d="M39.1647312,7.2602628 C39.1647312,5.9914701 38.2169403,5.0284164 37.0398693,5.0284164 C35.8627983,5.0284164 34.9150074,5.9608962 34.9150074,7.2602628 C34.9150074,8.5443666 35.8627983,9.4921575 37.0398693,9.4921575 C38.2016775,9.4921575 39.1647312,8.5290555 39.1647312,7.2602628 Z M32.9735889,14.3533593 L32.9735889,3.34684707 L34.8996963,3.34684707 L34.8996963,4.35577611 C35.4806004,3.63729912 36.3366696,3.19397757 37.3608711,3.19397757 C39.4704702,3.19397757 41.1214608,4.9213836 41.1214608,7.2296889 C41.1214608,9.5227314 39.4398963,11.2807065 37.3150344,11.2807065 C36.3366696,11.2807065 35.5111743,10.8832458 34.9608441,10.2411939 L34.9608441,14.3533593 L32.9735889,14.3533593 Z"
              id="Shape"
            />
            <path
              d="M48.046425,7.2603111 C48.046425,5.9456334 47.0986341,5.0284164 45.9215631,5.0284164 C44.7444921,5.0284164 43.7967012,5.9609445 43.7967012,7.2603111 C43.7967012,8.5444149 44.7444921,9.4922058 45.9215631,9.4922058 C47.0986341,9.4922058 48.046425,8.5749888 48.046425,7.2603111 Z M48.0769989,11.1584592 L48.0769989,10.1495205 C47.4960948,10.8527202 46.6400256,11.2960176 45.6158241,11.2960176 C43.4756994,11.2960176 41.8552827,9.5839275 41.8552827,7.2603111 C41.8552827,4.9673169 43.5368472,3.20930799 45.6617091,3.20930799 C46.6553367,3.20930799 47.5114059,3.65262471 48.0769989,4.34053263 L48.0769989,3.36217749 L50.003058,3.36217749 L50.003058,11.173722 L48.0769989,11.1584592 Z"
              id="Shape"
            />
            <path
              d="M29.5188831,4.11123321 C29.5188831,4.11123321 30.0080655,3.20930799 31.2004476,3.20930799 C31.7201556,3.20930799 32.0412057,3.39275139 32.0412057,3.39275139 L32.0412057,5.3800404 C32.0412057,5.3800404 31.3227432,4.936743 30.6501174,5.0284164 C29.9927544,5.1201381 29.5647198,5.7316161 29.5647198,6.5418486 L29.5647198,11.1584592 L27.5774646,11.1584592 L27.5774646,3.34689054 L29.503572,3.34689054 L29.5188831,4.11123321 Z"
              id="Path"
            />
            <path
              d="M83.475441,6.5571114 C83.337786,5.5481727 82.512339,4.9366947 81.533781,4.9366947 C80.570679,4.9366947 79.776144,5.5329099 79.562175,6.5571114 L83.475441,6.5571114 Z M79.562175,7.7800674 C79.699347,8.9418273 80.525277,9.5991903 81.579666,9.5991903 C82.405113,9.5991903 83.047503,9.2017296 83.429556,8.5902516 L85.44753,8.5902516 C84.973707,10.2412422 83.490897,11.3113287 81.549237,11.3113287 C79.195095,11.3113287 77.528745,9.6603381 77.528745,7.290885 C77.528745,4.9366947 79.271409,3.22458045 81.595122,3.22458045 C83.918835,3.22458045 85.615614,4.9366947 85.615614,7.290885 C85.615614,7.4590173 85.600158,7.6271979 85.569729,7.7953302 L79.562175,7.7953302 L79.562175,7.7800674 Z"
              id="Shape"
            />
            <path
              d="M114.720228,6.5571114 C114.583056,5.5481727 113.757126,4.9366947 112.779051,4.9366947 C111.815949,4.9366947 111.020931,5.5329099 110.806962,6.5571114 L114.720228,6.5571114 Z M110.806962,7.7800674 C110.944617,8.9418273 111.770064,9.5991903 112.824936,9.5991903 C113.650383,9.5991903 114.29229,9.2017296 114.674343,8.5902516 L116.692317,8.5902516 C116.218494,10.2412422 114.735684,11.3113287 112.794507,11.3113287 C110.439882,11.3113287 108.774015,9.6603381 108.774015,7.290885 C108.774015,4.9366947 110.516679,3.22458045 112.839909,3.22458045 C115.163622,3.22458045 116.860401,4.9366947 116.860401,7.290885 C116.860401,7.4590173 116.845428,7.6271979 116.814516,7.7953302 L110.806962,7.7953302 L110.806962,7.7800674 Z"
              id="Shape"
            />
            <path
              d="M125.136606,5.7694833 C124.943406,3.96567633 123.332118,3.19262517 121.512174,3.19262517 C119.176386,3.19262517 117.807081,4.25557293 117.807081,5.8016994 C117.807081,9.038862 123.283818,7.4122146 123.283818,9.038862 C123.283818,9.6186552 122.623557,9.9568518 121.576413,9.9568518 C120.577569,9.9568518 119.949669,9.6830391 119.675808,8.8777815 L117.694542,8.8777815 C117.91962,10.8265416 119.562786,11.519067 121.479813,11.519067 C123.63834,11.519067 125.297445,10.536645 125.297445,8.8294815 C125.297445,5.5601028 119.853069,7.1384019 119.853069,5.5923189 C119.853069,5.0286096 120.51333,4.77094359 121.383213,4.77094359 C122.382057,4.77094359 123.009957,5.0930418 123.219579,5.7694833 L125.136606,5.7694833 Z"
              id="Path"
            />
            <path
              d="M133.507479,5.7694833 C133.313796,3.96567633 131.702991,3.19262517 129.883047,3.19262517 C127.547259,3.19262517 126.177954,4.25557293 126.177954,5.8016994 C126.177954,9.038862 131.654691,7.4122146 131.654691,9.038862 C131.654691,9.6186552 130.99443,9.9568518 129.947286,9.9568518 C128.948442,9.9568518 128.320542,9.6830391 128.046681,8.8777815 L126.064932,8.8777815 C126.290493,10.8265416 127.933659,11.519067 129.850686,11.519067 C132.009213,11.519067 133.668318,10.536645 133.668318,8.8294815 C133.668318,5.5601028 128.223459,7.1384019 128.223459,5.5923189 C128.223459,5.0286096 128.884203,4.77094359 129.754086,4.77094359 C130.752447,4.77094359 131.38083,5.0930418 131.590452,5.7694833 L133.507479,5.7694833 Z"
              id="Path"
            />
            <path
              d="M106.119447,11.354847 L106.119447,6.5876853 C106.119447,5.7662955 106.522269,5.1220701 107.295552,5.1220701 C107.569413,5.1220701 107.794974,5.2026345 107.988174,5.3153667 L108.987018,3.54377583 C108.713157,3.31830177 108.310335,3.15724542 107.794974,3.15724542 C106.956969,3.15724542 106.377369,3.59209032 106.055208,4.09135293 L106.055208,3.30219372 L104.073942,3.30219372 L104.073942,11.354847 L106.119447,11.354847 Z"
              id="Path"
            />
            <polygon
              id="Path"
              stroke="#000000"
              strokeWidth={0.25}
              points="93.691374 11.3579382 93.813573 11.3579382 93.739674 11.2609518 90.718992 7.2994341 93.618441 3.43290318 93.690891 3.33630801 93.570141 3.33630801 91.53816 3.33630801 91.508214 3.33630801 91.48986 3.36024549 89.611956 5.8409673 87.719562 3.36006195 87.701208 3.33630801 87.671262 3.33630801 85.548477 3.33630801 85.427727 3.33630801 85.500177 3.43295631 88.430055 7.3312638 85.469748 11.2612416 85.396815 11.3579382 85.518048 11.3579382 87.580458 11.3579382 87.610404 11.3579382 87.628758 11.3337882 89.536608 8.7906483 91.459914 11.3339814 91.477785 11.3579382 91.507731 11.3579382"
            />
            <path
              d="M100.949415,7.1109675 C100.949415,5.8421748 100.001286,4.8791211 98.824215,4.8791211 C97.647144,4.8791211 96.699498,5.8116009 96.699498,7.1109675 C96.699498,8.3950713 97.647144,9.3428622 98.824215,9.3428622 C99.986313,9.3428622 100.949415,8.3798085 100.949415,7.1109675 Z M94.758321,14.204064 L94.758321,3.19756143 L96.684042,3.19756143 L96.684042,4.20649047 C97.265091,3.48800865 98.12145,3.04469193 99.14541,3.04469193 C101.255154,3.04469193 102.906048,4.77210279 102.906048,7.0803936 C102.906048,9.3734361 101.224242,11.1314112 99.099525,11.1314112 C98.12145,11.1314112 97.29552,10.7339505 96.745383,10.0918986 L96.745383,14.204064 L94.758321,14.204064 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AfterPayExpress;
