// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import { FLAG } from '@gemini/shared/utils/testing';

export interface IFlagsProps {
  name: string;
  label: string;
  className?: string;
}

interface IImageLoader {
  src: string;
}

const imageLoader = ({ src }: IImageLoader) => {
  /* eslint-disable @typescript-eslint/no-var-requires */
  return `/assets/flags/${src}`;
};
export const Flags = ({ name, label, className = '' }: IFlagsProps) => (
  <div
    data-testid={FLAG}
    className={`relative inline-block w-6 h-6 mr-2 ${className}`}
  >
    <SmartImage
      loader={imageLoader}
      src={`${name}.svg`}
      alt={label}
      layout="fill"
    />
  </div>
);

export default Flags;
