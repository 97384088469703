export const ArrowRight = (props: any) => {
  return (
    <svg
      width={8}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g transform="translate(-8 -1)" fill="current" fillRule="evenodd">
        <g fill="#000" fillOpacity={0.54}>
          <path d="m8.59 17.34 4.58-4.59-4.58-4.59L10 6.75l6 6-6 6z" />
        </g>
      </g>
    </svg>
  );
};

export default ArrowRight;
