export const InfoFilled = () => {
  return (
    <svg
      width={20}
      height={20}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(-2 -2)" fill="none" fillRule="evenodd">
        <g fill="#000" fillOpacity={1}>
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm1 15h-2v-6h2v6Zm0-8h-2V7h2v2Z" />
        </g>
      </g>
    </svg>
  );
};

export default InfoFilled;
