interface IReplenishmentProps {
  fill?: string;
}

export const Replenishment = ({ fill = '#000000' }: IReplenishmentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
  >
    <g transform="matrix(0.04 0 0 0.04 0 0)">
      <g>
        <path
          d="M222.1 368.7L264.7 368.7L264.7 411.30002L222.1 411.30002L222.1 368.7z"
          stroke="none"
          fill={fill}
          fillRule="nonzero"
        />
        <path
          d="M250.1 0C 112.3 0 0.2 112.1 0.2 249.9C 0.2 387.69998 112.299995 499.8 250.09999 499.8C 387.89996 499.8 500 387.7 500 249.9C 500 112.099976 387.9 0 250.1 0zM250.1 478.5C 124.600006 478.5 21.5 375.4 21.5 249.9C 21.5 124.399994 124.6 22.4 250.1 22.4C 375.6 22.4 477.6 124.4 477.6 249.9C 477.6 375.4 375.6 478.5 250.1 478.5zM243.4 102C 215.4 102 193 109.8 175 124.4C 158.2 139 148.1 161.4 143.6 189.4L143.6 189.4L181.70001 193.9C 185.1 172.59999 191.80002 156.9 203.00002 146.79999C 214.20001 136.69998 226.50002 132.19998 243.30002 132.19998C 260.10004 132.19998 273.6 137.79999 284.80002 148.99998C 296.00003 160.19998 301.6 172.49998 301.6 187.09998C 301.6 194.89998 299.4 202.79997 296 209.49997C 292.6 216.19997 283.7 225.19997 270.2 236.39996C 256.80002 247.59996 247.80002 256.59998 243.30002 262.19995C 236.60002 269.99994 232.10002 278.99994 228.70001 286.89996C 224.20001 298.09998 222.00002 310.39996 222.00002 324.99997L222.00002 324.99997L222.00002 336.19998L257.90002 336.19998C 257.90002 321.59998 259.00003 310.4 261.30002 303.69998C 263.50003 296.99997 265.80002 291.4 269.1 285.8C 272.4 280.19998 280.30002 272.4 292.6 262.3C 310.5 246.59999 322.9 231.99998 329.6 220.79999C 336.30002 209.59999 338.6 197.29999 338.6 183.79999C 338.6 160.29999 329.6 141.19998 311.7 124.39999C 297.2 109.79999 273.6 101.999985 243.40001 101.999985z"
          stroke="none"
          fill={fill}
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default Replenishment;
