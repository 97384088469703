export const FourPayments = () => (
  <svg
    width={80}
    height={88}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M11 82v-6a2 2 0 0 1 2-2h2v-6a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h18.999L34 28h11v-2.5a3.5 3.5 0 0 1 7 0V28h14v-2.5a3.5 3.5 0 0 1 7 0V28h10v47l-39.268.001A2 2 0 0 1 42 76h-2v6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H15a2 2 0 0 1-2-2v-6a2 2 0 0 1-2-2Z"
        id="c"
      />
      <path
        d="M58.5 0A3.5 3.5 0 0 1 62 3.5V6h10v47H33l-.001-.975-.004.124A2 2 0 0 1 31 54h-2v6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-6a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h2v-6a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h19V6h11V3.5a3.5 3.5 0 0 1 7 0V6h14V3.5A3.5 3.5 0 0 1 58.5 0ZM7 62H4v6h3v-6Zm4 0H8v6h3v-6Zm14 0H12v6h13v-6Zm4 0h-3v6h3v-6Zm-2-8h-3v6h3v-6Zm-4 0h-3v6h3v-6Zm-4 0H6v6h13v-6ZM5 54H2v6h3v-6Zm26-8h-3v6h3v-6ZM9 46H6v6h3v-6Zm4 0h-3v6h3v-6Zm14 0H14v6h13v-6Zm20-4.5H36v9.619h11V41.5Zm-12 0h-4V44a2 2 0 0 1 2 2v5.119h2V41.5Zm24 0H48v9.619h11V41.5Zm10.999 0H60v9.619l10 .001-.001-9.62ZM7 38H4v6h3v-6Zm4 0H8v6h3v-6Zm14 0H12v6h13v-6Zm4 0h-3v6h3v-6Zm6-7.5H24.999L25 36h4a2 2 0 0 1 2 2v2.5h4v-10Zm12 0H36v10h11v-10Zm12 0H48v10h11v-10Zm10.999 0H60v10h9.999v-10ZM35 19H24.999v10.5H35V19Zm12 0H36v10.5h11V19Zm12 0H48v10.5h11V19Zm10.999 0H60v10.5h9.999V19ZM34 7.879l-9 .001-.001 9.12h45L70 7.88l-8-.001V10.5a3.5 3.5 0 0 1-7 0V7.879H41V10.5a3.5 3.5 0 0 1-7 0V7.879ZM37.5 2A1.5 1.5 0 0 0 36 3.5v7a1.5 1.5 0 0 0 3 0v-7A1.5 1.5 0 0 0 37.5 2Zm21 0A1.5 1.5 0 0 0 57 3.5v7a1.5 1.5 0 0 0 3 0v-7A1.5 1.5 0 0 0 58.5 2Z"
        id="e"
      />
      <circle id="a" cx={40} cy={40} r={40} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)">
        <path fill="#EEE" d="M-8-4h96v96H-8z" />
      </g>
      <g transform="translate(-8 -4)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <use fill="#979797" fillRule="nonzero" xlinkHref="#c" />
        <g mask="url(#d)" fill="#FFF">
          <path d="M0 0h96v96H0z" />
        </g>
      </g>
      <g transform="translate(3 18)">
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <g mask="url(#f)">
          <path fill="#9E9E9E" d="M-11-22h96v96h-96z" />
        </g>
      </g>
    </g>
  </svg>
);

export default FourPayments;
