import { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface IPortalProps {
  children: ReactNode;
  id: string;
}

export const Portal = (props: IPortalProps) => {
  const { id, children } = props;
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>(`#${id}`);
    setMounted(true);
  }, []);

  return mounted && ref.current ? (
    createPortal(<>{children}</>, ref.current)
  ) : (
    <></>
  );
};

interface IPortalMountProps {
  id: string;
}

export const PortalMount = (props: IPortalMountProps) => <div id={props.id} />;
