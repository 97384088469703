import cn from 'classnames';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';

interface ISubNavItemProps {
  data: {
    toutHeader: {
      data: string;
    };
    body?: {
      data: string;
    };
    image: {
      data: {
        src: string;
        alt?: string;
      };
    };
    cta: {
      data: {
        path: string;
        title: string;
      };
    };
    toutSubcopy1: {
      data: string;
    };
    url: {
      data: string;
    };
  };
}

export const SubNavCardItem = (props: ISubNavItemProps) => {
  const { image, toutHeader, toutSubcopy1, url } = props.data;

  return (
    <>
      <div
        className={cn(
          'block lg:hidden',
          'fixed bottom-0 left-0 w-full h-[127px] flex m-0 bg-white p-[5px] border-t border-solid border-[#eeede9] z-50',
          'before:w-full before:h-px before:left-0 before:absolute before:-z-[1] before:bottom-full before:shadow-[0_0_75px_55px_#fff] before:pointer-events-none'
        )}
      >
        {image && (
          <div className="w-[116px] mr-[8px]">
            <SmartLink href={url.data}>
              <div className="w-[116px] h-[116px] absolute">
                <SmartImage
                  src={image.data.src}
                  alt={image.data.alt}
                  layout="fill"
                />
              </div>
            </SmartLink>
          </div>
        )}
        <div className="flex flex-1 flex-col items-start justify-center text-[16px] text-black">
          <div className="box-border uppercase text-start">
            {toutHeader.data}
          </div>
          {toutSubcopy1 && (
            <div
              className="text-sm text-start"
              dangerouslySetInnerHTML={{ __html: toutSubcopy1.data }}
            ></div>
          )}
        </div>
      </div>

      <div className="flex flex-col items-center w-56 px-4 mx-6 mb-2 bg-gray-100 lg:block lg:px-0 lg:bg-white lg:items-start">
        {image && (
          <div className="relative w-56 h-56 mb-2">
            <SmartImage
              src={image.data.src}
              alt={image.data.alt}
              layout="fill"
            />
          </div>
        )}
        <div className="flex flex-col items-start w-full ml-14 lg:ml-0">
          <div className="font-bold uppercase text-start">
            {toutHeader.data}
          </div>
          {toutSubcopy1 && (
            <div
              className="text-sm text-start"
              dangerouslySetInnerHTML={{ __html: toutSubcopy1.data }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};
