import type { GetStaticPaths, GetStaticProps } from 'next';
import { useEffect, useMemo } from 'react';
import useSWR, { SWRConfig } from 'swr';
import { LayoutRenderer } from '@gemini/brand/el/ui/layouts/layout-renderer';
import {
  PageFormatter,
  PageFormatterProvider
} from '@gemini/brand/el/ui/templates/page-formatter';
import { registerPageInDataLayer } from '@gemini/shared/services/analytics/tealium';
import {
  getStaticProps as createGetStaticProps,
  DefaultPathComponentProps
} from '@gemini/shared/services/configuration/app-server';
import { PageDataProvider } from '@gemini/shared/services/content/next-mantlecms';
import { SeoTags } from '@gemini/shared/services/seo/next-elc-seo';
import { isBrowser, isProd } from '@gemini/shared/services/utils/global';
import {
  ITemplateRendererSetup,
  TemplateRenderer,
  TemplateRendererSetup
} from '@gemini/shared/ui/templates/template-renderer';
import { ErrorBoundary } from '@gemini/shared/ui/utils/error-boundary';
import { useCursorProgress, useSPARouter } from '@gemini/shared/ui/utils/hooks';
import { ProductProvider } from '@gemini/shared/ui/utils/products';
import { clientPageDataFetcher } from '@gemini/shared/ui/utils/swr';
import { getLogger } from '@gemini/shared/utils/logger';
import {
  ALLOWED_PATHS,
  INTERNAL_TEST_PATHS
} from '../utils/constants/allowed-paths';
import { templateMap } from '../utils/constants/templateMap';

const SKIP_MOBILE_DATA_PROCESSING = [
  'OlapicWidget',
  'HeroBanner',
  'MultiUseHomepageHero',
  'TabbedBlock',
  'ContentBlockTout',
  'ContentFormatter',
  'TickerTapeFormatter',
  'ReferencedContent',
  'ContentBlockImage'
];

const Path = (props: Omit<DefaultPathComponentProps, 'fallback'>) => {
  const { data } = useSWR<
    Pick<DefaultPathComponentProps, 'pageData' | 'products'>,
    Error
  >(props.url, clientPageDataFetcher, {
    revalidateOnMount: false,
    refreshInterval: Number(process.env.DEFAULT_REVALIDATE_TIME) * 1000, // @todo use different options for clientside rehydration,
    fallbackData: {
      products: props.products,
      pageData: props.pageData
    }
  });

  const pageData = (data && data.pageData) || props.pageData;
  const products = (data && data.products) || props.products;
  const {
    metadata,
    hreflangs,
    bidirectional,
    templates,
    layout,
    section,
    formatter
  } = pageData;
  const templateRendererSetup = useMemo<ITemplateRendererSetup>(
    () => ({
      templateMap,
      skipMobileDataProcessing: SKIP_MOBILE_DATA_PROCESSING,
      mobileDetectClassName: 'block md:hidden'
    }),
    []
  );

  useEffect(() => {
    const payload = products ? { products } : {};
    registerPageInDataLayer(section, payload);
  }, [section, products]);

  return (
    <PageDataProvider value={props.pageData}>
      <TemplateRendererSetup value={templateRendererSetup}>
        <ProductProvider value={products}>
          {metadata && (
            <SeoTags
              metadata={metadata}
              hreflangs={hreflangs}
              bidirectional={bidirectional}
            />
          )}
          {layout && (
            <LayoutRenderer component={layout}>
              <PageFormatterProvider>
                <PageFormatter {...formatter}>
                  {(templates || []).map((template, index) => (
                    <ErrorBoundary
                      key={`template-${template.component}-${index}`}
                    >
                      <TemplateRenderer {...template} index={index} />
                    </ErrorBoundary>
                  ))}
                </PageFormatter>
              </PageFormatterProvider>
            </LayoutRenderer>
          )}
        </ProductProvider>
      </TemplateRendererSetup>
    </PageDataProvider>
  );
};

export default function CatchAll({
  fallback,
  ...props
}: DefaultPathComponentProps) {
  if (!isBrowser()) {
    getLogger<true>().setEnvData({
      nodeId: props.pageData.id,
      url: props.pageData.path
    });
  }

  useSPARouter(ALLOWED_PATHS);
  useCursorProgress();

  return (
    <SWRConfig value={{ fallback, revalidateOnFocus: false }}>
      <Path {...props} />
    </SWRConfig>
  );
}

export const getStaticProps: GetStaticProps = createGetStaticProps({
  shouldUpdateProductInventory: true
});

export const getStaticPaths: GetStaticPaths = () => {
  const paths = [...ALLOWED_PATHS, ...INTERNAL_TEST_PATHS].map((path) => ({
    params: { path: path.split('/') }
  }));

  return {
    paths,
    fallback: isProd() ? false : 'blocking'
  };
};
