import { ComponentType, useEffect, useState, Suspense } from 'react';
import dynamic from 'next/dynamic';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IA {}

export const dynamicClient = <T extends IA>(...args: Parameters<typeof dynamic<T>>): ComponentType<T> => (props: T) => {
  const [Component, setComponent] = useState<ComponentType<T> | null>(null);
  const [shouldMount, setShouldMount] = useState(false);

  useEffect(() => {
    if (shouldMount) {
      const LoadedCompoent = dynamic(...args);

      setComponent(LoadedCompoent);
    }
  }, [shouldMount]);

  useEffect(() => {
    setShouldMount(true);
  }, []);

  return shouldMount && Component ? (<Suspense fallback={null}><Component {...props} /></Suspense>) : null;
};
