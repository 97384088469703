import {
  ITemplateFieldElcTextFormat,
  TemplateType
} from '@gemini/shared/services/content/next-mantlecms';

export interface IBasicTextAreaProps extends TemplateType {
  data: {
    html: ITemplateFieldElcTextFormat;
  };
}

export function BasicTextArea(props: IBasicTextAreaProps) {
  const { data } = props;

  return (
    <div className="multi_use_homepage_module">
      <div
        className="basic-textarea-v1"
        dangerouslySetInnerHTML={{ __html: data.html.data }}
      />
    </div>
  );
}

export default BasicTextArea;
