export interface IInnerHtmlProps {
  classes?: string;
  content?: string;
}

export const InnerHtml = ({ classes = '', content }: IInnerHtmlProps) =>
  content ? (
    <div
      className={classes}
      dangerouslySetInnerHTML={{
        __html: content
      }}
    />
  ) : null;

export default InnerHtml;
