import { useAppContext } from '@gemini/shared/services/configuration/app-client';
import type { Translations } from './types';

export function useTranslations() {
  const { translations } = useAppContext();

  return translations as Translations;
}

export function useGlobalTemplateData() {
  const { templates } = useAppContext();

  return templates;
}

export default useTranslations;
