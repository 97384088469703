import {
  ITemplateFieldElcTextFormat,
  ITemplateFieldImage,
  ITemplateFieldString,
  ITemplateFieldUrl,
  TemplateType
} from '@gemini/shared/services/content/next-mantlecms';
import type { MakeOptional } from '@gemini/shared/services/utils/global';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import { akamaiImageLoader } from '@gemini/shared/ui/utils/image';

export interface IMenuToutProps
  extends MakeOptional<TemplateType, 'component' | 'metadata'> {
  data: {
    toutHeader: ITemplateFieldString;
    toutSubcopy1: ITemplateFieldElcTextFormat;
    toutSubcopy2: ITemplateFieldElcTextFormat;
    url: ITemplateFieldUrl;
    image: ITemplateFieldImage;
  };
}

const TextElement = ({
  content,
  className = ''
}: {
  content: string;
  className?: string;
}) => (
  <div dangerouslySetInnerHTML={{ __html: content }} className={className} />
);

export const MenuTout = ({
  data: { url, image, toutHeader, toutSubcopy1, toutSubcopy2 }
}: IMenuToutProps) => (
  <div className="whitespace-normal md:mb-[9px] md:-mt-[1px] menu-tout">
    <SmartLink href={url.data}>
      <div className="w-full pb-[100%] relative">
        <SmartImage
          layout="fill"
          src={image.data.src}
          objectFit="cover"
          alt={image.data.alt}
          loader={akamaiImageLoader}
          loading="lazy"
        />
      </div>
      {Boolean(toutHeader.data) && (
        <TextElement content={toutHeader.data} className="menu-tout__header" />
      )}
      {Boolean(toutSubcopy1.data) && (
        <TextElement
          content={toutSubcopy1.data}
          className="menu-tout__subcopy1"
        />
      )}
      {Boolean(toutSubcopy2.data) && (
        <TextElement
          content={toutSubcopy2.data}
          className="menu-tout__subcopy2"
        />
      )}
    </SmartLink>
  </div>
);

export default MenuTout;
