import { useEffect, useState } from 'react';
import {
  IconClose,
  IconMenu,
  IconSearch,
  LogoMobile
} from '@gemini/brand/el/base-theme';
import { Hamburger } from '@gemini/brand/el/ui/molecules/hamburger';
import { Animate } from '@gemini/shared/services/configuration/utils';
import type { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import { Button } from '@gemini/shared/ui/atoms/buttons';
import { BrandLogoCustom } from '@gemini/shared/ui/organisms/brand-logo';
import { TemplateRenderer } from '@gemini/shared/ui/templates/template-renderer';
import { BRAND_LOGO_MOBILE } from '@gemini/shared/utils/testing';
import { NavMobile } from './NavMobileLazy';

interface IMobileHeader {
  isOpenHamburger: boolean;
  setIsOpenHamburger: React.Dispatch<React.SetStateAction<boolean>>;
  searchData: any;
  menuItems?: {
    component: string | null;
    data: {
      childNode: {
        data: {
          id: string;
          templates: TemplateType;
          title: string;
        };
      };
      link: {
        data: {
          html: string;
          path: string;
          target: string;
          title: string;
        };
      };
    } | null;
  }[];
  gnavPrimary?: any;
  utilities?: TemplateType;
  storeLocation?: {
    data: {
      title?: string;
      link?: string;
    };
  };

  triggerSearchOverlay(): void;
}

export const MobileHeader = ({
  gnavPrimary,
  isOpenHamburger,
  setIsOpenHamburger,
  utilities,
  storeLocation,
  triggerSearchOverlay
}: IMobileHeader) => {
  const [showMenuItems, setShowMenuItems] = useState(true);
  const [showMenuLinkHeader, setShowMenuLinkHeader] = useState(false);
  const [showMenuLink, setShowMenuLink] = useState(false);
  const [showMenuTout, setShowMenuTout] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<any>();
  const [selectedMenuLinkHeader, setSelectedMenuLinkHeader] = useState<any>();
  const [selectedMenuLinkHeaderTitle, setSelectedMenuLinkHeaderTitle] =
    useState<any>();
  const [animate, setAnimate] = useState<Animate | undefined>(undefined);

  const toggleHamburger = () => {
    setIsOpenHamburger(!isOpenHamburger);
  };

  useEffect(() => {
    if (isOpenHamburger) {
      setShowMenuItems(true);
      setShowMenuLinkHeader(false);
      setShowMenuLink(false);
      setShowMenuTout(false);
      setSelectedMenuItem(undefined);
      setSelectedMenuLinkHeader(undefined);
      setSelectedMenuLinkHeaderTitle(undefined);
    } else {
      setAnimate(Animate.LEFT);
    }
  }, [isOpenHamburger]);

  useEffect(() => {
    const timer = setTimeout(() => setAnimate(undefined), 300);

    return () => {
      clearTimeout(timer);
    };
  }, [animate]);

  return (
    <>
      <div className="flex items-center h-52px">
        <div className="flex items-center h-full basis-0">
          <Hamburger
            onClick={toggleHamburger}
            isOpen={isOpenHamburger}
            iconClose={<IconClose width={20} height={20} />}
            iconMenu={<IconMenu width={20} height={20} />}
          />

          <Button
            onClick={triggerSearchOverlay}
            className="flex items-center justify-center h-full w-24px md:w-38px bg-none ml-1px -mt-2px"
            aria-label="Search"
          >
            <IconSearch width="24" height="24" />
          </Button>
        </div>
        <div className="flex -mt-2px min-w-[186px] absolute left-2/4 -ml-[93px]">
          <BrandLogoCustom dataTestId={BRAND_LOGO_MOBILE}>
            <LogoMobile width={186} height={23} />
          </BrandLogoCustom>
        </div>
        {utilities && <TemplateRenderer {...utilities} />}
      </div>
      <NavMobile
        {...gnavPrimary}
        isOpenHamburger={isOpenHamburger}
        setIsOpenHamburger={setIsOpenHamburger}
        showMenuItems={showMenuItems}
        setShowMenuItems={setShowMenuItems}
        showMenuLinkHeader={showMenuLinkHeader}
        setShowMenuLinkHeader={setShowMenuLinkHeader}
        showMenuLink={showMenuLink}
        setShowMenuLink={setShowMenuLink}
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
        selectedMenuLinkHeader={selectedMenuLinkHeader}
        setSelectedMenuLinkHeader={setSelectedMenuLinkHeader}
        selectedMenuLinkHeaderTitle={selectedMenuLinkHeaderTitle}
        setSelectedMenuLinkHeaderTitle={setSelectedMenuLinkHeaderTitle}
        showMenuTout={showMenuTout}
        setShowMenuTout={setShowMenuTout}
        storeLocation={storeLocation}
        animate={animate}
        setAnimate={setAnimate}
      />
    </>
  );
};

export default MobileHeader;
