export const Linked = () => {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <g fill="#000" fillOpacity={0.54}>
          <path d="M8.104 9.066a3.67 3.67 0 0 1 5.173 0L15 10.781l-1.293 1.287-1.724-1.715a1.834 1.834 0 0 0-2.585 0l-6.034 6.005a1.813 1.813 0 0 0 0 2.573l1.724 1.716c.714.71 1.871.71 2.586 0l3.541-3.406a5.5 5.5 0 0 0 2.062.522l-4.31 4.171a3.67 3.67 0 0 1-5.172 0L2.071 20.22a3.628 3.628 0 0 1 0-5.147Zm6.93-7a3.669 3.669 0 0 1 5.171 0l1.724 1.716a3.627 3.627 0 0 1 0 5.146l-6.034 6.006a3.67 3.67 0 0 1-5.172 0L9 13.219l1.293-1.287 1.724 1.715a1.833 1.833 0 0 0 2.585 0l6.034-6.005a1.814 1.814 0 0 0 0-2.574l-1.724-1.715a1.834 1.834 0 0 0-2.586 0l-3.42 3.45a5.49 5.49 0 0 0-2.061-.523Z" />
        </g>
      </g>
    </svg>
  );
};

export default Linked;
