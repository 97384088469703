import { ChangeEvent, useState } from 'react';
import { pgFormFetch } from '@gemini/shared/services/account/pg-email-signup';
import type { IModalProps } from '@gemini/shared/ui/organisms/modal';
import { dynamicClient } from '@gemini/shared/ui/utils/dynamic-load';

const Modal = dynamicClient<IModalProps>(() =>
  import('@gemini/shared/ui/organisms/modal').then((m) => m.Modal)
);
interface ISignUpFormProps {
  placeholder: string;
  submitText: string;
  termsText: string;
  successText: string;
  headerText: string;
  type: string;
  dataTestId: string;
  formFieldKey: string;
  params: Record<string, string>;
  classes?: string;
  maxLength?: number;
}

const REQUEST_METHOD = 'rpc.form';

export const SignUpForm = ({
  placeholder,
  submitText,
  termsText,
  successText,
  headerText,
  type,
  dataTestId,
  formFieldKey,
  params,
  classes,
  maxLength
}: ISignUpFormProps) => {
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [terms, setTerms] = useState(false);

  const onSetInputValue = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    setInputValue(event.target.value);
  };

  const onSetTerms = (event: ChangeEvent<HTMLInputElement>): void => {
    setTerms(Boolean(event.target.checked));
  };

  const onSubmit = async (
    event: ChangeEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    const response = await pgFormFetch({
      params: {
        ...params,
        [formFieldKey]: inputValue,
        TERMS: terms ? '1' : '0'
      },
      method: REQUEST_METHOD
    });
    const responseWithErrors = response.error;

    if (responseWithErrors) {
      setError(responseWithErrors.data.messages[0].text);
    } else {
      setError('');
      setSuccess(true);
    }
  };

  const onCloseModal = () => setSuccess(false);

  return (
    <>
      <form
        data-testid={dataTestId}
        onSubmit={onSubmit}
        className={`flex flex-col md:flex-row md:flex-wrap w-full mb-4 leading-30 md:pl-[13px] ${classes}`}
      >
        <div className={`footer-header pl-0 pb-20px pt-20px`}>{headerText}</div>
        <div className="flex flex-col w-full md:flex-row">
          <input
            className={`${
              error ? 'border-input-error' : ''
            } rounded-none text-base w-full md:w-[70%] py-[10px] pl-[10px] md:pr-[8px] border bg-navy text-light placeholder:text-light placeholder:uppercase focus:outline-none h-[40px] leading-[18px] tracking-[0.15em]`}
            placeholder={placeholder}
            value={inputValue}
            name={type}
            maxLength={maxLength}
            onChange={onSetInputValue}
            onFocus={(e) => (e.target.placeholder = '')}
            onBlur={(e) => (e.target.placeholder = placeholder)}
          ></input>
          <button
            className={
              'text-jordy-blue font-akzidenzgrotesk text-12px font-bold leading-[18px] uppercase border border-solid border-stickybackground px-0 py-[11px] h-10 mb-0 w-full md:w-[27%] md:ml-[3%] tracking-[0.15em] mt-[18px] md:mt-0'
            }
            type="submit"
          >
            {submitText}
          </button>
        </div>
        <div className="signup-form__terms">
          <label className="block pt-[16px] relative">
            <input
              className="signup-form__terms-checkbox"
              type="checkbox"
              checked={terms}
              name="terms"
              onChange={onSetTerms}
              onFocus={(e) => (e.target.placeholder = '')}
              onBlur={(e) => (e.target.placeholder = placeholder)}
            ></input>
            <label
              className="signup-form__terms-text"
              dangerouslySetInnerHTML={{
                __html: termsText
              }}
            />
          </label>
        </div>
      </form>
      <div
        className="inline-block font-akzidenzgrotesk float-left mb-4 md:pl-[13px] ml-1 text-venetian-red leading-[24px] tracking-[0.05em] text-[11px]"
        dangerouslySetInnerHTML={{
          __html: error
        }}
      />
      {success && (
        <Modal
          isOpen={success}
          overlay={true}
          overflow={true}
          overlayColor={'bg-navy'}
          overlayOpacity={'opacity-90'}
          contentHtml={successText}
          closeModal={onCloseModal}
        />
      )}
    </>
  );
};
