import { Link } from '@gemini/shared/ui/atoms/links';
import { Text } from '@gemini/shared/ui/atoms/text';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import { akamaiImageLoader } from '@gemini/shared/ui/utils/image';

export interface INavCardData {
  heading: string;
  subtitle?: string;
  image: {
    src: string;
    alt?: string;
  };
  link: {
    data: {
      path: string;
      title: string;
    };
  };
}

export interface INavCard {
  component: string;
  data: INavCardData;
  showAccordions?: boolean;
}

export const NavCard = ({
  data: { heading, subtitle, image, link },
  showAccordions
}: INavCard) => (
  <div
    data-testid="navCard"
    className={`flex md:flex-col-reverse py-2 px-4 md:px-0 mx-4 md:mx-0 mb-2 bg-gray-100 md:w-1/5 md:bg-white items-center ${
      showAccordions ? 'ml-8' : ''
    }`}
  >
    <div className="w-4/5 md:w-full">
      <h3 className="font-bold">
        <Text value={heading} />
      </h3>
      {subtitle && (
        <p className="text-sm">
          <Text value={subtitle} />
        </p>
      )}
      {link && (
        <div className="text-xs underline">
          <Link href={link.data.path}>{link.data.title}</Link>
        </div>
      )}
    </div>
    {image && (
      <div className="relative w-1/5 h-16 md:w-full md:h-32">
        <SmartImage
          src={image.src}
          layout="fill"
          objectFit="cover"
          alt={image.alt}
          loader={akamaiImageLoader}
          loading="lazy"
        />
      </div>
    )}
  </div>
);

export default NavCard;
