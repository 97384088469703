export const Google = () => (
  <svg
    width={19}
    height={20}
    viewBox="0 0 19 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-3 -2)" fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M12.592 10.2h9.055c.087.487.15.96.15 1.596 0 5.458-3.672 9.347-9.205 9.347C7.284 21.143 3 16.868 3 11.57 3 6.275 7.284 2 12.592 2c2.585 0 4.759.947 6.432 2.505l-2.61 2.505c-.712-.685-1.961-1.483-3.822-1.483-3.272 0-5.945 2.704-5.945 6.044 0 3.34 2.673 6.045 5.945 6.045 3.797 0 5.22-2.717 5.446-4.125h-5.446V10.2Z" />
      </g>
    </g>
  </svg>
);

export default Google;
