import { basicMenuDataMapper } from './basicMenuDataMapper';
import { BASIC_MENU, SEARCH } from './constants/templateNames';
import { searchDataMapper } from './searchDataMapper';

const CMS_MAPPERS = {
  [BASIC_MENU]: basicMenuDataMapper,
  [SEARCH]: searchDataMapper
};

export const getMapperForTemplate = (templateName: string) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  CMS_MAPPERS[templateName];
