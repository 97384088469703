import cn from 'classnames';
import { ISubNavLinksCMSData } from '@gemini/shared/services/content/cms-data-mapper';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';

export const SubNavLinks = (props: ISubNavLinksCMSData) => {
  const { categoryHeader, title, url, firstLevelLink } = props.data;

  return (
    !categoryHeader?.data &&
    !firstLevelLink?.data && (
      <>
        <li
          key={`${title.data}-mobile`}
          className={cn(
            'block',
            'menu-link cursor-pointer list-none relative flex items-center justify-center pl-5',
            'border-b border-solid border-light-gray',
            'portrait:lg:block',
            'landscape:lg:hidden',
            { hidden: !props.showMenuLink }
          )}
        >
          <SmartLink
            href={url.data}
            classes={cn(
              'sm:text-[18px] text-navy leading-[41.1px] w-full text-menu-link font-akzidenzgrotesk',
              'portrait:tracking-normal'
            )}
            dangerouslySetInnerHTML={{ __html: title?.data }}
          />
        </li>

        <li
          className={cn(
            'portrait:lg:hidden hidden pb-1 lg:block hover:underline'
          )}
          key={`${title.data}`}
        >
          <SmartLink
            href={url.data}
            dangerouslySetInnerHTML={{ __html: title.data }}
          />
        </li>
      </>
    )
  );
};
