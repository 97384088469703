import { Fragment } from 'react';
import { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import { TemplateRenderer } from '@gemini/shared/ui/templates/template-renderer';

export function ReferencedContent(props: TemplateType) {
  const { data, dataMobile, index } = props;
  const { templates, formatter } = data;

  if (formatter?.component) {
    return (
      <>
        <TemplateRenderer
          data={data}
          dataMobile={dataMobile}
          component={formatter.component}
          metadata={formatter.metadata}
          index={index}
        />
      </>
    );
  }

  return templates?.map((template: TemplateType, idx: number) => (
    <Fragment key={`${template.component}-${idx}`}>
      <TemplateRenderer {...template} index={index} />
    </Fragment>
  ));
}
