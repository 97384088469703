export interface IBasicMenuCMSData {
  data: {
    title: {
      data: string;
    };
    content: {
      data: {
        menu: {
          title: string;
          url: string;
        }[];
      };
    };
  };
}

export const basicMenuDataMapper = (basicMenuData: IBasicMenuCMSData) => {
  const data = basicMenuData.data;
  const title = data.title.data;
  const popularSearches = data.content.data.menu.map((item) => ({
    title: item.title,
    location: item.url
  }));

  return {
    title,
    popularSearches
  };
};
