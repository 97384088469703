import { useMemo } from 'react';
import { ITemplateFieldString } from '@gemini/shared/services/content/next-mantlecms';

interface IToolTipData {
  html: ITemplateFieldString;
}

export interface IToolTipCMSData {
  component: string;
  data: IToolTipData;
  dataMobile: IToolTipData | null;
}

export const toolTipDataMapper = (data: IToolTipData) => {
  const { html } = data;

  return {
    html: html.data
  };
};

interface IToolTipProps {
  toolTipData: IToolTipCMSData;
}

export const ToolTip = ({ toolTipData }: IToolTipProps) => {
  const toolTipProps = useMemo(
    () => toolTipDataMapper(toolTipData.data),
    [toolTipData]
  );
  const toolTipPropsMobile = useMemo(
    () => toolTipDataMapper(toolTipData.dataMobile ?? toolTipData.data),
    [toolTipData]
  );

  return (
    <>
      <div
        className="block md:hidden"
        dangerouslySetInnerHTML={{
          __html: toolTipPropsMobile.html
        }}
      />
      <div
        className="pl-[13px] hidden md:block"
        dangerouslySetInnerHTML={{
          __html: toolTipProps.html
        }}
      />
    </>
  );
};
