export const ShoppingBag = () => {
  return (
    <svg
      width={20}
      height={20}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(-2 -2)" fill="none" fillRule="evenodd">
        <g fill="#000" fillOpacity={0.54}>
          <path
            d="M16.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49A.996.996 0 0 0 21.01 4H6.21l-.94-2H2v2h2l3.6 7.59-1.35 2.44C5.52 15.37 6.48 17 8 17h12v-2H8l1.1-2h7.45ZM8 18c1.1 0 2 .9 2 2s-.9 2-2 2-1.99-.9-1.99-2S6.9 18 8 18Zm10 0c1.1 0 2 .9 2 2s-.9 2-2 2-1.99-.9-1.99-2 .89-2 1.99-2Zm1.31-12-2.76 5H9.53L7.16 6h12.15Z"
            id="a"
          />
        </g>
      </g>
    </svg>
  );
};

export default ShoppingBag;
