import cn from 'classnames';
import React, { ReactNode } from 'react';
import { Link } from '@gemini/shared/ui/atoms/links';
import { LIST } from '@gemini/shared/utils/testing';

export interface IListItem {
  listItem?: string;
  link?: string;
  className?: string;
  icon?: string;
  subList?: IListItem[] | null;
  subListDirection?: 'row' | 'col';
}

type ListItemType = {
  listItem: string;
} & Omit<IListItem, 'subListDirection' | 'listItem'>;

export interface IListProps {
  listItems: IListItem[];
  classNames?: string;
  ordered?: boolean;
}

const ListItem = ({ link, subList, className, listItem }: ListItemType) => {
  const Wrapper = link ? Link : React.Fragment;

  return (
    <div className={cn({ 'font-bold': [subList] })}>
      <Wrapper href={link} className={className}>
        <div
          dangerouslySetInnerHTML={{
            __html: listItem
          }}
        />
      </Wrapper>
    </div>
  );
};

export function List(props: IListProps) {
  const { listItems, classNames, ordered } = props;
  const TypeOfList = ordered ? 'ol' : 'ul';
  let menuLevel = 1;

  const createList = (list: IListItem[]): ReactNode => {
    menuLevel++;

    return list.map((item: IListItem, index) => {
      const listClassName = cn(`menu-level-${menuLevel}`, {
        [`flex flex-${item.subListDirection}`]: item.subListDirection
      });

      return (
        <li key={`${item.listItem}-${index}`}>
          {item.listItem && (
            <ListItem
              link={item.link}
              subList={item.subList}
              className={item.className}
              listItem={item.listItem}
            />
          )}
          {item.subList && item.subList.length && (
            <TypeOfList className={listClassName}>
              {createList(item.subList)}
            </TypeOfList>
          )}
        </li>
      );
    });
  };

  return (
    <TypeOfList
      data-testid={LIST}
      className={cn(`menu-level-${menuLevel}`, classNames)}
    >
      {createList(listItems)}
    </TypeOfList>
  );
}

export default List;
