export const Venmo = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none">
      <rect fill="#3396CD" width={20} height={20} rx={10} />
      <path
        d="M14.929 4.07c.43.71.623 1.44.623 2.364 0 2.946-2.514 6.771-4.555 9.457h-4.66L4.47 4.715l4.081-.387.993 7.952c.922-1.505 2.063-3.87 2.063-5.48 0-.883-.151-1.483-.388-1.977l3.71-.753Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Venmo;
