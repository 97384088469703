import { Copyrights } from '@gemini/shared/ui/molecules/copyrights';
import { List } from '@gemini/shared/ui/molecules/lists';
import { LanguageDropdown } from '@gemini/shared/ui/organisms/language-dropdown';
import { RegionDropdown } from '@gemini/shared/ui/organisms/region-dropdown';
import {
  FOOTER_BOTTOM,
  FOOTER_LEGAL_SECTION
} from '@gemini/shared/utils/testing';
import countryJson from './Countries.json';

const { countries, languages } = countryJson;

export interface IFooterBottomProps {
  className?: string;
  templates: {
    component: string;
    data: {
      templates: {
        component: string;
        data: {
          internetAds: {
            data: {
              path: string;
              title: string;
            };
          };
          ppLink: {
            data: {
              path: string;
              title: string;
            };
          };
          tcLink: {
            data: {
              path: string;
              title: string;
            };
          };
          copyrightText: {
            type: string;
            data: string;
          };
        };
      }[];
    };
  }[];
}

interface ILinkItem {
  data: {
    path: string;
    title: string;
  };
}

interface IListItem {
  listItem: string;
  link?: string;
}

export function groupFooterBottomLinks(footerLinks: ILinkItem[]) {
  return footerLinks?.reduce((listItems: IListItem[], { data: linkData }) => {
    const { title: listItem, path: link } = linkData;

    if (listItem) {
      listItems.push({
        listItem,
        link
      });
    }

    return listItems;
  }, []);
}

export function FooterBottom({ templates, className }: IFooterBottomProps) {
  const footerBottom = templates?.find(
    (item) => item.component === 'FooterBottom'
  );

  const footerBottomLinks = footerBottom?.data.templates.find(
    (item) => item.component === 'FooterBottomLinks'
  );

  // This needs to be used and we need to clean Countries from a json to a getter.
  /* const countryChooser =
    footerBottomLinks?.data.countryChooser.data.templates.find(
      (item) => item.component === 'CountryChooser'
    );
  */
  const footerBottomMiddleLinks = [
    footerBottomLinks?.data.internetAds,
    footerBottomLinks?.data.ppLink,
    footerBottomLinks?.data.tcLink
  ];

  const list = footerBottomMiddleLinks
    ? groupFooterBottomLinks(footerBottomMiddleLinks as ILinkItem[])
    : undefined;

  return (
    <div className="relative leading-42 md:border-t text-light md:pr-4 md:pl-5 md:pb-8">
      <div
        data-testid={FOOTER_BOTTOM}
        className={`block md:flex md:justify-between w-full md:max-w-screen-xl mx-auto ${
          className || ''
        }`}
      >
        <div className="md:flex md:w-[95%] md:float-left">
          <div className="flex w-full h-16 md:h-1 md:justify-start md:w-1/4">
            {countries?.length > 0 && (
              <div className="w-full md:pr-4">
                <RegionDropdown
                  selectedItemClassName="border-none border-r bg-black mt-2 min-w-60 h-8 flex h-8 pl-4 pr-10 border-none"
                  listClassName="h-11 rounded-none md:inline-block md:align-top md:border-none md:w-8/12 "
                  itemClassName="bg-black hover:bg-stickybackground uppercase leading-15 text-xsm p-2.5 border-b border-dark-gray"
                  countries={countries}
                  dropUp
                  footerDropdown="h-8 mt-1 mx-3"
                  mobileFooterClassName="inline-block align-top md:hidden"
                  mobileListClassName="border-none border-r bg-black mt-2 min-w-60 h-8 flex h-8 pl-4 pr-10 appearance-none bg-select-down-arrow bg-no-repeat bg-dropdown-arrow bg-1 leading-30"
                  innerTextClassName="!whitespace-normal"
                />
                <LanguageDropdown
                  selectedItemClassName="border-l border-0 bg-black mt-4 min-w-60 flex justify-center h-4 pl-4"
                  listClassName="h-11 rounded-none md:inline-block md:align-top md:border-0 md:w-4/12"
                  itemClassName="bg-black hover:bg-stickybackground uppercase leading-15 text-xsm p-2.5 border-b border-dark-gray"
                  languages={languages}
                  dropUp
                  mobileFooterClassName="inline-block align-top md:hidden"
                  mobileListClassName="border-l border-0 bg-black mt-4 min-w-60 flex justify-center h-4 pl-4 appearance-none leading-15 pt-px"
                />
              </div>
            )}
          </div>
          <div
            data-testid={FOOTER_LEGAL_SECTION}
            className="flex justify-center h-4 py-8 text-center border-t leading-15 md:border-t-0 md:py-0 md:mt-4 md:w-1/2"
          >
            {list && (
              <List
                listItems={list}
                classNames="flex text-light justify-between text-center text-s footer-middle-items"
              />
            )}
          </div>
          <div className="block w-full text-center h-14 md:h-11 md:flex md:w-1/4 md:justify-end">
            <Copyrights
              brandName={footerBottomLinks?.data.copyrightText.data}
              className="mb-4 md:mb-0 text-light"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterBottom;
