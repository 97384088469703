import React, { useContext } from 'react';

/* eslint-disable-next-line @nrwl/nx/enforce-module-boundaries */
import { PageFormatterContext } from '@gemini/brand/el/ui/templates/page-formatter';
import { PageScroller } from '@gemini/shared/ui/organisms/page-scroller';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import styles from './multi-use-homepage-hero-formatter.module.scss';

const cl = (className: string) => styles[className] ?? '';

interface IMultiUseHomepageHeroFormatterProps {
  children?: React.ReactNode[];
}

export function MultiUseHomepageHeroFormatter(
  props: IMultiUseHomepageHeroFormatterProps
) {
  const { children } = props;

  const { isMobile } = useBreakpoint('block md:hidden');

  const { activeIndex, indexes } = useContext(PageFormatterContext);

  return (
    <div className={cl('multi-use-home-formatter')}>
      {children}
      {!isMobile && (
        <div className={cl('multi-use-home-formatter__scroller')}>
          <PageScroller activeIndex={activeIndex} indexes={indexes} />
        </div>
      )}
    </div>
  );
}

export default MultiUseHomepageHeroFormatter;
