export const BANNER_VIEWED_SESSION_STORAGE_KEY = 'banner_viewed';
export const BANNER_VIEWED_VALUE = '1';
export const BANNER_NOT_VIEWED_VALUE = '0';

export function getStoredBannerViewed() {
  if (typeof sessionStorage === 'undefined') {
    return false;
  }

  const storedValue = sessionStorage.getItem(BANNER_VIEWED_SESSION_STORAGE_KEY);

  return storedValue === BANNER_VIEWED_VALUE;
}

export function setStoredBannerViewed(bannerViewed: boolean) {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem(
      BANNER_VIEWED_SESSION_STORAGE_KEY,
      bannerViewed ? BANNER_VIEWED_VALUE : BANNER_NOT_VIEWED_VALUE
    );
  }
}
