import cn from 'classnames';
import { ComponentType, FC, PropsWithChildren, useMemo } from 'react';
import { IconArrowRight } from '@gemini/brand/el/base-theme';
import {
  ISiteHeaderMenuFormatter,
  ITemplateFieldNodeRefDrop
} from '@gemini/brand/el/ui/organisms/menu-column';
import { useMegamenu } from '@gemini/brand/el/ui/organisms/use-megamenu';
import { Animate } from '@gemini/shared/services/configuration/utils';
import {
  ITemplateFieldCheckbox,
  ITemplateFieldString,
  ITemplateFieldUrl,
  TemplateType
} from '@gemini/shared/services/content/next-mantlecms';
import { useTranslations } from '@gemini/shared/services/content/translations';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import { TemplateRenderer } from '@gemini/shared/ui/templates/template-renderer';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import styles from './BasicMenuref.module.scss';
import menuFormatterStyles from './MenuFormatter.module.scss';

export interface IBasicMenurefProps extends TemplateType {
  data: {
    title: ITemplateFieldString;
    hasMenuRefChildren: ITemplateFieldCheckbox;
    url: ITemplateFieldUrl;
    content: ITemplateFieldNodeRefDrop;
  };
  className?: string;
  showCloseMenu?: boolean;

  [key: string]: any;
}

const cl = (className: string) => styles[className] ?? '';

interface IFormatterOwnProps {
  data: ISiteHeaderMenuFormatter;
}

type IFormatterProps = PropsWithChildren<IFormatterOwnProps>;

const DefaultFormatter: FC<IFormatterProps> = ({ children }) => <>{children}</>;

const MenuFormatter: FC<IFormatterProps> = ({ children, data }) => {
  const columnWidth = data.data.columnWidth.data;

  const style = useMemo(() => {
    return {
      '--menu-formatter-column-width': `${columnWidth}`,
      '--menu-formatter-column-width-percent': `${columnWidth}%`
    };
  }, [data]);

  return (
    <>
      <div
        className={cn('menu-formatter', menuFormatterStyles['menu-formatter'])}
        style={style}
        data-columnwidth={columnWidth}
        data-columnwidth-percent={`${columnWidth}%`}
      >
        {children}
      </div>
    </>
  );
};

const FORMATTER_MAP: Record<string, ComponentType<IFormatterProps>> = {
  MenuFormatter
};

export function DesktopView(props: IBasicMenurefProps) {
  const {
    data: { title, url, content },
    showCloseMenu = true,
    className
  } = props;
  const { templates = [] } =
    content.data || ({} as ITemplateFieldNodeRefDrop['data']);
  const { close } = useMegamenu();

  const {
    elc_general: { close: closeLabel }
  } = useTranslations();
  const id = url.data || title.data;
  const { active } = useMegamenu();
  const showTemplates = active === id;

  const Formatter: ComponentType<IFormatterProps> =
    FORMATTER_MAP[content.data.formatter?.component || ''] || DefaultFormatter;

  const hasMultiMenuTout =
    templates.filter((template) => template.component === 'MenuTout').length >
    1;

  return (
    <Formatter data={content.data.formatter}>
      <div
        className={cn(
          cl('basic-menu'),
          styles.basicMenu,
          'group',
          className,
          'basic-menu__group'
        )}
      >
        <SmartLink
          href={url.data}
          classes={cn(cl('basic-menu__link'), 'basic-menu__link')}
          dangerouslySetInnerHTML={{ __html: title.data }}
        />
        {templates.length > 0 && (
          <div
            className={cn(
              cl('basic-menu__template'),
              {
                '!hidden': !showTemplates,
                'basic-menu__template--multi-menu-tout': hasMultiMenuTout
              },
              'basic-menu__template'
            )}
          >
            {Boolean(props.logo) && (
              <div className={cl('basic-menu__logo')}>
                <SmartImage src={props.logo as string} layout="fill" />
              </div>
            )}
            <div>
              {templates.map((template, i: number) => {
                if (template.component === 'MenuColumn') {
                  template.logo = props.logo;
                }

                return (
                  <div
                    className="basic-menu__entry basic-menu__entry--desktop"
                    data-component={(template as TemplateType)?.component}
                    key={i.toString()}
                  >
                    <TemplateRenderer {...(template as TemplateType)} />
                  </div>
                );
              })}
            </div>
            {showCloseMenu && (
              <button
                className={'basic-menu__close-button'}
                onClick={close}
                aria-label={closeLabel}
              >
                <Icon
                  asImg={true}
                  name="Close"
                  width="20"
                  height="20"
                  label={closeLabel}
                />
              </button>
            )}
          </div>
        )}
      </div>
    </Formatter>
  );
}

export function MobileView(props: IBasicMenurefProps) {
  const {
    data: { title, url, content }
  } = props;
  const { templates = [], id } = content.data;

  const shouldNavigate = useMemo(
    () => templates.some(({ component }) => component === 'MenuColumn'),
    [templates]
  );

  const handleArrowRightClick = () => {
    if (shouldNavigate) {
      props.setAnimate(Animate.RIGHT);
      props.setShowMenuLinkHeader(!props.showMenuLinkHeader);
      props.setShowMenuItems(!props.showMenuItems);
      props.setShowMenuTout(!props.showMenuTout);
      props.setSelectedMenuItem({ templates, id, title, url });
    }
  };

  return (
    <>
      {props.showMenuItems ? (
        <div
          className={cn(
            'menu-item cursor-pointer relative flex items-center justify-center border-b border-solid border-light-gray pl-[18px]',
            { hidden: !props.showMenuItems },
            props.animate === Animate.RIGHT
              ? 'motion-safe:animate-slide-from-right'
              : props.animate === Animate.LEFT
              ? 'motion-safe:animate-slide-from-left'
              : ''
          )}
          onClick={handleArrowRightClick}
        >
          {shouldNavigate ? (
            <>
              <span className="text-menu-link font-bold leading-[41.1px] tracking-[2.10938px] uppercase w-full text-navy font-akzidenzgrotesk">
                {title.data}
              </span>
              <button
                className="absolute inset-y-0 appearance-none right-5"
                aria-label="Expand"
              >
                <IconArrowRight name="ArrowRight" width="22" height="22" />
              </button>
            </>
          ) : (
            <SmartLink
              href={url.data}
              classes="text-menu-link font-bold leading-[41.1px] tracking-[2.10938px] uppercase w-full text-navy font-akzidenzgrotesk"
            >
              {title.data}
            </SmartLink>
          )}
        </div>
      ) : (
        <span className="text-menu-link font-bold leading-[41.1px] tracking-[2.10938px] uppercase w-full text-navy font-akzidenzgrotesk">
          {title.data}
        </span>
      )}
    </>
  );
}

export function BasicMenuRef(props: IBasicMenurefProps) {
  const { isMobile, isDesktop } = useBreakpoint('block md:hidden');

  return (
    <>
      {isDesktop && (
        <div className={`hidden md:block h-full ${styles['basic-menu']}`}>
          <DesktopView {...props} />
        </div>
      )}
      {isMobile && (
        <div className="block md:hidden">
          <MobileView {...props} />
        </div>
      )}
    </>
  );
}

export default BasicMenuRef;
