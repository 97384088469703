export const Hamburger = () => {
  return (
    <svg
      width={18}
      height={12}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M3 18h18v-2H3v2Zm0-5h18v-2H3v2Zm0-7v2h18V6H3Z"
          id="path-hamburger"
        />
      </defs>
      <g transform="translate(-3 -6)" fill="none" fillRule="evenodd">
        <mask id="mask-hamburger" fill="#fff">
          <use xlinkHref="#path-hamburger" />
        </mask>
        <g mask="url(#mask-hamburger)" fill="#000" fillOpacity={0.54}>
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
};

export default Hamburger;
