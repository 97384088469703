import { useCallback } from 'react';
import { ReactSVG, Props as ReactSVGProps } from 'react-svg';
import {
  ISmartImageProps,
  SmartImage
} from '@gemini/shared/ui/molecules/smart-image';

interface IAsImgProps extends Omit<ISmartImageProps, 'src' | 'fill'> {
  asImg: true;
  name: string;
}

type IReactSVGProps = Omit<ReactSVGProps, 'src'> & { asImg?: false } & (
    | {
        name?: string;
        path: string;
      }
    | {
        name: string;
        path?: string;
      }
  );

interface IIconOwnProps {
  label?: string;
  dataTestId?: string;
}
export interface IIconConfig {
  width?: number | string;
  height?: number | string;
  unit?: string;
  fill?: string;
  color?: string;
}

export type IIconProps = IIconOwnProps &
  (IAsImgProps | IReactSVGProps) &
  IIconConfig;

export const Icon = (props: IIconProps) => {
  const { label, name, asImg = false, dataTestId = '', ...otherProps } = props;

  const alt = label || name || 'Icon';

  if (asImg) {
    // fill means something else here
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { fill: imgFill, ...rest } = otherProps;

    return (
      <SmartImage
        data-testid={dataTestId}
        {...(rest as unknown as Partial<ISmartImageProps>)}
        src={`/assets/icons/${name}.svg`}
        alt={alt}
      />
    );
  }

  const { height, width, unit = 'px', fill, color } = props;

  const beforeInjection = useCallback(
    (svg: SVGSVGElement) => {
      const h = height ? `height: ${height}${unit}; ` : '';
      const w = width ? `width: ${width}${unit}; ` : '';
      const f = fill ? `fill: ${fill};` : '';
      const c = color ? `color: ${color};` : '';

      svg.setAttribute('style', `${h}${w}${f}${c}`);
      svg.setAttribute('role', 'img');
    },
    [height, width, fill]
  );

  return (
    <ReactSVG
      aria-label={(props as ReactSVGProps)['aria-label']}
      role={(props as ReactSVGProps).role ?? 'presentation'}
      data-testid={dataTestId}
      src={(props as IReactSVGProps).path || `/assets/icons/${name}.svg`}
      beforeInjection={beforeInjection}
    />
  );
};

export default Icon;
