interface ITemplateField {
  type: string;
  data: any;
}

interface IRichTextAreaProps {
  data: {
    wrapperClass: ITemplateField;
    html: ITemplateField;
  };
}

export function RichTextArea(props: IRichTextAreaProps) {
  const { wrapperClass, html } = props.data;

  // is for when content is set to `<span> </span>`
  // the intent is to use this for spacing, but it causes a layout shift
  if (html.data === '\u003Cspan\u003E\u00a0\u003C/span\u003E') {
    return <div className="pb-6" />;
  }

  return (
    <div
      className={wrapperClass.data}
      dangerouslySetInnerHTML={{ __html: html.data }}
    ></div>
  );
}
