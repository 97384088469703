import { FC, PropsWithChildren } from 'react';
import { ITemplateRendererSetup } from './ITemplateRendererSetup';
import { TemplateRendererSetupContext } from './TemplateRendererSetupContext';

export interface ITemplateRendererSetupProps {
  value: ITemplateRendererSetup;
}

export const TemplateRendererSetup: FC<
  PropsWithChildren<ITemplateRendererSetupProps>
> = ({ value, children }) => (
  <TemplateRendererSetupContext.Provider value={value}>
    {children}
  </TemplateRendererSetupContext.Provider>
);
