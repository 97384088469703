import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

const USE_BREAKPOINT_ELEMENT_CONTAINER_ID = 'use-breakpoint-container';

function getUseBreakpointElementIdByClassName(className: string) {
  return `use_breakpoint_for_${className}`;
}

interface IUseBreakpointWatcherConfig {
  isMobile: boolean;
  element: HTMLElement | null;
}

type IUseBreakpointWatcher = (config: IUseBreakpointWatcherConfig) => void;

interface IUseBreakpointElementInfo {
  element: HTMLDivElement;
  observer: ResizeObserver;
  watchers: IUseBreakpointWatcher[];
  mobile: boolean;
}

interface IUseBreakpointRepository {
  elementCache: Map<string, IUseBreakpointElementInfo>;
  containerElement?: HTMLElement | null;
}

const UseBreakpointContext = createContext<IUseBreakpointRepository>({
  elementCache: new Map(),
  containerElement: null
});

export interface IUseBreakpointOptions {
  name?: string;
}

export const useBreakpoint = (
  classNameMobile: string,
  options?: IUseBreakpointOptions
) => {
  const [isMobile, setIsMobile] = useState<boolean>(true);
  const [isDesktop, setIsDesktop] = useState<boolean>(true);
  const breakpointRepository =
    useContext<IUseBreakpointRepository>(UseBreakpointContext);
  const { elementCache } = breakpointRepository;

  const cleanupRef = useRef<(() => void)[]>([]);

  const doCleanup = useCallback(() => {
    cleanupRef.current.forEach((c) => c());
    cleanupRef.current.splice(0, cleanupRef.current.length);
  }, []);

  const update = useCallback((mobile: boolean) => {
    setIsMobile(mobile);
    setIsDesktop(!mobile);
  }, []);

  const attachToObserver = useCallback(() => {
    if (typeof ResizeObserver !== 'undefined') {
      let elementContainer = breakpointRepository.containerElement;
      if (!elementContainer) {
        elementContainer = document.createElement('div');
        breakpointRepository.containerElement = elementContainer;
        elementContainer.setAttribute(
          'id',
          USE_BREAKPOINT_ELEMENT_CONTAINER_ID
        );
        document.body.appendChild(elementContainer);
      }

      const elementId = getUseBreakpointElementIdByClassName(classNameMobile);
      let info = elementCache.get(elementId);
      const e = info?.element ?? document.createElement('div');
      const watcher: IUseBreakpointWatcher = (config) => {
        setIsMobile(config.isMobile);
        setIsDesktop(!config.isMobile);
      };

      if (info) {
        info.watchers.push(watcher);
      } else {
        e.setAttribute('id', elementId);
        e.className = classNameMobile;
        elementContainer.appendChild(e);

        const watchers: IUseBreakpointElementInfo['watchers'] = [];

        const observer = new ResizeObserver(() => {
          const mobile = e.clientWidth > 0;

          const i = elementCache.get(elementId);
          if (i && i.mobile !== mobile) {
            i.mobile = mobile;

            const cfg: IUseBreakpointWatcherConfig = {
              element: e,
              isMobile: mobile
            };

            watchers.forEach((w) => w(cfg));
          }
        });
        watchers.push(watcher);

        info = {
          element: e,
          observer,
          watchers,
          mobile: e.clientWidth > 0
        };

        elementCache.set(elementId, info);

        observer.observe(e);
      }

      update(info.mobile);

      return () => {
        const idx = info?.watchers.indexOf(watcher) ?? -1;
        if (idx >= 0) {
          info?.watchers.splice(idx, 1);
        }
        if (info?.watchers.length === 0) {
          info.observer.unobserve(e);
          info.observer.disconnect();
          elementContainer?.removeChild(e);
          elementCache.delete(elementId);
        }
      };
    }

    return () => {
      // NOOP
    };
  }, [classNameMobile]);

  useEffect(() => {
    doCleanup();

    const cleanup = attachToObserver();
    cleanupRef.current.push(cleanup);

    return doCleanup;
  }, []);

  useEffect(() => {
    doCleanup();

    const cleanup = attachToObserver();
    cleanupRef.current.push(cleanup);

    return doCleanup;
  }, [classNameMobile]);

  return {
    isMobile,
    isDesktop
  };
};
