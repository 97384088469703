import React from 'react';

/* eslint-disable-next-line @nrwl/nx/enforce-module-boundaries */
import { MultiUseHomepageHeroFormatter } from '@gemini/brand/el/ui/templates/multi-use-homepage-hero-formatter';
import { ITemplateMetadata } from '@gemini/shared/services/content/next-mantlecms';

const pageFormatterMap = {
  MultiUseHomepageHeroFormatter
};

export interface IPageFormatterProps {
  component: string;
  metadata: ITemplateMetadata;
  data: any;
  children: React.ReactNode[];
}

export function PageFormatter(props: IPageFormatterProps) {
  const { component, data, children } = props;
  // only homepage has a formatter for now so we apply the formatter
  // only in that case
  if (component === 'MultiUseHomepageHeroFormatter') {
    const FormatterComponent = pageFormatterMap[component];

    return React.createElement(
      FormatterComponent,
      data as Record<string, unknown>,
      [...children]
    );
  } else {
    return <>{children}</>;
  }
}

export default PageFormatter;
