export const Cancel = () => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(-4 -4)" fill="none" fillRule="evenodd">
        <g fill="#000" fillOpacity={0.54}>
          <path d="M16 14.87 14.87 16 12 13.13 9.13 16 8 14.87 10.87 12 8 9.13 9.13 8 12 10.87 14.87 8 16 9.13 13.13 12 16 14.87ZM12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8Z" />
        </g>
      </g>
    </svg>
  );
};

export default Cancel;
