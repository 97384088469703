import { useMemo } from 'react';
import { ITemplateFieldString } from '@gemini/shared/services/content/next-mantlecms';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import { FOOTER_SMS_SIGN_UP } from '@gemini/shared/utils/testing';
import { SignUpForm } from './SignUpForm';

interface ISmsSignUpData {
  smsPlaceholder: ITemplateFieldString;
  smsSubmitText: ITemplateFieldString;
  smsTermsText: ITemplateFieldString;
  smsSuccessText: ITemplateFieldString;
  smsSignupHeader: ITemplateFieldString;
}

export interface ISmsSignUpCMSData {
  component: string;
  data: ISmsSignUpData;
  dataMobile: ISmsSignUpData | null;
}

export const smsSignUpDataMapper = (data: ISmsSignUpData) => {
  const {
    smsPlaceholder,
    smsSubmitText,
    smsTermsText,
    smsSuccessText,
    smsSignupHeader
  } = data;

  return {
    placeholder: smsPlaceholder.data,
    submitText: smsSubmitText.data,
    termsText: smsTermsText.data,
    successText: smsSuccessText.data,
    headerText: smsSignupHeader.data
  };
};

interface ISmsSignUpProps {
  smsSignUpData: ISmsSignUpCMSData;
}

const params = {
  _SUBMIT: 'sms_signup',
  SMSPROMO_LAST_SOURCE: 'gnav',
  SMSPROMO_ORIGINAL_SOURCE: 'gnav',
  SMSPROMO_SMS_PROMOTIONS_PRESENT: '1',
  'FIELD_PREFIX--sms_signup': 'SMSPROMO_',
  SMSPROMO_CARRIER_CODE: 'UNKNOWN',
  SMSPROMO_COUNTRY_ID: '1',
  SMSPROMO_LANGUAGE_ID: '48',
  SMSPROMO_MOBILE_NUMBER: '2025550003',
  SMSPROMO_SMS_PROMOTIONS: '1'
};

export const SmsSignUp = ({ smsSignUpData }: ISmsSignUpProps) => {
  const { isMobile, isDesktop } = useBreakpoint('block md:hidden');

  const smsSignUpProps = useMemo(
    () => smsSignUpDataMapper(smsSignUpData.data),
    [smsSignUpData]
  );
  const smsSignUpPropsMobile = useMemo(
    () =>
      smsSignUpDataMapper(
        smsSignUpData.dataMobile ? smsSignUpData.dataMobile : smsSignUpData.data
      ),
    [smsSignUpData]
  );

  return (
    <>
      {isMobile && (
        <SignUpForm
          {...smsSignUpPropsMobile}
          type="footerSms"
          formFieldKey="SMSPROMO_MOBILE_NUMBER"
          params={params}
          dataTestId={FOOTER_SMS_SIGN_UP}
          classes="md:hidden"
          maxLength={14}
        />
      )}
      {isDesktop && (
        <SignUpForm
          {...smsSignUpProps}
          type="footerSms"
          formFieldKey="SMSPROMO_MOBILE_NUMBER"
          params={params}
          dataTestId={FOOTER_SMS_SIGN_UP}
          classes="hidden md:block"
          maxLength={14}
        />
      )}
    </>
  );
};
