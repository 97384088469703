import cn from 'classnames';
import {
  ITemplateFieldCheckbox,
  ITemplateFieldColor,
  ITemplateFieldMantleCtaButton,
  ITemplateFieldMantleCustomTextFormat,
  ITemplateFieldSelect
} from '@gemini/shared/services/content/next-mantlecms';
import { MantleCustomText } from '@gemini/shared/ui/molecules/mantle-custom-text';
import styles from '../basic-tout.module.scss';
import { CtaButtons } from './CtaButtons';

export interface IDisplayContentOverImage {
  type: 'component_group';
  data: {
    eyebrowOverMedia: ITemplateFieldMantleCustomTextFormat;
    headerOverMedia: ITemplateFieldMantleCustomTextFormat;
    contentOverMedia: ITemplateFieldMantleCustomTextFormat;
    backgroundColor: ITemplateFieldColor;
    ctaButtonOverMedia: ITemplateFieldMantleCtaButton;
    ctaButton2OverMedia: ITemplateFieldMantleCtaButton;
    textRow1OverMedia: ITemplateFieldMantleCustomTextFormat;
    textRow2OverMedia: ITemplateFieldMantleCustomTextFormat;
    horizontalAlignmentOverMedia: ITemplateFieldSelect;
    verticalAlignmentOverMedia: {
      data: 'justify-center' | 'justify-start' | 'justify-end' | '';
      type: 'select_v2';
    };

    textAlignmentOverMedia: ITemplateFieldSelect;
    maxWidthOverMedia: ITemplateFieldSelect;
    marginLeftOverMedia: ITemplateFieldSelect;
    marginRightOverMedia: ITemplateFieldSelect;
    marginTopOverMedia: ITemplateFieldSelect;
    marginBottomOverMedia: ITemplateFieldSelect;
    contentOverImageHideMobileOverMedia:
      | ITemplateFieldCheckbox
      | {
          data: string;
        };
    contentOverImageHidePcOverMedia:
      | ITemplateFieldCheckbox
      | {
          data: string;
        };
  } | null;
}

interface IContentOverImage {
  content: IDisplayContentOverImage;
}

const cl = (className: string) => styles[className] ?? '';

const processOptions = (
  data: ITemplateFieldMantleCustomTextFormat
): ITemplateFieldMantleCustomTextFormat => {
  if (!data.data) {
    return data;
  }

  const font_classes = data.data.font_classes
    .split(' ')
    .map((elem) => cl(elem))
    .join(' ');

  return {
    ...data,
    data: {
      ...data.data,
      font_classes
    }
  };
};

export const ContentOverImage = ({ content }: IContentOverImage) => {
  if (!content.data) {
    return null;
  }

  const {
    eyebrowOverMedia,
    headerOverMedia,
    contentOverMedia,
    verticalAlignmentOverMedia,
    marginBottomOverMedia,
    marginLeftOverMedia,
    marginRightOverMedia,
    marginTopOverMedia,
    maxWidthOverMedia,
    ctaButtonOverMedia,
    ctaButton2OverMedia,
    textRow1OverMedia,
    textRow2OverMedia
  } = content.data;

  return (
    <div
      className={cn(
        cl('basic-tout__over-media-block'),
        cl(`tout--${verticalAlignmentOverMedia.data}`),
        'basic-tout__over-media-block'
      )}
    >
      <div
        className={cn(
          cl(`basic-tout__over-media-wrapper`),
          'basic-tout__over-media-wrapper',
          marginBottomOverMedia.data ?? '',
          marginLeftOverMedia.data ?? '',
          marginRightOverMedia.data ?? '',
          marginTopOverMedia.data ?? '',
          maxWidthOverMedia.data ?? ''
        )}
      >
        {eyebrowOverMedia.data.text_content && (
          <MantleCustomText
            options={processOptions(eyebrowOverMedia)}
            className={cl('basic-tout__eyebrow')}
          />
        )}
        {headerOverMedia.data.text_content && (
          <MantleCustomText
            options={processOptions(headerOverMedia)}
            className={cn(cl('basic-tout__header'), 'basic-tout__header')}
          />
        )}
        {contentOverMedia.data.text_content && (
          <MantleCustomText
            options={processOptions(contentOverMedia)}
            className={cl('basic-tout__content')}
          />
        )}
        <CtaButtons
          ctaButton1={ctaButtonOverMedia}
          ctaButton2={ctaButton2OverMedia}
        />
        {textRow1OverMedia.data.text_content && (
          <MantleCustomText
            options={processOptions(textRow1OverMedia)}
            className={cn(
              cl('basic-tout__content-block__line'),
              cl('basic-tout__content-block__line--text-row-1')
            )}
          />
        )}
        {textRow2OverMedia.data.text_content && (
          <MantleCustomText
            options={processOptions(textRow2OverMedia)}
            className={cn(
              cl('basic-tout__content-block__line'),
              cl('basic-tout__content-block__line--text-row-2')
            )}
          />
        )}
      </div>
    </div>
  );
};
