export const Hide = () => (
  <svg
    width={22}
    height={20}
    viewBox="0 0 22 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-1 -3)" fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M3.414 3 21.8 21.385l-1.414 1.414-3.746-3.746c-1.424.61-2.992.947-4.639.947-5 0-9.27-3.11-11-7.5a11.887 11.887 0 0 1 3.934-5.151L2 4.414 3.414 3Zm4.225 7.054a5.002 5.002 0 0 0 6.807 6.807l-1.51-1.51A2.996 2.996 0 0 1 9 12.5c0-.326.052-.64.148-.935l-1.51-1.51ZM12 5c5 0 9.27 3.11 11 7.5a11.883 11.883 0 0 1-3.435 4.756L16.65 14.34a5.002 5.002 0 0 0-6.49-6.49L8.005 5.694A11.776 11.776 0 0 1 12 5Zm0 4.5a2.996 2.996 0 0 1 2.995 3.184l-3.179-3.179c.061-.003.122-.005.184-.005Z" />
      </g>
    </g>
  </svg>
);

export default Hide;
