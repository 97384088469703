import { atom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';

interface INavItemState {
  hidden?: boolean;
}

const initialState: INavItemState = {};

const NavItemState = atom<INavItemState>(initialState);
NavItemState.debugLabel = 'NavItem State';

const useUpdateNavItemState = () => useUpdateAtom(NavItemState);

const useNavItemState = () => useAtomValue(NavItemState);

export { NavItemState, useUpdateNavItemState, useNavItemState };
