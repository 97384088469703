import { ReactNode, useCallback, useEffect, useState } from 'react';
import type { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import { Button } from '@gemini/shared/ui/atoms/buttons';
import { Icon, IIconConfig } from '@gemini/shared/ui/molecules/icon';
import { TemplateRenderer } from '@gemini/shared/ui/templates/template-renderer';
import {
  getLoyaltyMemberStatus,
  getSignedInStatus
} from '@gemini/shared/utils/browser-storage';
import {
  PROMO_BANNER_BUTTON_CLOSE,
  PROMO_BANNER_BUTTON_LEFT,
  PROMO_BANNER_BUTTON_RIGHT
} from '@gemini/shared/utils/testing';

export interface IPromoBannerData {
  templates: TemplateType[];
  formatter?: {
    speed?: number;
    autoplay?: boolean;
    hideArrows?: boolean;
  };
}

export enum IconConfigKeys {
  ARROW = 'arrow',
  CLOSE = 'close'
}

export interface IPromoBannerProps {
  data: IPromoBannerData;
  iconConfig?: Partial<Record<IconConfigKeys, IIconConfig>>;
  active: boolean;

  onActiveChange?(active: boolean): void;

  prevLabel?: string;
  nextLabel?: string;
  closeLabel?: string;
  prevIcon?: ReactNode;
  nextIcon?: ReactNode;
  closeIcon?: ReactNode;
}

enum BannerCopyType {
  GUEST = 'js-guest',
  LOYALTY = 'js-loyalty',
  NON_LOYALTY = 'js-non-loyalty'
}

export function PromoBanner({
  data,
  iconConfig,
  active,
  onActiveChange,
  prevLabel,
  prevIcon,
  nextLabel,
  nextIcon,
  closeLabel,
  closeIcon
}: IPromoBannerProps) {
  const { templates, formatter = {} } = data;
  const { speed = 3000, hideArrows = false, autoplay = true } = formatter;

  const [bannerIndex, setBannerIndex] = useState(0);

  useEffect(() => {
    if (active) {
      document.body.classList.add('site-banner__is-open');
    }

    return () => {
      document.body.classList.remove('site-banner__is-open');
    };
  }, [active]);

  const promotions = templates.filter((template) => {
    if (!template.data.bannerCopyType || !template.data.bannerCopyType.data) {
      return true;
    }

    if (getLoyaltyMemberStatus()) {
      return template.data.bannerCopyType.data === BannerCopyType.LOYALTY;
    }
    if (getSignedInStatus()) {
      return template.data.bannerCopyType.data === BannerCopyType.NON_LOYALTY;
    }

    return template.data.bannerCopyType.data === BannerCopyType.GUEST;
  });

  if (!promotions || promotions.length === 0) {
    return null;
  }

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (autoplay) {
      interval = setInterval(() => {
        if (bannerIndex + 1 < promotions.length) {
          setBannerIndex(bannerIndex + 1);
        } else {
          setBannerIndex(0);
        }
      }, speed);
    }

    return () => clearInterval(interval);
  }, [bannerIndex, autoplay, promotions.length, speed]);

  const onHidePromoBanner = useCallback(() => {
    document.body.classList.remove('site-banner__is-open');
    onActiveChange?.(false);
  }, [onActiveChange]);

  const promotion = promotions[bannerIndex];

  return (
    <>
      {active && (
        <div className="flex justify-center w-full promo-banner">
          <div className="flex justify-between w-full promo-banner-nav">
            {iconConfig && !hideArrows && (
              <Button
                aria-label={prevLabel || 'Previous'}
                className="flex visible bg-transparent promo-banner-arrow promo-banner-arrow-left"
                data-testid={PROMO_BANNER_BUTTON_LEFT}
                onClick={() =>
                  setBannerIndex(
                    bannerIndex === 0 ? promotions.length - 1 : bannerIndex - 1
                  )
                }
              >
                {prevIcon || (
                  <Icon
                    name="ArrowLeft"
                    {...iconConfig[IconConfigKeys.ARROW]}
                  />
                )}
              </Button>
            )}
            <TemplateRenderer {...promotion} />
            {iconConfig && !hideArrows && (
              <Button
                aria-label={nextLabel || 'Next'}
                className="flex visible bg-transparent promo-banner-arrow promo-banner-arrow-right"
                data-testid={PROMO_BANNER_BUTTON_RIGHT}
                onClick={() =>
                  setBannerIndex(
                    bannerIndex === promotions.length - 1 ? 0 : bannerIndex + 1
                  )
                }
              >
                {nextIcon || (
                  <Icon
                    name="ArrowRight"
                    {...iconConfig[IconConfigKeys.ARROW]}
                  />
                )}
              </Button>
            )}
          </div>
          {iconConfig && (
            <Button
              aria-label={closeLabel || 'Close'}
              className="hidden promo-banner-close"
              data-testid={PROMO_BANNER_BUTTON_CLOSE}
              onClick={onHidePromoBanner}
            >
              {closeIcon || (
                <Icon name="Close" {...iconConfig[IconConfigKeys.CLOSE]} />
              )}
            </Button>
          )}
        </div>
      )}
    </>
  );
}

export default PromoBanner;
