export const isSupportedRoute = (
  href: string,
  allowedPaths: string[] = []
): boolean => {
  const url = href.replace(/^\/+/g, '');

  return allowedPaths.includes(url);
};

export const getExternalUrl = (href: string) => {
  const domain = process.env.NEXT_PUBLIC_ORIGIN?.slice(0, -1);

  return href.startsWith('http') ? href : `${domain}${href}`;
};
