import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { ProductType } from '@gemini/shared/services/products/catalog';

export const INVENTORY_STATUS_SOLD_OUT = 'Sold Out';

const mergeProductsArray = (
  oldProducts: ProductType[],
  newProducts: ProductType[]
) => {
  const bArray =
    oldProducts.length > newProducts.length ? oldProducts : newProducts;
  const sArray =
    oldProducts.length > newProducts.length ? newProducts : oldProducts;

  if (oldProducts.length >= newProducts.length) {
    const isSameData = newProducts.every(({ productId }) =>
      oldProducts.some((pr) => pr.productId === productId)
    );

    if (isSameData) {
      return oldProducts;
    }
  }

  const productsMap = bArray.reduce(
    (productsObj: Record<string, ProductType>, product, index) => {
      productsObj[product.productId] = product;

      const sArrProduct = sArray[index];

      if (sArrProduct) {
        productsObj[sArrProduct.productId] = sArrProduct;
      }

      return productsObj;
    },
    {}
  );

  return Object.values(productsMap);
};

type ProductsUpdater = (v: ProductType[]) => void;
type ProductContextType = {
  products: ProductType[];
  useUpdateProducts: ProductsUpdater;
};

const initialValue = {
  products: [],
  useUpdateProducts: () => {
    console.warn('ProductContext using initialValue');
  }
} as ProductContextType;

const ProductContext = createContext<ProductContextType>(initialValue);

export const ProductProvider = ({
  value,
  children
}: {
  value: ProductContextType['products'];
  children: any;
}) => {
  const [products, setProducts] = useState(value || initialValue.products);

  const updateProducts = useCallback(
    (newProducts: ProductType[]) => {
      setProducts(mergeProductsArray(products, newProducts));
    },
    [products]
  );

  const newStateProducts = useMemo(
    () => mergeProductsArray(products, value),
    [products, value]
  );

  return (
    <ProductContext.Provider
      value={{ products: newStateProducts, useUpdateProducts: updateProducts }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = (): ProductContextType => {
  return useContext<ProductContextType>(ProductContext);
};

export const useProducts = (ids?: string[]) => {
  const { products } = useProductContext();

  return ids
    ? products.filter(({ productId }) => ids.includes(productId))
    : products;
};

export const useProductsWithStock = () => {
  const products = useProducts();

  return useMemo(
    () =>
      products.filter((product) =>
        product.skus
          .map((sku) => sku.inventoryStatus !== INVENTORY_STATUS_SOLD_OUT)
          .some(Boolean)
      ),
    [products]
  );
};
