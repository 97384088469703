export const Schedule = () => (
  <svg
    width={80}
    height={88}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M69.5 22a3.5 3.5 0 0 1 3.5 3.5V28h10v50H48v10.997a3 3 0 0 1-3 3H16a3 3 0 0 1-3-3v-21a3 3 0 0 1 3-3l14.385-.001L34 61.832V28h11v-2.5a3.5 3.5 0 0 1 7 0V28h14v-2.5a3.5 3.5 0 0 1 3.5-3.5Z"
        id="c"
      />
      <path
        d="M56.5 0A3.5 3.5 0 0 1 60 3.5V6h10v50H35v10.997a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-21a3 3 0 0 1 3-3l14.385-.001 3.614-3.164L21 6h11V3.5a3.5 3.5 0 0 1 7 0V6h14V3.5A3.5 3.5 0 0 1 56.5 0ZM22.855 40.867l-4.719 4.129H3a1 1 0 0 0-1 1v21a1 1 0 0 0 1 1h29a1 1 0 0 0 1-1v-21a1 1 0 0 0-1-1h-9.145v-4.129Zm-1.668 9.094c2.586 0 4.067 2.056 4.067 4.887 0 2.312-1.585 5.015-4.106 6.83a.147.147 0 0 1-.02.023c-.324.283-2.805 1.728-3.221 1.623a5.047 5.047 0 0 1-.7-.273c-3.859-1.474-6.52-5.016-6.643-7.988l-.004-.215c0-2.831 1.48-4.887 4.066-4.887 1.264 0 2.37.413 3.281 1.214.911-.801 2.017-1.214 3.28-1.214Zm0 2c-.776 0-1.425.237-1.983.736-.17.161-.323.327-.46.497-.04.05-.078.102-.116.155A4 4 0 0 0 18 54.538c-.017-.004-.269-.543-.812-1.19l-.002.001-.02-.027a6.525 6.525 0 0 0-1-.955 2.832 2.832 0 0 0-1.33-.4l-.21-.006c-1.313 0-2.066 1.046-2.066 2.887 0 2.163 2.16 5.107 5.346 6.331 3.188-1.224 5.348-4.168 5.348-6.331 0-1.841-.753-2.887-2.067-2.887ZM68 52H35v2h33v-2Zm0-31H23l-.001 17.083 1.856-1.623v6.536H32a3 3 0 0 1 3 3V51h33V21ZM41.5 44a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm10 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm9 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm-19-9a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm-10 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm20 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm9 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm-29-12a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9Zm0 2a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm10 1a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm10 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm9 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM32 8h-9v11h45V8h-8v2.5a3.5 3.5 0 0 1-7 0V8H39v2.5a3.5 3.5 0 0 1-7 0V8Zm3.5-6A1.5 1.5 0 0 0 34 3.5v7a1.5 1.5 0 0 0 3 0v-7A1.5 1.5 0 0 0 35.5 2Zm21 0A1.5 1.5 0 0 0 55 3.5v7a1.5 1.5 0 0 0 3 0v-7A1.5 1.5 0 0 0 56.5 2Z"
        id="e"
      />
      <circle id="a" cx={40} cy={40} r={40} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)">
        <path fill="#EEE" d="M-8-4h96v96H-8z" />
      </g>
      <g transform="translate(-8 -4)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <g mask="url(#d)" fill="#FFF">
          <path d="M0 0h96v96H0z" />
        </g>
      </g>
      <g transform="translate(5 18)">
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <g mask="url(#f)">
          <path fill="#9E9E9E" d="M-13-22h96v96h-96z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Schedule;
