export const TikTok = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M12.128 2.017C13.218 2 14.303 2.01 15.386 2c.065 1.276.524 2.575 1.458 3.477.932.924 2.25 1.348 3.533 1.49v3.357c-1.202-.04-2.41-.29-3.5-.807-.475-.215-.918-.492-1.351-.775-.006 2.435.01 4.867-.016 7.292a6.344 6.344 0 0 1-1.127 3.284c-1.09 1.599-2.982 2.64-4.925 2.673-1.192.068-2.383-.257-3.398-.856-1.684-.992-2.868-2.81-3.04-4.76-.02-.416-.027-.832-.01-1.24a6.247 6.247 0 0 1 2.152-4.134c1.38-1.202 3.313-1.774 5.123-1.436.017 1.235-.033 2.468-.033 3.702-.826-.267-1.793-.192-2.515.31A2.887 2.887 0 0 0 6.6 15.035c-.173.423-.123.892-.113 1.34.198 1.368 1.513 2.518 2.917 2.393.93-.01 1.822-.55 2.307-1.34.157-.277.333-.56.342-.886.082-1.49.05-2.975.06-4.466.006-3.36-.01-6.71.015-10.058v-.001Z" />
      </g>
    </g>
  </svg>
);

export default TikTok;
