export const Attention = () => (
  <svg
    width={20}
    height={18}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M15.32 4.89a3.887 3.887 0 0 0-6.67 0l-6.02 9.747C.947 17.372 2.836 21 5.964 21h12.072c3.128 0 5.017-3.597 3.335-6.364l-6.05-9.745ZM13 18.274c0 .485-.232.727-.696.727h-.608c-.464 0-.696-.242-.696-.727v-.546c0-.485.232-.727.696-.727h.608c.464 0 .696.242.696.727v.546Zm-.128-3.044c0 .482-.231.771-.641.771h-.462c-.384 0-.615-.257-.64-.77L11 9.802c0-.514.205-.803.615-.803h.77c.41 0 .615.289.615.803l-.128 5.426Z"
        id="a"
      />
    </defs>
    <g transform="translate(-2 -3)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fill="#000" fillOpacity={0.64}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default Attention;
