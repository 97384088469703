import { ChangeEvent, useCallback, useState } from 'react';
import { pgFormFetch } from '@gemini/shared/services/account/pg-email-signup';
import type { IModalProps } from '@gemini/shared/ui/organisms/modal';
import { dynamicClient } from '@gemini/shared/ui/utils/dynamic-load';

const Modal = dynamicClient<IModalProps>(() =>
  import('@gemini/shared/ui/organisms/modal').then((m) => m.Modal)
);
interface IEmailSmsSignUpFormProps {
  newsletterPlaceholder: string;
  submitText: string;
  emailTermsText: string;
  newsletterSuccessText: string;
  headerText: string;
  smsPlaceholder: string;
  smsTermsText: string;
  emailType: string;
  smsType: string;
  dataTestId: string;
  formEmailKey: string;
  formSmsKey: string;
  params: Record<string, string>;
  classes?: string;
}

const REQUEST_METHOD = 'rpc.form';

export const EmailSmsSignUpForm = ({
  newsletterPlaceholder,
  submitText,
  emailTermsText,
  newsletterSuccessText,
  headerText,
  smsPlaceholder,
  smsTermsText,
  emailType,
  smsType,
  dataTestId,
  formEmailKey,
  formSmsKey,
  params,
  classes
}: IEmailSmsSignUpFormProps) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [smsValue, setSmsValue] = useState('');
  const [emailTerms, setEmailTerms] = useState(false);
  const [smsTerms, setSmsTerms] = useState(false);
  const [showSmsTerms, setShowSmsTerms] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(true);

  const onSetEmailValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      setEmailValue(event.target.value);
    },
    []
  );

  const onSetSmsValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      setSmsValue(event.target.value);
      setShowSmsTerms(Boolean(event.target.value));
    },
    []
  );

  const onSetEmailTerms = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setEmailTerms(Boolean(event.target.checked));
    },
    []
  );

  const onSetSmsTerms = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setSmsTerms(Boolean(event.target.checked));
    },
    []
  );

  const onSubmit = async (
    event: ChangeEvent<HTMLFormElement>
  ): Promise<void> => {
    setButtonEnabled(false);
    event.preventDefault();
    const response = await pgFormFetch({
      params: {
        ...params,
        [formEmailKey]: emailValue,
        [formSmsKey]: smsValue,
        TERMS: emailTerms ? '1' : '0',
        SMS_PROMOTIONS: smsTerms ? '1' : '0'
      },
      method: REQUEST_METHOD
    });
    const responseWithErrors = response.error;

    if (responseWithErrors) {
      setError(responseWithErrors.data.messages[0].text);
    } else {
      setError('');
      setSuccess(true);
    }
    setButtonEnabled(true);
  };

  const onCloseModal = useCallback(() => setSuccess(false), []);

  return (
    <>
      <form
        data-testid={dataTestId}
        onSubmit={onSubmit}
        className={`md:pl-[13px] mb-4 ${classes}`}
      >
        <div className="signup-form__terms-heading"> {headerText} </div>
        <div className="flex flex-col w-full gap-5 md:flex-row">
          <input
            className={`${
              error ? 'border-input-error' : ''
            } signup-form__terms-input`}
            placeholder={newsletterPlaceholder}
            value={emailValue}
            name={emailType}
            onChange={onSetEmailValue}
            onFocus={(e) => (e.target.placeholder = '')}
            onBlur={(e) => (e.target.placeholder = newsletterPlaceholder)}
          ></input>
          <div className="signup-form__terms leading-[1.3]">
            <label className="relative block uppercase">
              <input
                className="signup-form__terms-checkbox"
                type="checkbox"
                checked={emailTerms}
                name="emailterms"
                onChange={onSetEmailTerms}
              ></input>
              <label
                className="signup-form__terms-text"
                dangerouslySetInnerHTML={{
                  __html: emailTermsText
                }}
              />
            </label>
          </div>
        </div>
        <div className="flex flex-col gap-5 w-full md:flex-row pt-[20px]">
          <input
            className="signup-form__terms-input"
            placeholder={smsPlaceholder}
            value={smsValue}
            name={smsType}
            onChange={onSetSmsValue}
            onFocus={(e) => (e.target.placeholder = '')}
            onBlur={(e) => (e.target.placeholder = smsPlaceholder)}
          ></input>
          {showSmsTerms && (
            <div className="signup-form__terms leading-[1.3]">
              <label className="relative block uppercase">
                <input
                  className="signup-form__terms-checkbox"
                  type="checkbox"
                  checked={smsTerms}
                  name="smsterms"
                  onChange={onSetSmsTerms}
                ></input>
                <label
                  className="signup-form__terms-text"
                  dangerouslySetInnerHTML={{
                    __html: smsTermsText
                  }}
                />
              </label>
            </div>
          )}
        </div>
        <button
          disabled={!buttonEnabled}
          className={
            'text-jordy-blue font-akzidenzgrotesk text-12px font-bold leading-[18px] uppercase border border-solid border-stickybackground px-0 py-[11px] h-10 mb-0 w-full md:w-[27%] tracking-[0.15em] mt-[18px]'
          }
          type="submit"
        >
          {submitText}
        </button>
      </form>
      {error && (
        <div
          className="inline-block font-akzidenzgrotesk mb-4 md:pl-[13px] text-venetian-red leading-[24px] tracking-[0.05em] text-[11px]"
          dangerouslySetInnerHTML={{
            __html: error
          }}
        />
      )}
      {success && (
        <Modal
          isOpen={success}
          overlay={true}
          overflow={true}
          overlayColor={'bg-navy'}
          overlayOpacity={'opacity-90'}
          contentHtml={newsletterSuccessText}
          closeModal={onCloseModal}
        />
      )}
    </>
  );
};
