import dynamic from 'next/dynamic';
import { BasicTout } from '@gemini/brand/el/ui/organisms/basic-tout';
import { MenuColumn } from '@gemini/brand/el/ui/organisms/menu-column';
import { MenuItem } from '@gemini/brand/el/ui/organisms/menu-item';
import { MenuTout } from '@gemini/brand/el/ui/organisms/menu-tout';
import { NavMobile } from '@gemini/brand/el/ui/organisms/navigation';
import { SubNavCardItem } from '@gemini/brand/el/ui/organisms/sub-nav-card-item';
import { SubNavLinks } from '@gemini/brand/el/ui/organisms/sub-nav-links';
import { BasicTextArea } from '@gemini/brand/el/ui/templates/basic-text-area';
import { CtaTout } from '@gemini/brand/el/ui/templates/cta-tout';
import type { ILoyaltySignupPanelProps } from '@gemini/brand/el/ui/templates/loyalty-signup-panel';
import { NavItem } from '@gemini/brand/el/ui/templates/nav-item';
import { ReferencedContent } from '@gemini/brand/el/ui/templates/referenced-content';
import { RichTextArea } from '@gemini/brand/el/ui/templates/rich-text-area';
import { SignInLink } from '@gemini/brand/el/ui/templates/sign-in-link';
import { Faq, FaqItem } from '@gemini/shared/ui/templates/faq';
import { LinksGroup } from '@gemini/shared/ui/templates/links-group';
import { MenuReference } from '@gemini/shared/ui/templates/menu-reference';
import { NavCard } from '@gemini/shared/ui/templates/nav-card';
import { ProductBreadcrumbs } from '@gemini/shared/ui/templates/product-breadcrumbs';
import { ITemplateMap } from '@gemini/shared/ui/templates/template-renderer';
import { dynamicClient } from '@gemini/shared/ui/utils/dynamic-load';

const PromoBannerItem = dynamic(
  () =>
    import('@gemini/shared/ui/molecules/promo-banner-item').then(
      (m) => m.PromoBannerItem
    ),
  { ssr: false }
);

const MultiUseHomepageHero = dynamic(
  () =>
    import('@gemini/brand/el/ui/templates/multi-use-homepage-hero').then(
      (m) => m.MultiUseHomepageHero
    ),
  { ssr: true }
);

const HeroBanner = dynamic(
  () =>
    import('@gemini/brand/el/ui/templates/hero-banner').then(
      (m) => m.HeroBanner
    ),
  { ssr: true }
);
const HeroCarousel = dynamic(
  () =>
    import('@gemini/brand/el/ui/templates/hero-carousel').then(
      (m) => m.HeroCarousel
    ),
  { ssr: true }
);

const ServiceView = dynamicClient(() =>
  import('@gemini/shared/services/content/next-elc-ecomm').then(
    (m) => m.ServiceView
  )
);

const CountdownClock = dynamicClient(() =>
  import('@gemini/brand/el/ui/molecules/countdown-clock').then(
    (m) => m.CountdownClock
  )
);

const ProductDetails = dynamic(
  () =>
    import('@gemini/brand/el/ui/templates/product-details').then(
      (m) => m.ProductDetails
    ),
  { ssr: true }
);

const ProductGrid = dynamic(
  () =>
    import('@gemini/brand/el/ui/templates/product-grid').then(
      (m) => m.ProductGrid
    ),
  { ssr: true }
);

const ContentFormatter = dynamic(
  () =>
    import('@gemini/brand/el/ui/templates/content-formatter').then(
      (m) => m.ContentFormatter
    ),
  { ssr: false }
);

const LoyaltySignupPanel = dynamic<ILoyaltySignupPanelProps>(
  () =>
    import('@gemini/brand/el/ui/templates/loyalty-signup-panel').then(
      (m) => m.LoyaltySignupPanel
    ),
  { ssr: false }
);

const TabbedBlock = dynamic(
  () =>
    import('@gemini/brand/el/ui/templates/tabbed-block').then(
      (m) => m.TabbedBlock
    ),
  { ssr: true }
);

const OlapicWidget = dynamic(() =>
  import('@gemini/brand/el/ui/templates/olapic-widget').then(
    (m) => m.OlapicWidget
  )
);

const ContentBlockTout = dynamic(
  () =>
    import('@gemini/brand/el/ui/templates/content-block-tout').then(
      (m) => m.ContentBlockTout
    ),
  { ssr: true }
);

const ContentBlockVideo = dynamic(
  () =>
    import('@gemini/brand/el/ui/templates/content-block-video').then(
      (m) => m.ContentBlockVideo
    ),
  { ssr: true }
);

const ContentBlockImage = dynamicClient(
  () =>
    import('@gemini/brand/el/ui/templates/content-block-image').then(
      (m) => m.ContentBlockImage
    ),
  { ssr: true }
);

const AerinBrandStyles = dynamic(
  () =>
    import('@gemini/brand/el/ui/atoms/aerin-brand-styles').then(
      (m) => m.AerinBrandStyles
    ),
  { ssr: true }
);

const TickerTapeFormatter = dynamicClient(
  () =>
    import('@gemini/brand/el/ui/templates/ticker-tape-formatter').then(
      (m) => m.TickerTapeFormatter
    ),
  { ssr: true }
);

const HolidayCountdownFormatter = dynamicClient(
  () =>
    import('@gemini/brand/el/ui/templates/holiday-countdown-formatter').then(
      (m) => m.HolidayCountdownFormatter
    ),
  { ssr: true }
);

const UtilityNavLoyalty = dynamic(
  () =>
    import('@gemini/brand/el/ui/templates/utility-nav-loyalty').then(
      (m) => m.UtilityNavLoyalty
    ),
  { ssr: false }
);

export const templateMap: ITemplateMap = {
  BasicMenuRef: MenuItem,
  BasicMenuRefAerin: MenuItem,
  BasicMenuRefRenutriv: MenuItem,
  BasicTout,
  BoutiqueX5CountdownClock: CountdownClock,
  CountdownClock,
  CtaTout,
  ElcProductBreadcrumb: ProductBreadcrumbs, // @todo remove after GEMINI-682 is deployed
  ProductBreadcrumbs,
  Faq,
  FaqItem,
  HeroBanner,
  MultiUseHomepageHero,
  LinksGroup,
  LoyaltySignupPanel,
  MenuColumn,
  MenuItem,
  MenuLink: SubNavLinks,
  MenuReference,
  MenuTout,
  NavCard,
  Navigation: NavMobile,
  NavItem,
  ProductDetails,
  PromoBannerItem,
  ReferencedContent,
  RichTextArea,
  TabbedBlock,
  Text: BasicTextArea,
  ServiceView: {
    getComponent: ({ templateData: { metadata } }) => {
      if (metadata && metadata.serviceViewName === 'product-grid') {
        return ProductGrid;
      }

      // @todo this is not getting the proper metadata, so only templateSource is available
      if (metadata && metadata.templateSource === 'elc_sd_product_spp_v1') {
        return ProductDetails;
      }

      return ServiceView;
    }
  },
  OlapicWidget,
  SubNavCardItem,
  SubNavLinks,
  HeroCarousel,
  ContentBlockTout,
  ContentBlockVideo,
  ContentBlockImage,
  ContentFormatter,
  TickerTapeFormatter,
  Holiday2020CountdownFormatter: HolidayCountdownFormatter,
  VideoReference: ReferencedContent,
  ParallaxReference: () => null,
  Utilities: ReferencedContent,
  UtilityNavLoyalty,
  SignIn: SignInLink,
  AerinBrandStyles
};
