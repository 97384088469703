import { Dropdown } from '@gemini/shared/ui/molecules/dropdown';
import { IDropdownItem } from '@gemini/shared/ui/molecules/dropdown-item';
import { REGION_DROPDOWN } from '@gemini/shared/utils/testing';

export interface IRegionDropdownProps {
  countries: IDropdownItem[];
  dropUp?: boolean;
  dropUpDefaultMaxHeight?: string;
  listClassName?: string;
  selectedIndex?: number;
  selectedItemClassName?: string;
  mobileFooterClassName?: string;
  mobileListClassName?: string;
  itemClassName?: string;
  footerDropdown?: string;
  hasFlag?: boolean;
  innerTextClassName?: string;
  dropdownListClasses?: string;
  cssDropdownArrow?: boolean;
}

export function RegionDropdown(props: IRegionDropdownProps) {
  const {
    countries,
    dropUp,
    dropUpDefaultMaxHeight,
    listClassName = '',
    selectedIndex,
    selectedItemClassName = '',
    mobileFooterClassName = '',
    mobileListClassName = '',
    itemClassName = '',
    footerDropdown = '',
    hasFlag,
    innerTextClassName,
    dropdownListClasses,
    cssDropdownArrow
  } = props;

  // @todo add handler for switching countries
  return (
    <Dropdown
      dataTestId={REGION_DROPDOWN}
      list={countries}
      selected={countries[selectedIndex ? selectedIndex : 0]}
      dropUp={dropUp}
      dropUpDefaultMaxHeight={dropUpDefaultMaxHeight}
      listClassName={listClassName}
      selectedItemClassName={selectedItemClassName}
      mobileFooterClassName={mobileFooterClassName}
      mobileListClassName={mobileListClassName}
      itemClassName={itemClassName}
      footerDropdown={footerDropdown}
      hasFlag={hasFlag}
      innerTextClassName={innerTextClassName}
      dropdownListClasses={dropdownListClasses}
      cssDropdownArrow={cssDropdownArrow}
    />
  );
}

export default RegionDropdown;
