import { COPYRIGHT } from '@gemini/shared/utils/testing';

export interface ICopyrightsProps {
  brandName?: string;
  className?: string;
}

export const Copyrights = ({
  brandName = 'LOGO',
  className = ''
}: ICopyrightsProps) => (
  <div
    data-testid={COPYRIGHT}
    className={className || 'font-bold tracking-tight text-gray-600 text-tiny'}
  >
    {brandName}
  </div>
);

export default Copyrights;
