import cn from 'classnames';
import styles from '../basic-tout.module.scss';

const cl = (className: string) => styles[className] ?? '';

export const BasicToutWrapper = ({
  children
}: {
  children: React.ReactElement[];
}) => (
  <div
    className={cn(cl('basic-tout__wrapper'), 'basic-tout__wrapper')}
    style={{ position: 'relative' }}
  >
    {children}
  </div>
);
