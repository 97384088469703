import {
  jsonRPCFetch,
  jsonRPCFetchResponse
} from '@gemini/shared/services/utils/pg-jsonrpc';

export const EMAIL_SIGN_UP_METHOD = 'rpc.form';

type DataSignUp = {
  email?: unknown;
  source?: unknown;
  phone?: unknown;
  smsPromo?: unknown;
};

export const pgEmailSignUp = async (data: DataSignUp): Promise<any> => {
  const request: any = [];
  const { email, source, phone, smsPromo } = data;
  const params = [
    {
      _SUBMIT: 'email_signup',
      LAST_SOURCE: source,
      pc_email_promotions: '1',
      PC_EMAIL_PROMOTIONS_PRESENT: '1',
      COUNTRY_ID: '1',
      LANGUAGE_ID: '48',
      redirect_or_text: 'redirect',
      redirect: '',
      SMS_PROMOTIONS_PRESENT: smsPromo ? '1' : '0',
      SMS_AFFILIATE_ID: '',
      CARRIER_CODE: 'UNKNOWN',
      _SECONDARY_SUBMIT: 'sms',
      PC_EMAIL_ADDRESS: email,
      ...(phone ? { MOBILE_PHONE: phone } : {})
    }
  ];

  request.push({
    method: EMAIL_SIGN_UP_METHOD,
    params
  });

  const url = `/api/rpc/emailSignUp?dbgmethod=${EMAIL_SIGN_UP_METHOD}`;

  const res = await jsonRPCFetch({
    url,
    request,
    useCSRFTokenParam: true,
    useCSRFTokenHeader: true
  });
  const response: any = await jsonRPCFetchResponse(res as Response);

  return response;
};
