import {
  ITemplateFieldUrl,
  TemplateType
} from '@gemini/shared/services/content/next-mantlecms';
import { PRODUCT_TOUT } from '@gemini/shared/utils/testing';
import styles from './basic-tout.module.scss';
import { BasicToutLinkWrapper } from './components/BasicToutLinkWrapper';
import { BasicToutMediaWrapper } from './components/BasicToutMediaWrapper';
import { BasicToutWrapper } from './components/BasicToutWrapper';
import {
  ContentAboveImage,
  IDisplayContentAboveImage
} from './components/ContentAboveImage';
import {
  ContentBelowImage,
  IDisplayContentBelowImage
} from './components/ContentBelowImage';
import {
  ContentOverImage,
  IDisplayContentOverImage
} from './components/ContentOverImage';
import { IDisplayMedia, MantleMedia } from './components/MantleMedia';

const cl = (className: string) => styles[className] ?? '';

export interface IBasicToutProps extends TemplateType {
  data: {
    moduleUrl: ITemplateFieldUrl;
    displayMedia: IDisplayMedia;
    displayContentAboveImage: IDisplayContentAboveImage;
    displayContentOverImage: IDisplayContentOverImage;
    displayContentBelowImage: IDisplayContentBelowImage;
  };
}

export function BasicTout({ data }: IBasicToutProps) {
  const {
    moduleUrl,
    displayMedia,
    displayContentAboveImage,
    displayContentOverImage,
    displayContentBelowImage
  } = data;

  const bgColor = displayContentOverImage.data?.backgroundColor.data.hex;

  return (
    <div
      data-testid={PRODUCT_TOUT}
      className={`${cl('basic-tout')} basic-tout`}
    >
      <BasicToutLinkWrapper link={moduleUrl.data}>
        <BasicToutWrapper>
          <ContentAboveImage content={displayContentAboveImage} />
          <BasicToutMediaWrapper bgColor={bgColor || undefined}>
            <MantleMedia content={displayMedia} />
            <ContentOverImage content={displayContentOverImage} />
          </BasicToutMediaWrapper>
          <ContentBelowImage
            content={displayContentBelowImage}
            link={moduleUrl.data}
          />
        </BasicToutWrapper>
      </BasicToutLinkWrapper>
    </div>
  );
}

export default BasicTout;
