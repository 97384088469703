export const ApplePayLogo = () => (
  <svg width={58} height={27} xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <path d="M17.062 6.946c-.217.2-.437.397-.652.6-.76.72-1.332 1.557-1.533 2.6-.279 1.445.018 2.77.894 3.958.438.595.961 1.088 1.657 1.371.08.033.163.063.263.1-.09.247-.17.49-.268.725-.566 1.373-1.304 2.644-2.309 3.746a5.56 5.56 0 0 1-.614.566c-.735.594-1.558.823-2.485.553-.463-.135-.921-.287-1.382-.43-.475-.146-.955-.264-1.456-.276-.62-.014-1.194.171-1.766.382-.435.16-.872.327-1.321.434-.708.168-1.347-.05-1.935-.448-.592-.4-1.06-.926-1.465-1.505C1.673 17.871.899 16.301.424 14.589c-.422-1.523-.54-3.067-.303-4.63.21-1.38.723-2.625 1.694-3.654C2.822 5.24 4.06 4.802 5.511 4.945c.723.073 1.414.282 2.107.484.288.084.583.147.875.22.417.106.83.058 1.233-.06.569-.167 1.13-.356 1.699-.525.845-.25 1.702-.323 2.574-.154 1.128.219 2.019.826 2.765 1.674.094.106.185.214.277.322.009.01.012.023.021.04" />
      <path d="M12.823 0c.012.248.046.481.03.711-.087 1.255-.58 2.334-1.447 3.243-.39.41-.822.77-1.356.981-.454.18-.937.218-1.419.253-.007 0-.015-.007-.023-.012C8.675 2.376 10.09.563 12.823 0M25.4 11.983c.506.133 1.145.186 1.917.186 2.796 0 4.5-1.357 4.5-3.833 0-2.37-1.678-3.515-4.233-3.515-1.012 0-1.784.08-2.184.187v6.975ZM23.084 3.33a26.848 26.848 0 0 1 4.446-.346c2.29 0 3.967.533 5.032 1.491.985.852 1.57 2.157 1.57 3.754 0 1.624-.478 2.902-1.384 3.834-1.224 1.304-3.221 1.97-5.484 1.97-.692 0-1.331-.027-1.864-.16v7.189h-2.316V3.33Zm19.06 11.156c-2.555-.054-5.457.399-5.457 2.902 0 1.517 1.011 2.236 2.21 2.236 1.677 0 2.742-1.065 3.114-2.157.08-.24.133-.505.133-.745v-2.236Zm.346 6.576-.186-1.624h-.08c-.719 1.011-2.103 1.916-3.94 1.916-2.61 0-3.94-1.836-3.94-3.7 0-3.115 2.768-4.819 7.747-4.792v-.267c0-1.065-.293-2.982-2.928-2.982-1.199 0-2.45.373-3.355.959l-.532-1.544c1.065-.692 2.609-1.145 4.233-1.145 3.94 0 4.898 2.689 4.898 5.271v4.82c0 1.117.054 2.21.213 3.088h-2.13Zm5.03-12.886 2.821 7.614c.293.852.613 1.864.826 2.636h.053c.24-.772.506-1.757.825-2.69l2.556-7.56h2.476l-3.514 9.185c-1.677 4.42-2.822 6.683-4.42 8.067-1.144 1.012-2.289 1.411-2.875 1.517l-.586-1.97a6.197 6.197 0 0 0 2.05-1.144c.64-.506 1.438-1.412 1.97-2.61.107-.24.187-.426.187-.559 0-.133-.053-.32-.16-.612L44.964 8.176h2.555Z" />
    </g>
  </svg>
);

export default ApplePayLogo;
