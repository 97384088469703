export const SameDay = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M22.265 2.136H21.09V.8a.786.786 0 0 0-.8-.801h-.535c-.427 0-.8.347-.8.8v1.336H5.071V.8a.786.786 0 0 0-.8-.801h-.535c-.427 0-.8.347-.8.8v1.336H1.762C.801 2.136 0 2.963 0 3.978v18.18C0 23.172.8 24 1.762 24h20.503c.96 0 1.762-.828 1.762-1.842V3.978c0-1.015-.801-1.842-1.762-1.842ZM22 4v4H2V4h20ZM2 22V10h20v12H2Zm14.445-7.904-5.5 5.5a.343.343 0 0 1-.266.106.343.343 0 0 1-.267-.107l-2.99-3.017a.371.371 0 0 1 0-.507l1.308-1.308a.371.371 0 0 1 .507 0l1.495 1.495 3.951-3.924a.371.371 0 0 1 .507 0l1.255 1.254a.371.371 0 0 1 0 .508Z" />
      </g>
    </g>
  </svg>
);

export default SameDay;
