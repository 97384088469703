import React, { ComponentType, useMemo } from 'react';
import type { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import { isProd } from '@gemini/shared/services/utils/global';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import type { IMappingRule } from './IMappingRule';
import { useTemplateRendererSetup } from './useTemplateRendererSetup';

function isMappingRule(data: any): data is IMappingRule {
  return !!(data && data.getComponent);
}

export function TemplateRenderer(templateData: TemplateType) {
  const { component, metadata } = templateData;
  const { templateSource } = metadata || {};

  if (!component) {
    if (!isProd()) {
      console.warn(`Component not found (source: ${templateSource})`);
    }

    return null;
  }

  const { templateMap, mobileDetectClassName, skipMobileDataProcessing } =
    useTemplateRendererSetup();

  const { isMobile } = useBreakpoint(
    mobileDetectClassName ?? 'block md:hidden'
  );

  const componentInfo = templateMap[component];

  let TemplateComponent: ComponentType | undefined | null;

  if (isMappingRule(componentInfo)) {
    const accept = componentInfo.test
      ? componentInfo.test({ templateData })
      : true;
    if (accept) {
      const computedComponent = componentInfo.getComponent({ templateData });
      TemplateComponent = computedComponent || undefined;
    } else {
      TemplateComponent = null;
    }
  } else {
    TemplateComponent = componentInfo;
  }

  const data = useMemo(() => {
    if (!TemplateComponent) {
      return {};
    }

    const componentData = { ...templateData };
    if (
      isMobile &&
      templateData.dataMobile &&
      !(skipMobileDataProcessing ?? []).includes(component)
    ) {
      componentData.data = templateData.dataMobile;
      componentData.dataMobile = null;
    }

    return componentData;
  }, [templateData, isMobile, TemplateComponent, skipMobileDataProcessing]);

  if (TemplateComponent && typeof TemplateComponent !== 'undefined') {
    return React.createElement(
      TemplateComponent,
      data as Record<string, unknown>
    );
  }

  if (isProd()) {
    return null;
  }

  console.warn(`Component ${component} not found (source: ${templateSource})`);

  return (
    <div className="p-4 text-center text-error">
      Component <strong>{component}</strong> not found (source: {templateSource}
      )
    </div>
  );
}

export default TemplateRenderer;
