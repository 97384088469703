import {
  ITemplateFieldELCProductBreadcrumb,
  ITemplateFieldLink,
  ITemplateFieldString
} from '@gemini/shared/services/content/next-mantlecms';
import { InnerHtml } from '@gemini/shared/ui/atoms/inner-html';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import { BREADCRUMBS } from '@gemini/shared/utils/testing';

interface IProductBreadcrumbsData {
  breadcrumb?: ITemplateFieldELCProductBreadcrumb;
  ariaLabel?: ITemplateFieldString;
  level1Url?: ITemplateFieldLink;
  level2Url?: ITemplateFieldLink;
  level3Url?: ITemplateFieldLink;
  level4Url?: ITemplateFieldLink;
}

export interface IProductBreadcrumbsProps {
  data?: IProductBreadcrumbsData;
}

const ProductBreadcrumbsFallbackData = {
  ariaLabel: {
    type: 'string',
    data: 'Breadcrumbs'
  },
  level1Url: {
    type: 'link_v2',
    data: {
      path: `/`,
      title: 'Home',
      target: '',
      html: `<a href="/">Home</a>`
    }
  },
  level2Url: {
    type: 'link_v2',
    data: {
      path: '',
      title: '',
      target: '',
      html: ''
    }
  },
  level3Url: {
    type: 'link_v2',
    data: {
      path: '',
      title: '',
      target: '',
      html: ''
    }
  },
  level4Url: {
    type: 'link_v2',
    data: {
      path: '',
      title: '',
      target: '',
      html: ''
    }
  }
};

const NextElement = (props: { link: ITemplateFieldLink; first?: boolean }) => {
  const { link, first } = props;
  if (link?.data?.title) {
    return (
      <>
        {!first && (
          <span className={`breadcrumbs-level breadcrumbs-level--separator`}>
            {' / '}
          </span>
        )}
        <span
          className={
            'breadcrumbs-level breadcrumbs-level--link-wrapper capitalize'
          }
        >
          {link.data.path ? (
            <SmartLink
              href={link.data.path}
              classes={'breadcrumbs-level breadcrumbs-level--link'}
            >
              {link.data.title}
            </SmartLink>
          ) : (
            <InnerHtml content={link.data.title} classes={'inline'} />
          )}
        </span>
      </>
    );
  }

  return null;
};

export function ProductBreadcrumbs({
  data = ProductBreadcrumbsFallbackData
}: IProductBreadcrumbsProps) {
  const { ariaLabel, level1Url, level2Url, level3Url, level4Url } = data;

  return (
    <div
      data-testid={BREADCRUMBS}
      className={`breadcrumbs`}
      aria-label={ariaLabel?.data}
    >
      {level1Url && <NextElement link={level1Url} first />}
      {level2Url && <NextElement link={level2Url} />}
      {level3Url && <NextElement link={level3Url} />}
      {level4Url && <NextElement link={level4Url} />}
    </div>
  );
}

export default ProductBreadcrumbs;
