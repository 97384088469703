export const Email = () => {
  return (
    <svg
      width={20}
      height={16}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(-2 -4)" fill="none" fillRule="evenodd">
        <g fill="#000" fillOpacity={0.64}>
          <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2Zm0 4-8 5-8-5V6l8 5 8-5v2Z" />
        </g>
      </g>
    </svg>
  );
};

export default Email;
