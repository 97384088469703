export const SwitchOn = () => (
  <svg
    width={38}
    height={26}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path d="M10 5h20a7 7 0 0 1 0 14H10a7 7 0 0 1 0-14Z" id="a" />
      <path
        d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0Z"
        id="c"
      />
      <filter
        x="-25%"
        y="-20%"
        width="150%"
        height="150%"
        filterUnits="objectBoundingBox"
        id="d"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={1.5}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMorphology
          radius={0.5}
          in="SourceAlpha"
          result="shadowSpreadOuter2"
        />
        <feOffset dy={2} in="shadowSpreadOuter2" result="shadowOffsetOuter2" />
        <feGaussianBlur
          stdDeviation={0.5}
          in="shadowOffsetOuter2"
          result="shadowBlurOuter2"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
        />
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter3" />
        <feGaussianBlur
          stdDeviation={0.5}
          in="shadowOffsetOuter3"
          result="shadowBlurOuter3"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
          in="shadowBlurOuter3"
          result="shadowMatrixOuter3"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="shadowMatrixOuter3" />
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-3)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#FAFAFA" xlinkHref="#a" />
        <g mask="url(#b)" fill="#70B000">
          <path d="M0 0h39.604v23.762H0z" />
        </g>
      </g>
      <g transform="translate(15 2)">
        <mask id="e" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <use fill="#000" filter="url(#d)" xlinkHref="#c" />
        <use fill="#FAFAFA" xlinkHref="#c" />
        <g mask="url(#e)">
          <path
            stroke="#E0E0E0"
            d="M18-.5A2.496 2.496 0 0 1 20.5 2v16c0 .69-.28 1.315-.732 1.768A2.492 2.492 0 0 1 18 20.5H2c-.69 0-1.315-.28-1.768-.732A2.492 2.492 0 0 1-.5 18V2c0-.69.28-1.315.732-1.768A2.492 2.492 0 0 1 2-.5Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SwitchOn;
