import { Icon } from '@gemini/shared/ui/molecules/icon';
import {
  ImageLoaderProps,
  SmartImage
} from '@gemini/shared/ui/molecules/smart-image';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import { LOGO } from '@gemini/shared/utils/testing';

const imageLoader = ({ src }: ImageLoaderProps) => {
  if (src.startsWith('https://') || src.startsWith('http://')) {
    const u = new URL(src);
    u.pathname = `/assets${u.pathname}`;

    return `${u}`;
  }

  return `/assets${src}`;
};

export interface ILogoProps {
  brandName?: string;
  imageSrc?: string;
  asImg?: boolean;
  width?: number;
  height?: number;
  className?: string;
  linkClassName?: string;
  dataTestId?: string;
}

export const Logo = ({
  brandName = 'LOGO',
  className = '',
  linkClassName = '',
  imageSrc,
  asImg,
  width,
  height,
  dataTestId = LOGO
}: ILogoProps) => {
  let content: JSX.Element = <span>{brandName}</span>;

  if (asImg && imageSrc) {
    content = (
      <SmartImage
        alt={`${brandName} logo`}
        loader={imageLoader}
        src={imageSrc}
        height={height}
        width={width}
      />
    );
  } else if (imageSrc) {
    content = (
      <div className="flex items-center justify-center overflow-hidden">
        <Icon
          dataTestId={`${dataTestId}-icon`}
          path={imageSrc}
          height={height}
          width={width}
        />
      </div>
    );
  }

  return (
    <div
      data-testid={dataTestId}
      className={`flex items-center justify-center overflow-hidden ${className}`}
    >
      <SmartLink href="/" ariaLabel={brandName} classes={linkClassName}>
        {content}
      </SmartLink>
    </div>
  );
};

export default Logo;
