export interface IFooterLinksData {
  className?: string;
  menuHtml: {
    class?: string;
    data?: string;
    menuReference?: string;
    html?: string;
  };
}

export const FooterLinks = ({ className = '', menuHtml }: IFooterLinksData) => {
  return (
    <>
      {menuHtml.data && (
        <div
          className={`md:w-1/4 md:pl-1% md:pr-3% ${className}`}
          dangerouslySetInnerHTML={{ __html: menuHtml.data }}
        ></div>
      )}
      {menuHtml.menuReference && (
        <div
          className={`md:w-1/4 md:pl-1% md:pr-3% ${className} ${
            menuHtml.class || ''
          }`}
          dangerouslySetInnerHTML={{ __html: menuHtml.menuReference }}
        ></div>
      )}
    </>
  );
};

export default FooterLinks;
