import {
  ITemplateFieldCheckbox,
  ITemplateFieldMantleCtaButton,
  ITemplateFieldMantleCustomTextFormat,
  ITemplateFieldSelect
} from '@gemini/shared/services/content/next-mantlecms';
import { MantleCustomText } from '@gemini/shared/ui/molecules/mantle-custom-text';
import styles from '../basic-tout.module.scss';
import { CtaButtons } from './CtaButtons';

export interface IDisplayContentAboveImage {
  type: 'component_group';
  data: {
    eyebrowAboveImage: ITemplateFieldMantleCustomTextFormat;
    headerAboveImage: ITemplateFieldMantleCustomTextFormat;
    contentAboveImage: ITemplateFieldMantleCustomTextFormat;
    textAlignmentAboveImage: ITemplateFieldSelect;
    primaryCtaButtonAboveImage: ITemplateFieldMantleCtaButton;
    secondaryCtaButtonAboveImage: ITemplateFieldMantleCtaButton;
    contentAboveImageHideMobile: ITemplateFieldCheckbox;
    contentAboveImageHidePc: ITemplateFieldCheckbox;
  } | null;
}

interface IContentAboveImage {
  content: IDisplayContentAboveImage;
}

const cl = (className: string) => styles[className] ?? '';

const processOptions = (
  data: ITemplateFieldMantleCustomTextFormat
): ITemplateFieldMantleCustomTextFormat => {
  if (!data.data) {
    return data;
  }

  return {
    ...data,
    data: {
      ...data.data,
      font_style: cl(data.data.font_style),
      font_color: cl(data.data.font_color)
    }
  };
};

export const ContentAboveImage = ({ content }: IContentAboveImage) => {
  if (!content?.data) {
    return null;
  }

  const {
    eyebrowAboveImage,
    headerAboveImage,
    contentAboveImage,
    primaryCtaButtonAboveImage,
    secondaryCtaButtonAboveImage
  } = content.data;

  return (
    <div className={cl('basic-tout__content-above-block')}>
      <div className={cl('basic-tout__content-above')}>
        {eyebrowAboveImage?.data.text_content && (
          <MantleCustomText
            options={processOptions(eyebrowAboveImage)}
            className={cl('basic-tout__eyebrow')}
          />
        )}
        {headerAboveImage?.data.text_content && (
          <MantleCustomText
            options={processOptions(headerAboveImage)}
            className={cl('basic-tout__header')}
          />
        )}
        {contentAboveImage?.data.text_content && (
          <MantleCustomText
            options={processOptions(contentAboveImage)}
            className={cl('basic-tout__content')}
          />
        )}
        <CtaButtons
          ctaButton1={primaryCtaButtonAboveImage}
          ctaButton2={secondaryCtaButtonAboveImage}
        />
      </div>
    </div>
  );
};
