export const HomeDelivery = () => (
  <svg
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M17 14h1.964v3H17v-3Zm7-5.586a.744.744 0 0 0-.292-.577L18.51 3.14a.686.686 0 0 0-.419-.144L10 3V0H8.036L8 3H5.995a.683.683 0 0 0-.414.14L.286 7.837a.732.732 0 0 0-.286.6l.014 2.837c.009.393.32.707.701.707l1.25.019.027 11.277c0 .4.314.723.701.723h18.711c.388 0 .64-.323.64-.723l-.011-11.258h1.255c.38 0 .713-.333.712-.725v-2.88ZM6 5h12l3.005 2.548H2.781L6 5Zm6.983 17H10.98v-5.022h2.002V22Zm7.114.01L15 22v-6.295c0-.4-.346-.705-.733-.705l-4.564-.003c-.387 0-.7.323-.7.723L9 22l-5.017.01V13h16.114v9.01ZM2 11V9h19.997v2H2Z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fillOpacity={0.54}>
        <path fill="#000" d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default HomeDelivery;
