import { Link } from '@gemini/shared/ui/atoms/links';
import { IconTypes } from '@gemini/shared/ui/atoms/toggles-map';
import { Accordion } from '@gemini/shared/ui/molecules/accordion';
import { List } from '@gemini/shared/ui/molecules/lists';
import { LINKS_GROUP } from '@gemini/shared/utils/testing';

export interface ILinkGroup {
  data: {
    path: string;
    title: string;
  };
}

export interface ILinksGroupData {
  title: string;
  accordionIconType: IconTypes;
  links: ILinkGroup[];
  link: {
    data: {
      path: string;
      title: string;
    };
  };
}
export interface ILinksGroupProps {
  component: string;
  data: ILinksGroupData;
  showAccordions?: boolean;
}

export function mapToList(data: ILinksGroupData) {
  return {
    listItem: data.title,
    subList: data.links.map((link: ILinkGroup) => ({
      listItem: link.data.title,
      link: link.data.path
    }))
  };
}

export const LinksGroup = (props: ILinksGroupProps) => {
  const listItems = mapToList(props.data);
  const mobileListItems = [...listItems.subList];
  const { link } = props.data;
  const { title, path } = link?.data || {};

  return (
    <>
      <div data-testid={LINKS_GROUP} className="hidden mx-5 md:block">
        <List classNames="cursor-pointer" listItems={[listItems]} />
        {link && (
          <div className="text-xs font-bold underline">
            <Link href={path}>{title}</Link>
          </div>
        )}
      </div>
      <div className="block md:hidden">
        <Accordion
          className="md:hidden"
          onOpenClassName={`${!props.showAccordions ? 'bg-gray-100' : ''}`}
          contentClassName={`border-t pl-0 mx-4 pt-4 pb-4 ${
            props.showAccordions ? 'ml-12' : ''
          }`}
          titleClassName={`${props.showAccordions ? 'p-4 pl-6' : ''}`}
          title={listItems.listItem}
          iconType={props.data.accordionIconType}
        >
          <List listItems={mobileListItems} classNames="space-y-4" />
        </Accordion>
      </div>
    </>
  );
};

export default LinksGroup;
