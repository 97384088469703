import { useAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import { root } from '@gemini/shared/services/configuration/utils';
import { ScrollItemsState } from './ScrollItemsState';

export const useScrollerClick = () => {
  const [lastScrollItem, setLastScrollItem] = useState<HTMLDivElement>();
  const [lastPosition, setLastPosition] = useState(0);

  const onClick = (item: HTMLDivElement) => {
    if (!lastScrollItem || lastScrollItem !== item) {
      item.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setLastPosition(root.pageYOffset);
      setLastScrollItem(item);
    } else {
      if (lastScrollItem !== item) {
        const pageYOffset = root.pageYOffset;
        root.scrollTo({ top: lastPosition, left: 0, behavior: 'smooth' });
        setLastPosition(pageYOffset);
      }
    }
  };

  return onClick;
};

export const useScrollItem = () => {
  const [scrollItems, setScrollItems] = useAtom(ScrollItemsState);
  const ref = useRef<HTMLDivElement | null>(null);

  const onRef = useCallback(
    (node: HTMLDivElement) => {
      ref.current = node;
      scrollItems.push(node);
      setScrollItems(scrollItems);
    },
    [ref]
  );

  useEffect(
    () => () => {
      setScrollItems(scrollItems.filter((item) => item === ref.current));
    },
    []
  );

  return onRef;
};
