import cn from 'classnames';
import { CSSProperties } from 'react';
import { ITemplateFieldMantleCtaButton } from '@gemini/shared/services/content/next-mantlecms';
import { Button } from '@gemini/shared/ui/atoms/buttons';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import styles from './mantle-cta-button.module.scss';

const cl = (className: string) => styles[className] ?? '';

interface IMantleCtaButtonProps {
  content: ITemplateFieldMantleCtaButton;
  className?: string;
  onClick?(): void;
}

export function MantleCtaButton({
  content: {
    data: {
      href,
      text,
      classname,
      color_vals,
      open_new_window,
      bg_color,
      border_color,
      text_color
    }
  },
  className,
  onClick
}: IMantleCtaButtonProps) {
  const ctaClassName = cn('elc-media-asset', classname);

  const customStyles: CSSProperties = color_vals
    ? {
        backgroundColor: bg_color,
        borderColor: border_color,
        color: text_color
      }
    : {};

  const target = open_new_window ? '_blank' : '_self';

  return (
    <div className={className}>
      {onClick ? (
        <Button
          onClick={onClick}
          style={customStyles}
          className={cn(ctaClassName, cl('mantle-cta-button--button'))}
        >
          <span>{text}</span>
        </Button>
      ) : (
        <SmartLink
          href={href}
          target={target}
          style={customStyles}
          classes={cn(ctaClassName, cl('mantle-cta-button--button'))}
        >
          <span>{text}</span>
        </SmartLink>
      )}
    </div>
  );
}

export default MantleCtaButton;
