export const Alipay = () => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.864 0A3.136 3.136 0 0 1 20 3.136v11.38c-2.208-.784-4.532-1.7-6.756-2.485 1.046-2.083 1.74-4.283 1.74-4.283h-4.04V6.273h4.98v-.899h-4.98V3H8.628v2.374H3.65v.899h4.978v1.475H4.441v.79h8.09s-.448 1.429-1.2 2.844c-2.171-.695-4.173-1.18-5.78-1.18-3.855 0-4.71 2.008-4.528 3.844.147 1.466 1.198 3.605 4.41 3.605 2.935 0 5.304-1.744 6.762-3.82 2.704 1.291 5.556 2.873 7.632 4.07A3.142 3.142 0 0 1 16.864 20H3.136A3.136 3.136 0 0 1 0 16.864V3.136A3.136 3.136 0 0 1 3.136 0h13.728ZM5.217 11.372c1.132 0 2.928.6 4.944 1.472-1.14 1.552-2.965 3.412-5.32 3.412-2.696 0-3.114-1.576-3.16-2.517-.044-.822.492-2.367 3.536-2.367Z"
      fill="#5590C0"
      fillRule="evenodd"
    />
  </svg>
);

export default Alipay;
