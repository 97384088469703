import cn from 'classnames';
import { IconArrowRight } from '@gemini/brand/el/base-theme';
import { Animate } from '@gemini/shared/services/configuration/utils';
import { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';

export const MenuItem = (props: TemplateType) => {
  const { templates, id } = props.data.content?.data || {};
  const {
    title: { data: title },
    url: { data: path }
  } = props.data;

  const shouldNavigate =
    templates &&
    templates.length > 0 &&
    templates.some(
      (menuItem: TemplateType) => menuItem.component === 'MenuColumn'
    );

  const handleArrowRightClick = () => {
    if (shouldNavigate) {
      props.setAnimate(Animate.RIGHT);
      props.setShowMenuLinkHeader(!props.showMenuLinkHeader);
      props.setShowMenuItems(!props.showMenuItems);
      props.setShowMenuTout(!props.showMenuTout);
      props.setSelectedMenuItem({ templates, id, title, path });
    }
  };

  return (
    <>
      {props.showMenuItems && (
        <li
          key={`${id}-mobile`}
          className={cn(
            'block',
            'menu-item cursor-pointer list-none relative flex items-center justify-center border-b border-solid border-light-gray pl-[18px]',
            { hidden: !props.showMenuItems }
          )}
          onClick={handleArrowRightClick}
        >
          {shouldNavigate ? (
            <>
              <span
                className={cn(
                  'sm:text-[18px] text-menu-link font-bold leading-[41.1px] tracking-[2.10938px] uppercase w-full text-navy font-akzidenzgrotesk'
                )}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <button
                className="absolute inset-y-0 appearance-none right-[18px] top-[10px] w-[22px] h-[22px]"
                aria-label="Expand menu"
              >
                <IconArrowRight name="ArrowRight" width="22" height="22" />
              </button>
            </>
          ) : (
            <SmartLink
              href={path}
              classes={cn(
                'sm:text-[18px] text-menu-link font-bold leading-[41.1px] tracking-[2.10938px] uppercase w-full text-navy font-akzidenzgrotesk'
              )}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
        </li>
      )}
    </>
  );
};
