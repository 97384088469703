import Image, { ImageProps } from 'next/image';
import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { getImageSizeFromUrl } from '@gemini/shared/ui/utils/image';
import { useSmartImageSetup } from './useSmartImageSetup';

type ImgElementStyle = NonNullable<JSX.IntrinsicElements['img']['style']>;

export interface ISmartImageProps
  extends Omit<ImageProps, 'onLoadingComplete' | 'alt' | 'fill'> {
  layout?: 'fill' | 'fixed';
  objectFit?: ImgElementStyle['objectFit'];
  objectPosition?: ImgElementStyle['objectPosition'];
  alt?: string;

  onLoadingComplete?(result: {
    naturalWidth: number;
    naturalHeight: number;
  }): void;
}

export const SmartImage: FC<ISmartImageProps> = (props) => {
  const [naturalWidth, setNaturalWidth] = useState<number | undefined>(
    undefined
  );
  const [naturalHeight, setNaturalHeight] = useState<number | undefined>(
    undefined
  );
  const {
    src,
    width,
    height,
    onLoadingComplete,
    loader,
    alt,
    style,
    layout,
    objectFit,
    objectPosition,
    ...rest
  } = props;

  const { transformSrc, defaultLoader } = useSmartImageSetup();

  const inferredSize = useMemo(() => {
    return src && typeof src === 'string' ? getImageSizeFromUrl(src) : null;
  }, [src]);

  const transformedSrc = useMemo<ISmartImageProps['src']>(() => {
    return transformSrc && src && typeof src === 'string'
      ? transformSrc({ src })
      : src;
  }, [src, transformSrc]);

  const onLoadingCompleteHandler = useCallback<
    NonNullable<ImageProps['onLoadingComplete']>
  >((img) => {
    setNaturalWidth(img.naturalWidth);
    setNaturalHeight(img.naturalHeight);

    onLoadingComplete?.({
      naturalWidth: img.naturalWidth,
      naturalHeight: img.naturalHeight
    });
  }, []);

  const computedWidth = useMemo(() => {
    return width ?? naturalWidth ?? inferredSize?.width ?? '1';
  }, [width, naturalWidth, inferredSize?.width]);

  const computedHeight = useMemo(() => {
    return height ?? naturalHeight ?? inferredSize?.height ?? '1';
  }, [height, naturalHeight, inferredSize?.height]);

  const fill = layout === 'fill';

  const computedStyle = useMemo<CSSProperties>(() => {
    const getLayoutFillVal = <T,>(
      val: T | undefined,
      defaultVal: T
    ): T | undefined => {
      return val ?? (fill ? defaultVal : undefined);
    };

    return {
      ...(style ?? {}),
      position: style?.position || (fill ? 'absolute' : undefined),
      top: getLayoutFillVal(style?.top, 0),
      left: getLayoutFillVal(style?.left, 0),
      height: getLayoutFillVal(style?.height, '100%'),
      width: getLayoutFillVal(style?.width, '100%'),
      objectFit: style?.objectFit ?? objectFit,
      objectPosition: style?.objectPosition ?? objectPosition
    } as CSSProperties;
  }, [style, layout, fill, objectFit, objectPosition]);

  useEffect(() => {
    setNaturalWidth(undefined);
    setNaturalHeight(undefined);
  }, [src]);

  if (!transformedSrc) {
    return null;
  }

  return (
    <Image
      {...rest}
      alt={alt ?? (src as string)}
      fill={fill}
      loader={loader ?? defaultLoader}
      src={transformedSrc}
      onLoadingComplete={onLoadingCompleteHandler}
      width={fill ? undefined : width ?? computedWidth}
      height={fill ? undefined : height ?? computedHeight}
      style={computedStyle}
    />
  );
};
