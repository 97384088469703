export const alfrescoLoader = ({ src, width }: any) => {
  const imwidth = width > 100 ? `?imwidth=${width}` : '';

  return `${src}${imwidth}`;
};

export const akamaiImageLoader = ({
  src,
  width
}: {
  src: string;
  width?: number;
}) => {
  return `${src}${width ? `?width=${width}` : ''}`;
};

let WIDTH_X_HEIGHT_REGEX: RegExp | null = null;

export interface IImageInfo {
  width: number;
  height: number;
  descriptor: string;
}

export function getImageSizeFromUrl(url: string): IImageInfo | null {
  if (!WIDTH_X_HEIGHT_REGEX) {
    WIDTH_X_HEIGHT_REGEX = /(?<width>\d{2,})x(?<height>\d{2,})/i;
  }

  const match = url.match(WIDTH_X_HEIGHT_REGEX);
  if (match) {
    const width = Number.parseInt(match.groups?.width ?? '0', 10);
    const height = Number.parseInt(match.groups?.height ?? '0', 10);

    return {
      width,
      height,
      descriptor: '1x'
    };
  }

  return null;
}

export function getImageSizesFromSrcset(srcSet: string): IImageInfo[] {
  const parts = srcSet.split(',');
  const result: IImageInfo[] = [];
  for (const part of parts) {
    const [src, ...sizeDescriptors] = part.trim().split(' ');
    const info = getImageSizeFromUrl(src);
    if (info) {
      result.push({
        ...info,
        descriptor: sizeDescriptors.length ? sizeDescriptors.join(' ') : '1x'
      });
    }
  }

  return result;
}
