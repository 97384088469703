import { useAppContext } from '@gemini/shared/services/configuration/app-client';
import { Logo } from '@gemini/shared/ui/molecules/logo';
import { BRAND_LOGO } from '@gemini/shared/utils/testing';

export interface IBrandLogoProps {
  imageSrc?: string;
  asImg?: boolean;
  width?: number;
  height?: number;
  className?: string;
  linkClassName?: string;
  dataTestId?: string;
}

export function BrandLogo({
  className = '',
  linkClassName = '',
  imageSrc,
  asImg,
  width,
  height,
  dataTestId = BRAND_LOGO
}: IBrandLogoProps) {
  const {
    brand: { literal: brandName }
  }: any = useAppContext();

  return !brandName ? null : (
    <Logo
      brandName={brandName}
      asImg={asImg}
      imageSrc={imageSrc}
      width={width}
      height={height}
      className={className}
      linkClassName={linkClassName}
      dataTestId={dataTestId}
    />
  );
}

export default BrandLogo;
