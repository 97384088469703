export const Choose = () => (
  <svg
    width={80}
    height={88}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M59 0a4 4 0 0 1 4 4v27.789l1.276 2.21L70 34v9.106L64 70H31l-5.792-26.002L4 44a4 4 0 0 1-4-4v-3l5-.001V4a4 4 0 0 1 4-4h50Z"
        id="c"
      />
      <path
        d="M59 0a4 4 0 0 1 4 4v27.789l1.276 2.21L70 34v9.106L64 70H31l-5.792-26.002L4 44a4 4 0 0 1-4-4v-3l5-.001V4a4 4 0 0 1 4-4h50Zm8.974 43H27.035l5.569 25h29.793l5.577-25ZM38 49a2 2 0 0 1 2 2v10a2 2 0 1 1-4 0V51a2 2 0 0 1 2-2Zm10 0a2 2 0 0 1 2 2v10a2 2 0 1 1-4 0V51a2 2 0 0 1 2-2Zm9 0a2 2 0 0 1 2 2v10a2 2 0 1 1-4 0V51a2 2 0 0 1 2-2Zm-19 1a1 1 0 0 0-1 1v10a1 1 0 0 0 2 0V51a1 1 0 0 0-1-1Zm10 0a1 1 0 0 0-1 1v10a1 1 0 0 0 2 0V51a1 1 0 0 0-1-1Zm9 0a1 1 0 0 0-1 1v10a1 1 0 0 0 2 0V51a1 1 0 0 0-1-1ZM30.576 36H27v2.999L63 39H27v3h41v-6h-3.273a3 3 0 0 1-5.575 1.124L58.5 36H36.578l-.48.831a3 3 0 0 1-5.522-.83ZM5 38.999 2 39v1a2 2 0 0 0 2 2l21-.002V39H5v-.001ZM59 2H9a2 2 0 0 0-2 2v32.999h18V35H9V4h50v20.861l2 3.464V4a2 2 0 0 0-2-2Zm-3.25 23.232a1 1 0 0 0-.366 1.366l5.5 9.526a1 1 0 0 0 1.732-1l-5.5-9.526a1 1 0 0 0-1.366-.366Zm-17.616.072-5.5 9.526a1 1 0 0 0 1.732 1l5.5-9.526a1 1 0 1 0-1.732-1ZM58 5H10v29h20.811c.029-.058.059-.114.09-.17l5.5-9.526a3 3 0 1 1 5.197 3l-3.866 6.695h19.615l-3.695-6.4A3 3 0 0 1 58 23.66V5Z"
        id="e"
      />
      <circle id="a" cx={40} cy={40} r={40} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)">
        <path fill="#EEE" d="M-8-4h96v96H-8z" />
      </g>
      <g transform="translate(5 18)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <g mask="url(#d)">
          <path fill="#FFF" d="M-13-22h96v96h-96z" />
        </g>
      </g>
      <g transform="translate(5 18)">
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <use fill="#9E9E9E" fillRule="nonzero" xlinkHref="#e" />
        <g mask="url(#f)">
          <path fill="#9E9E9E" d="M-13-22h96v96h-96z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Choose;
