import useSWR from 'swr';
import { ProductType } from '@gemini/shared/services/products/catalog';
import { getLogger } from '@gemini/shared/utils/logger';

type Error = { error: any };
export type GenericLoader = {
  data: any;
  error: any;
  isLoading: boolean;
};

export const clientPageDataFetcher = async (url: string) =>
  fetch('/api/pageData', {
    body: JSON.stringify(url),
    method: 'POST'
  })
    .then(async (res) => {
      const data = await res.json();

      if (!data || data.error) {
        throw new Error(data.error || 'Page data error');
      }

      return data;
    })
    .catch((err) => {
      getLogger<false>().logError(err);

      return [];
    });

const productsFetcher = (...ids: string[]) =>
  fetch('/api/products', {
    body: JSON.stringify(ids),
    method: 'POST'
  })
    .then(async (res): Promise<ProductType[]> => {
      const data = await res.json();

      if (!data || data.error) {
        throw new Error(data.error || 'No products found');
      }

      return data;
    })
    .catch((err) => {
      getLogger<false>().logError(err);

      return [];
    });

export const nodeFetcher = async (id: string) =>
  fetch('/api/node', {
    body: JSON.stringify(id),
    method: 'POST'
  })
    .then(async (res) => {
      const data = await res.json();
      if (!data || data.error) {
        throw new Error(data.error || 'Node data error');
      }

      return data;
    })
    .catch((err) => {
      getLogger<false>().logError(err);

      return [];
    });

export const useGetNode = (id: string): GenericLoader => {
  const result = useSWR(id, nodeFetcher, {
    revalidateOnFocus: false
  });
  const { data, error } = result;
  const returnObj = {
    data,
    error,
    isLoading: Boolean(!error && !data)
  };

  return returnObj;
};

export const useGetProducts = (ids: string[]): GenericLoader => {
  const { data, error } = useSWR<ProductType[], Error>(ids, productsFetcher, {
    revalidateOnFocus: false
  });

  return {
    data,
    error,
    isLoading: !error && !data
  };
};
