export const VenmoWhite = () => (
  <svg
    width={77}
    height={15}
    viewBox="0 0 77 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M71.464.051C75.518.051 77 2.444 77 5.747c0 4.37-2.313 8.894-7.823 8.894-4.08 0-5.432-2.679-5.432-6.033 0-4.42 2.339-8.557 7.719-8.557Zm-49.706 0c2.833 0 4.417 1.588 4.417 3.798 0 3.563-4.572 4.654-7.224 4.68 0 1.588.883 2.21 2.053 2.21 1.274 0 2.494-.311 4.08-1.117l-.597 4.056c-1.117.546-2.859.91-4.549.91-4.287 0-5.822-2.6-5.822-5.85 0-4.213 2.496-8.687 7.642-8.687ZM12.656 0c.52.859.755 1.743.755 2.86 0 3.563-3.042 8.192-5.51 11.443H2.26L0 .78 4.937.311l1.196 9.622c1.117-1.82 2.496-4.68 2.496-6.63 0-1.067-.183-1.794-.469-2.393L12.656 0Zm24.465.052c2.444 0 3.303 1.248 3.303 3.121 0 .52-.08 1.274-.158 1.767l-1.481 9.363h-4.808l1.352-8.583c.025-.232.104-.701.104-.961 0-.624-.39-.78-.859-.78-.623 0-1.247.286-1.663.494l-1.532 9.83h-4.835L28.753.286h4.184l.053 1.119c.987-.65 2.287-1.353 4.131-1.353Zm22.045 0c2.468 0 3.327 1.248 3.327 3.121 0 .52-.079 1.274-.157 1.767l-1.48 9.362h-4.809l1.377-8.763c.026-.234.079-.52.079-.701 0-.703-.39-.859-.859-.859-.597 0-1.194.26-1.637.494l-1.533 9.83h-4.807l1.377-8.764c.025-.234.077-.52.077-.701 0-.703-.39-.859-.858-.859-.624 0-1.247.286-1.662.494l-1.534 9.83h-4.833L43.442.286h4.133l.13 1.17c.962-.701 2.26-1.404 4.002-1.404 1.507 0 2.494.65 2.99 1.534C56.073.599 57.373.051 59.166.051Zm11.752 3.693c-1.897 0-2.287 3.354-2.287 5.07 0 1.302.364 2.107 1.22 2.107 1.794 0 2.21-3.537 2.21-5.253 0-1.144-.286-1.924-1.143-1.924ZM21.29 3.303c-.91 0-2.105 1.091-2.235 2.652.909 0 3.196-.416 3.196-1.716 0-.624-.441-.936-.961-.936Z"
      fill="#FFF"
    />
  </svg>
);

export default VenmoWhite;
