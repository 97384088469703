export const PayPal = () => (
  <svg
    width={18}
    height={20}
    viewBox="0 0 18 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M14.56 1.509C13.626.453 11.94 0 9.78 0H3.515a.896.896 0 0 0-.886.75L.02 17.164a.535.535 0 0 0 .532.616h3.867l.971-6.113-.03.193a.893.893 0 0 1 .884-.75h1.838c3.61 0 6.437-1.455 7.263-5.664.025-.125.064-.365.064-.365.235-1.557-.001-2.614-.85-3.572Z"
        fill="#273983"
      />
      <path
        d="M16.388 5.879c-.898 4.144-3.761 6.336-8.306 6.336H6.434L5.204 20h2.673a.783.783 0 0 0 .775-.657l.03-.166.616-3.863.04-.213a.782.782 0 0 1 .774-.657h.488c3.16 0 5.632-1.273 6.354-4.954.29-1.478.15-2.716-.566-3.611Z"
        fill="#4199DD"
      />
    </g>
  </svg>
);

export default PayPal;
