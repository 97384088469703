import NextLink from 'next/link';
import { CSSProperties, HTMLAttributeAnchorTarget } from 'react';
import { useAppContext } from '@gemini/shared/services/configuration/app-client';
import {
  getExternalUrl,
  isSetToAllRoutesExternal,
  isSupportedRoute
} from '@gemini/shared/services/utils/global';
import { Link } from '@gemini/shared/ui/atoms/links';

export interface ISmartLinkProps {
  href: string;
  external?: boolean;
  classes?: string;
  children?: React.ReactNode;
  ariaLabel?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  dangerouslySetInnerHTML?: { __html: string };
  target?: HTMLAttributeAnchorTarget;
  style?: CSSProperties;
}

export const SmartLink = ({
  external = false,
  target = '_self',
  ...props
}: ISmartLinkProps) => {
  const { allowedPaths } = useAppContext();

  const {
    classes = 'cursor-pointer',
    children,
    ariaLabel,
    href = '',
    ...restProps
  } = props;

  const allProps = {
    'aria-label': ariaLabel,
    className: `${classes}`,
    href,
    ...restProps
  };

  const externalHref = getExternalUrl(href);

  if (
    isSetToAllRoutesExternal() ||
    external ||
    !isSupportedRoute(href, allowedPaths)
  ) {
    return (
      <Link {...allProps} href={externalHref} target={target}>
        {children}
      </Link>
    );
  }

  return <NextLink {...allProps}>{children}</NextLink>;
};
