export const ArrowDown = (props: any) => {
  return (
    <svg
      width={12}
      height={8}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g transform="translate(-6 -8)" fill="none" fillRule="evenodd">
        <g fill="#000" fillOpacity={0.54}>
          <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z" />
        </g>
      </g>
    </svg>
  );
};

export default ArrowDown;
