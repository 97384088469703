export const CheckOut = () => (
  <svg
    width={80}
    height={88}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M39 0v26h27a4 4 0 0 1 4 4v23a4 4 0 0 1-4 4H39v12.797l-4.824-4.777-4.823 4.777-4.824-4.777-4.824 4.777-4.824-4.777L9.853 70l-5.03-4.98L0 69.797V0l5.234 4.777L10.058 0l4.823 4.777L19.705 0l4.824 4.777L29.353 0l4.823 4.777L39 0Z"
        id="c"
      />
      <path
        d="M39 0v26h27a4 4 0 0 1 4 4v23a4 4 0 0 1-4 4H39v12.797l-4.824-4.777-4.823 4.777-4.824-4.777-4.824 4.777-4.824-4.777L9.853 70l-5.03-4.98L0 69.797V0l5.234 4.777L10.058 0l4.823 4.777L19.705 0l4.824 4.777L29.353 0l4.823 4.777L39 0Zm-9.647 2.815L24.529 7.59l-4.824-4.776-4.824 4.776-4.823-4.776-4.768 4.72-3.29-3v60.469l2.824-2.796 5.029 4.98 5.028-4.98 4.824 4.776 4.824-4.776 4.824 4.776 4.823-4.776L37 65.002 36.999 57H25a4 4 0 0 1-4-4v-3H8.5a.5.5 0 1 1 0-1H21V30a4 4 0 0 1 4-4h11.999L37 4.795l-2.824 2.796-4.823-4.776ZM68 38H23v15a2 2 0 0 0 2 2h41a2 2 0 0 0 2-2V38ZM17.5 54a.5.5 0 1 1 0 1h-9a.5.5 0 1 1 0-1h9Zm40-4a.5.5 0 1 1 0 1h-9a.5.5 0 1 1 0-1h9ZM44 47v4H26v-4h18Zm19.5 0a.5.5 0 1 1 0 1h-15a.5.5 0 1 1 0-1h15Zm-50-3a.5.5 0 1 1 0 1h-5a.5.5 0 1 1 0-1h5ZM66 28H25a2 2 0 0 0-2 2v1h45v-1a2 2 0 0 0-2-2ZM11.008 13.898v1.579c.916.083 1.626.427 2.129 1.03.502.605.754 1.425.754 2.462h-.93c0-.818-.212-1.468-.637-1.95-.424-.481-1.004-.722-1.738-.722-.74 0-1.316.194-1.73.582-.415.388-.622.905-.622 1.55 0 .626.19 1.114.57 1.466.343.316.901.615 1.676.898l.266.094c.914.31 1.578.606 1.992.89.414.284.724.62.93 1.008.206.388.309.853.309 1.395 0 .854-.267 1.543-.801 2.066-.534.524-1.267.822-2.2.895v1.484h-.789v-1.477c-1.046-.067-1.856-.397-2.43-.988-.572-.591-.859-1.4-.859-2.426h.938c0 .813.237 1.45.71 1.91.475.461 1.136.692 1.985.692.74 0 1.343-.198 1.809-.594.466-.396.7-.911.7-1.547 0-.578-.188-1.05-.563-1.418-.375-.367-1.037-.714-1.985-1.043-1.177-.37-2.002-.808-2.476-1.316-.474-.508-.711-1.165-.711-1.973 0-.849.26-1.537.78-2.066.522-.529 1.23-.83 2.126-.902v-1.579h.797ZM26.5 21a.5.5 0 1 1 0 1h-5a.5.5 0 1 1 0-1h5Zm4-5a.5.5 0 1 1 0 1h-9a.5.5 0 1 1 0-1h9Z"
        id="e"
      />
      <circle id="a" cx={40} cy={40} r={40} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)">
        <path fill="#EEE" d="M-8-4h96v96H-8z" />
      </g>
      <g transform="translate(5 18)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <g mask="url(#d)">
          <path fill="#FFF" d="M-13-22h96v96h-96z" />
        </g>
      </g>
      <g transform="translate(5 18)">
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <g mask="url(#f)">
          <path fill="#9E9E9E" d="M-13-22h96v96h-96z" />
        </g>
      </g>
    </g>
  </svg>
);

export default CheckOut;
