import { createContext } from 'react';
import { IMegamenu } from './IMegamenu';

function createMegamenuContextData(): IMegamenu {
  function noop() {
    // noop
  }

  return {
    close: noop,
    show: noop,
    hide: noop,
    active: null
  };
}

export const MegamenuContext = createContext<IMegamenu>(
  createMegamenuContextData()
);
