import { CustomText } from '@gemini/brand/el/ui/molecules/custom-text';
import {
  ITemplateFieldCheckbox,
  ITemplateFieldColor,
  ITemplateFieldImage,
  ITemplateFieldOriginsCustomTextFormat,
  ITemplateFieldString,
  TemplateType
} from '@gemini/shared/services/content/next-mantlecms';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';

const getPosition = (position: string) => {
  if (position === 'top') {
    return 'sm:justify-start';
  } else if (position === 'bottom') {
    return 'sm:justify-end';
  } else {
    return 'sm:justify-center';
  }
};

const getPositionMob = (positionMob: string) => {
  if (positionMob === 'top') {
    return 'justify-start';
  } else if (positionMob === 'bottom') {
    return 'justify-end';
  } else {
    return 'justify-center';
  }
};

const getAlignment = (alignment: string) => {
  if (alignment === 'left') {
    return `sm:items-start sm:text-${alignment}`;
  } else if (alignment === 'right') {
    return `sm:items-end sm:text-${alignment}`;
  } else {
    return `sm:items-center sm:text-center`;
  }
};

const getAlignmentMob = (alignmentMob: string) => {
  if (alignmentMob === 'left') {
    return `items-start text-${alignmentMob}`;
  } else if (alignmentMob === 'right') {
    return `items-end text-${alignmentMob}`;
  } else {
    return 'items-center text-center';
  }
};

const getWidth = (width: string): string => {
  switch (width) {
    case '100':
      return 'w-full';
    case '90':
      return 'w-[90%]';
    case '80':
      return 'w-[80%]';
    case '70':
    default:
      return 'w-[70%]';
    case '60':
      return 'w-[60%]';
    case '50':
      return 'w-[50%]';
    case '40':
      return 'w-[40%]';
    case '30':
      return 'w-[30%]';
    case '20':
      return 'w-[20%]';
  }
};

const getImageWidth = (width: string): string => {
  switch (width) {
    case '100':
      return 'w-full';
    case '90':
      return 'w-[90%]';
    case '80':
      return 'w-[80%]';
    case '70':
      return 'w-[70%]';
    case '60':
      return 'w-[60%]';
    case '50':
      return 'w-[50%]';
    case '40':
      return 'w-[40%]';
    case '30':
      return 'w-[30%]';
    case '20':
      return 'w-[20%]';
    default:
      return 'w-full';
  }
};

const getColor = (rgb: string | null, hex: string | null) => {
  return rgb || hex;
};

const getScaleHeightToText = (isShow: boolean) => {
  if (isShow) {
    return 'px-5';
  }

  return '';
};

const getTextUnderImg = (value: boolean) => {
  if (value) {
    return '';
  } else {
    return 'absolute';
  }
};

const getTextUnderImgMobile = (value: boolean) => {
  if (value) {
    return '';
  } else {
    return 'sm:absolute';
  }
};

interface ICtaToutProps extends TemplateType {
  data: {
    chatIdMobile: ITemplateFieldString;
    chatIdPc: ITemplateFieldString;
    copy: ITemplateFieldString;
    copyOptions: ITemplateFieldOriginsCustomTextFormat;
    ctaButtonStyle: ITemplateFieldString;
    ctaJsOverride: ITemplateFieldString;
    ctaLink: ITemplateFieldString;
    ctaText: ITemplateFieldString;
    eyebrow: ITemplateFieldString;
    eyebrowOptions: ITemplateFieldOriginsCustomTextFormat;
    imageMaxWidth: ITemplateFieldString;
    largeImage: ITemplateFieldImage;
    moduleBgColor: ITemplateFieldColor;
    moduleBorder: ITemplateFieldCheckbox;
    moduleBorderColor: ITemplateFieldColor;
    moduleEnableBgColor: ITemplateFieldCheckbox;
    moduleName: ITemplateFieldString;
    moduleScaleHeightToText: ITemplateFieldCheckbox;
    moduleType: ITemplateFieldString;
    moduleUrl: ITemplateFieldString;
    productButtonText: ITemplateFieldString;
    productEnableShadePicker: ITemplateFieldCheckbox;
    productImage: ITemplateFieldImage;
    removeLazyload: ITemplateFieldCheckbox;
    smallImage: ITemplateFieldImage;
    smsIdMobileIos: ITemplateFieldString;
    textAlignment: ITemplateFieldString;
    textAlignmentMob: ITemplateFieldString;
    textColor: ITemplateFieldString;
    textPosition: ITemplateFieldString;
    textPositionMob: ITemplateFieldString;
    textUnderMobileImage: ITemplateFieldCheckbox;
    textUnderPcImage: ITemplateFieldCheckbox;
    textWidth: ITemplateFieldString;
    textWidthMob: ITemplateFieldString;
    title: ITemplateFieldString;
    titleOptions: ITemplateFieldOriginsCustomTextFormat;
  };
}

export function CtaTout(props: ICtaToutProps) {
  const { data } = props;

  const {
    copy,
    copyOptions,
    ctaButtonStyle,
    eyebrow,
    eyebrowOptions,
    imageMaxWidth,
    moduleBgColor,
    moduleBorder,
    moduleBorderColor,
    moduleEnableBgColor,
    moduleName,
    moduleScaleHeightToText,
    moduleType,
    moduleUrl,
    removeLazyload,
    textColor,
    titleOptions,
    textWidth,
    textWidthMob,
    title,
    ctaLink,
    ctaText,
    largeImage,
    smallImage,
    textPosition,
    textPositionMob,
    textAlignment,
    textAlignmentMob,
    textUnderMobileImage,
    textUnderPcImage,
    productImage
  } = data;

  const setBorder = (isExist: boolean) => {
    if (isExist && moduleBorderColor.data.rgb && moduleBorderColor.data.hex) {
      return `1px solid ${getColor(
        moduleBorderColor.data.rgb,
        moduleBorderColor.data.hex
      )}`;
    }

    return 'none';
  };

  const titleClasses = `${
    titleOptions.data.font_style
      ? ''
      : 'text-lg sm:text-2xl font-futura-dem leading-tight'
  } ${getWidth(textWidthMob.data)} sm:${getWidth(textWidth.data)} xsm:w-full`;
  const copyClasses = `text-lg font-futura-book leading-tight ${getWidth(
    textWidthMob.data
  )} sm:${getWidth(textWidth.data)} xsm:w-full`;

  return (
    <a
      className={`h-full min-h-[180px] md:min-h-full ${getScaleHeightToText(
        !!moduleScaleHeightToText.data
      )}`}
      href={ctaLink.data}
      style={{
        background: moduleEnableBgColor.data
          ? getColor(moduleBgColor.data.rgb, moduleBgColor.data.hex) || 'none'
          : 'none',
        color: textColor.data
      }}
      data-trackname={moduleName?.data || moduleType?.data || 'touts-cta'}
    >
      {eyebrow.data && (
        <CustomText options={eyebrowOptions} content={eyebrow.data} />
      )}

      <div className={'relative'} data-clickable={moduleUrl?.data}>
        <picture
          className={`block mx-auto my-0 ${getImageWidth(
            imageMaxWidth.data.slice(6, imageMaxWidth.data.length)
          )}`}
        >
          <source srcSet={smallImage.data.src} media="(max-width: 640px)" />
          <source srcSet={largeImage.data.src} media="(min-width: 640px)" />
          <img
            alt={largeImage.data.alt}
            loading={removeLazyload?.data ? 'eager' : 'lazy'}
          />
        </picture>

        {productImage?.data && (
          <div>
            <SmartImage
              src={productImage.data.src}
              data-src={productImage.data.src}
              alt={productImage.data.alt}
            />
          </div>
        )}

        <div
          className={`flex px-2.5 py-4 flex-col top-0 h-full w-full
            ${getPositionMob(textPositionMob.data)}
            ${getPosition(textPosition.data)}
            ${getAlignmentMob(textAlignmentMob.data)}
            ${getAlignment(textAlignment.data)}
            ${getScaleHeightToText(!!moduleScaleHeightToText.data)}
            ${getTextUnderImg(!!textUnderPcImage.data)}
            ${getTextUnderImgMobile(!!textUnderMobileImage.data)}
          `}
          style={{
            border: setBorder(!!moduleBorder.data)
          }}
          data-modulename={moduleName?.data}
          data-moduletype={moduleType?.data}
        >
          {title.data && (
            <CustomText
              options={titleOptions}
              content={title.data}
              className={titleClasses}
            />
          )}

          {copy?.data && (
            <CustomText
              options={copyOptions}
              content={copy.data}
              className={copyClasses}
            />
          )}

          {ctaText.data && (
            <button className={`${ctaButtonStyle.data || 'btn-primary'}  mt-1`}>
              {ctaText.data}
            </button>
          )}
        </div>
      </div>
    </a>
  );
}
