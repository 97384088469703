import Script from 'next/script';

interface ILpConfig {
  accountId: string;
  cStatus: string;
  siteName: string;
}

const LpConfig = ({ accountId, cStatus, siteName }: ILpConfig) => (
  <Script
    id="lp-configuration"
    dangerouslySetInnerHTML={{
      __html: `
        var lpConfig = {
            account_id: '${accountId}',
            cstatus: '${cStatus}',
            // lp_header_div: 'header_chat',
            // lp_mobile_skin_regimen_div: 'account_chat',
            // lp_mobile_spp_sticky_chat_div: 'LP_DIV_1510606631657',
            // lp_pc_spp_sticky_chat_div: 'LP_DIV_1473880849665',
            // lp_pc_sticky_chat_div: 'footer_sticky_chat',
            // lp_skin_regimen_div: 'account_chat',
            site_name: '${siteName}'
        };
      `
    }}
  />
);

const LpInit = () => (
  <Script
    id="lp-init"
    dangerouslySetInnerHTML={{
      __html: `
        if (window.location == window.parent.location) {
            window.lpTag = window.lpTag || {};
            if (typeof window.lpTag._tagCount === 'undefined') {
                window.lpTag = {
                    site: (!!lpConfig && lpConfig.account_id) || '',
                    section: lpTag.section || '',
                    autoStart: lpTag.autoStart === false ? false : true,
                    ovr: lpTag.ovr || {},
                    _v: '1.6.0',
                    _tagCount: 1,
                    protocol: 'https:',
                    events: {
                        bind: function(app, ev, fn) {
                            lpTag.defer(function() {
                                lpTag.events.bind(app, ev, fn);
                            }, 0);
                        },
                        trigger: function(app, ev, json) {
                            lpTag.defer(function() {
                                lpTag.events.trigger(app, ev, json);
                            }, 1);
                        }
                    },
                    defer: function(fn, fnType) {
                        if (fnType == 0) {
                            this._defB = this._defB || [];
                            this._defB.push(fn);
                        } else if (fnType == 1) {
                            this._defT = this._defT || [];
                            this._defT.push(fn);
                        } else {
                            this._defL = this._defL || [];
                            this._defL.push(fn);
                        }
                    },
                    load: function(src, chr, id) {
                        var t = this;
                        setTimeout(function() {
                            t._load(src, chr, id);
                        }, 0);
                    },
                    _load: function(src, chr, id) {
                        var url = src;
                        if (!src) {
                            url = this.protocol + '//' + ((this.ovr && this.ovr.domain) ? this.ovr.domain : 'lptag.liveperson.net') + '/tag/tag.js?site=' + this.site;
                        }
                        var s = document.createElement('script');
                        s.setAttribute('charset', chr ? chr : 'UTF-8');
                        if (id) {
                            s.setAttribute('id', id);
                        }
                        s.setAttribute('src', url);
                        document.getElementsByTagName('head').item(0).appendChild(s);
                    },
                    init: function() {
                        this._timing = this._timing || {};
                        this._timing.start = (new Date()).getTime();
                        var that = this;
                        if (window.attachEvent) {
                            window.attachEvent('onload', function() {
                                that._domReady('domReady');
                            });
                        } else {
                            window.addEventListener('DOMContentLoaded', function() {
                                that._domReady('contReady');
                            }, false);
                            window.addEventListener('load', function() {
                                that._domReady('domReady');
                            }, false);
                        }
                        if (typeof (window._lptStop) == 'undefined') {
                            this.load();
                        }
                    },
                    start: function() {
                        this.autoStart = true;
                    },
                    _domReady: function(n) {
                        if (!this.isDom) {
                            this.isDom = true,
                            this.events.trigger('LPT', 'DOM_READY', {
                                t: n
                            });
                        }
                        this._timing[n] = (new Date()).getTime();
                    },
                    vars: lpTag.vars || [],
                    dbs: lpTag.dbs || [],
                    ctn: lpTag.ctn || [],
                    sdes: lpTag.sdes || [],
                    ev: lpTag.ev || []
                };
                lpTag.init();
            } else {
                window.lpTag._tagCount += 1;
            }
        }
        `
    }}
  />
);

const LpSection = () => (
  <Script
    id="lp-section"
    dangerouslySetInnerHTML={{
      __html: `
        (function() {
            function getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i <ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return '';
            }

            var lpTag = lpTag || {};

            if (window.location === window.parent.location) {
                const locale = getCookie('LOCALE');
                const site_name = lpConfig.site_name[locale] ? lpConfig.site_name[locale] : lpConfig.site_name;
                let deviceName;
                lpTag.section = [site_name];

                var getMobileOperatingSystem = function() {
                    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                    if (/windows phone/i.test(userAgent)) {
                        return 'Windows';
                    } else if (/android/i.test(userAgent)) {
                        return 'Android';
                    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                        return 'iOS';
                    } else {
                        return 'unknown';
                    }
                };

                deviceName = getMobileOperatingSystem().toLowerCase();
                lpTag.section.push('or_' + deviceName);
            }
        });
      `
    }}
  />
);

const LpSdes = () => (
  <Script
    id="lp-sdes"
    dangerouslySetInnerHTML={{
      __html: `
        if (window.location == window.parent.location) {
            lpTag.sdes = lpTag.sdes || [];
            lpTag.sdes.push({
                "type": "ctmrinfo",
                "info": {"cstatus": lpConfig.cstatus }
            });
        }
      `
    }}
  />
);

const LpTrackVisitorError = () => (
  <Script
    id="lp-sdes"
    dangerouslySetInnerHTML={{
      __html: `
        lpTag.sdes = lpTag.sdes || [];
        lpTag.sdes.push({
            type: "error",
            error: {
                message: message,
                code: code
            }
        });
      `
    }}
  />
);

export const LivePersonLibraries = () => {
  if (process.env.NEXT_PUBLIC_LIVEPERSON_KILLSWITCH === 'true') {
    return null;
  }

  const accountId = process.env.NEXT_PUBLIC_LIVEPERSON_ACCOUNT_ID || '';
  const cStatus = process.env.NEXT_PUBLIC_LIVEPERSON_CSTATUS || '';
  const siteName = process.env.NEXT_PUBLIC_LIVEPERSON_SITE_NAME || '';
  const includeLibraries = !!accountId && !!cStatus && !!siteName;

  return includeLibraries ? (
    <>
      <LpConfig accountId={accountId} cStatus={cStatus} siteName={siteName} />
      <LpInit />
      <LpSection />
      <LpSdes />
      <LpTrackVisitorError />
    </>
  ) : null;
};

export default LivePersonLibraries;
