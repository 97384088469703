import cn from 'classnames';
import { useMemo } from 'react';
import { ITemplateFieldMantleMedia } from '@gemini/shared/services/content/next-mantlecms';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import {
  SmartSource,
  SmartVideo
} from '@gemini/shared/ui/molecules/smart-video';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import {
  alfrescoLoader,
  getImageSizeFromUrl
} from '@gemini/shared/ui/utils/image';
import styles from '../basic-tout.module.scss';

export interface IDisplayMedia {
  type: 'component_group';
  data: {
    image_mantle: ITemplateFieldMantleMedia;
  } | null;
}

interface IMantleMedia {
  content: IDisplayMedia;
}

const cl = (className: string) => styles[className] ?? '';

export const MantleMedia = ({ content }: IMantleMedia) => {
  if (!content.data) {
    return null;
  }

  const { isMobile } = useBreakpoint('block md:hidden');

  const image = content?.data?.image_mantle.data;

  const {
    alt,
    device_image_url,
    device_mp4_url,
    image_aspect_ratio,
    image_classes,
    lazyload,
    mobile_image_url,
    mobile_mp4_url,
    video_aspect_ratio
  } = image;

  const videoUrl = isMobile
    ? mobile_mp4_url
      ? mobile_mp4_url
      : device_mp4_url
    : device_mp4_url;

  const imageUrl = isMobile
    ? mobile_image_url
      ? mobile_image_url
      : device_image_url
    : device_image_url;

  const imageSize = useMemo(() => {
    const info = getImageSizeFromUrl(`${imageUrl}`);
    if (info) {
      const { width, height } = info;

      return { width, height };
    }

    return { width: 0, height: 0 };
  }, [imageUrl]);

  return (
    <div className={cl('basic-tout__media')}>
      <div
        className={cn(cl('basic-tout__media-asset'), 'basic-tout__media-asset')}
      >
        {videoUrl && (
          <SmartVideo
            autoPlay
            muted
            loop
            playsInline
            src={videoUrl}
            className={cn(
              cl('basic-tout__media-asset--video'),
              video_aspect_ratio
            )}
          >
            <SmartSource src={videoUrl} type="video/mp4" />
          </SmartVideo>
        )}
        {!videoUrl && imageUrl && (
          <div className={cl('basic-tout__media-asset--image-wrapper')}>
            <SmartImage
              loader={alfrescoLoader}
              alt={alt}
              src={imageUrl}
              priority={!lazyload}
              className={cn(
                image_aspect_ratio,
                image_classes,
                cl('basic-tout__media-asset--image')
              )}
              width={imageSize.width}
              height={imageSize.height}
            />
          </div>
        )}
      </div>
    </div>
  );
};
