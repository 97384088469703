export const ApplePay = () => (
  <svg
    width={16}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M17.363 12.627c-.025-2.533 2.03-3.748 2.121-3.808-1.154-1.72-2.952-1.955-3.593-1.982-1.53-.158-2.985.917-3.762.917-.774 0-1.973-.894-3.242-.87-1.668.025-3.206.987-4.064 2.507-1.733 3.06-.444 7.595 1.245 10.077.825 1.215 1.81 2.58 3.101 2.53 1.245-.05 1.715-.819 3.22-.819 1.504 0 1.927.82 3.243.795 1.34-.026 2.187-1.239 3.007-2.457.948-1.41 1.338-2.775 1.361-2.845-.03-.013-2.61-1.02-2.637-4.045ZM14.89 5.194c.686-.847 1.149-2.022 1.022-3.194-.987.04-2.185.67-2.893 1.515-.636.75-1.193 1.945-1.044 3.094 1.103.088 2.229-.57 2.915-1.415Z"
        id="a"
      />
    </defs>
    <g transform="translate(-4 -2)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fill="#000" fillOpacity={0.86}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default ApplePay;
