export const SelectAfterPay = () => (
  <svg
    width={80}
    height={88}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M72 22a4 4 0 0 1 4 4v34.5c4.25 3.192 7 8.275 7 14C83 84.165 75.165 92 65.5 92c-9.16 0-16.675-7.037-17.437-16H17a4 4 0 0 1-4-4V26a4 4 0 0 1 4-4h55Z"
        id="c"
      />
      <path
        d="M61 0a4 4 0 0 1 4 4v4h-.001v30.647C69.259 41.847 72 46.862 72 52.5 72 62.165 63.941 70 54 70c-9.421 0-17.152-7.037-17.935-16H4.127C1.847 54 0 52.207 0 50V3.994L.005 3.8A4 4 0 0 1 4 0h57Zm-7 37c-8.633 0-15.663 6.67-15.935 15h.117a15.3 15.3 0 0 0 .066 2h-.117c.776 7.857 7.585 14 15.869 14 8.805 0 15.943-6.94 15.943-15.5C69.943 43.94 62.805 37 54 37Zm-4.75 23c1.146 0 2.083.9 2.083 2s-.937 2-2.083 2c-1.146 0-2.073-.9-2.073-2s.927-2 2.073-2Zm10.417 0c1.145 0 2.083.9 2.083 2s-.938 2-2.083 2c-1.146 0-2.073-.9-2.073-2s.927-2 2.073-2Zm-13.26-16 4.437 9h7.312l4.021-7 1.823.96-4.02 7.01A2.088 2.088 0 0 1 58.155 55h-7.76l-1.146 2h12.5v2h-12.5c-1.583 0-2.583-1.63-1.823-2.97l1.406-2.44-3.75-7.59H43v-2h3.406ZM62.934 8H2.063v42c0 1.104.924 2 2.064 2h31.88C36.28 42.565 44.231 35 54 35c3.252 0 6.303.838 8.936 2.305L62.935 8ZM55.5 43v3h3.125v2H55.5v3h-2.083v-3h-3.125v-2h3.125v-3H55.5ZM15 38a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6Zm-1 2h-4v4h4v-4Zm20 1a1 1 0 0 1 0 2H22a1 1 0 0 1 0-2h12ZM15 26a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6Zm-1 2h-4v4h4v-4Zm39 1a1 1 0 0 1 0 2H22a1 1 0 0 1 0-2h31ZM15 14a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6Zm-1.39 2.188-2.098 2.621L10.3 17.9a.5.5 0 0 0-.6.8l1.6 1.2a.5.5 0 0 0 .69-.088l2.4-3a.5.5 0 1 0-.78-.624ZM46 17a1 1 0 0 1 0 2H21a1 1 0 0 1 0-2h25ZM6.19 2c-1.14 0-2.063.895-2.063 2s.924 2 2.063 2c1.14 0 2.064-.895 2.064-2S7.33 2 6.19 2Zm6.191 0c-1.14 0-2.064.895-2.064 2s.924 2 2.064 2c1.14 0 2.063-.895 2.063-2s-.923-2-2.063-2Zm6.19 0c-1.14 0-2.063.895-2.063 2s.924 2 2.063 2c1.14 0 2.064-.895 2.064-2s-.924-2-2.064-2Z"
        id="e"
      />
      <circle id="a" cx={40} cy={40} r={40} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)">
        <path fill="#EEE" d="M-8-4h96v96H-8z" />
      </g>
      <g transform="translate(-8 -4)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <g mask="url(#d)" fill="#FFF">
          <path d="M0 0h96v96H0z" />
        </g>
      </g>
      <g transform="translate(5 18)">
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <g mask="url(#f)">
          <path fill="#9E9E9E" d="M-13-22h96v96h-96z" />
        </g>
      </g>
    </g>
  </svg>
);

export default SelectAfterPay;
