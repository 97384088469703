export const Facebook = () => (
  <svg
    width={9}
    height={17}
    viewBox="0 0 9 17"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-8 -4)" fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M10.538 21v-7.604H8v-3.021h2.538v-2.39c0-1.262.352-2.242 1.055-2.94C12.297 4.35 13.231 4 14.395 4c.946 0 1.715.044 2.308.133v2.69h-1.582c-.594 0-1 .132-1.22.398-.176.221-.264.575-.264 1.062v2.092h2.802l-.395 3.021h-2.407V21h-3.099Z" />
      </g>
    </g>
  </svg>
);

export default Facebook;
