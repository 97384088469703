import React from 'react';
import { isProd } from '@gemini/shared/services/utils/global';
import { layoutMap } from './layoutMap';

export function LayoutRenderer({
  component,
  children
}: {
  component: any;
  children?: React.ReactChild;
}) {
  const { data, metadata } = component;
  const { component: name } = component;

  const LayoutComponent = layoutMap[name];

  if (typeof LayoutComponent !== 'undefined') {
    return React.createElement(LayoutComponent, { data }, children);
  }

  if (isProd()) {
    return null;
  }

  const { templateSource } = metadata || {};

  console.warn(`Component ${name} not found (source: ${templateSource})`);

  return (
    <div className="p-4 text-center text-error">
      Component <strong>{name}</strong> not found (source: {templateSource})
    </div>
  );
}

export default LayoutRenderer;
