import { useRef } from 'react';
import { StickyFooter } from '@gemini/brand-el-ui-organisms-sticky-footer';
import {
  FooterLinksGroup,
  IFooterLinksGroupData
} from '@gemini/brand/el/ui/organisms/footer-links-group';
import { FooterBottom } from '@gemini/brand/el/ui/templates/footer-bottom';
import type { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import { LivePersonLibraries } from '@gemini/shared/services/utils/next-liveperson';
import { useInViewport } from '@gemini/shared/ui/utils/hooks';

export interface IFooterColumnsLayoutProps {
  data: {
    language?: string;
    templates: TemplateType[];
  };
}

const DesktopFooter = ({ templates }: IFooterLinksGroupData) => {
  const footerLinksGroup = templates;

  return (
    footerLinksGroup && (
      <FooterLinksGroup templates={footerLinksGroup} classes="md:pt-78px" />
    )
  );
};

export function FooterColumnsLayout({ data }: IFooterColumnsLayoutProps) {
  const footerRef = useRef<HTMLDivElement>(null);
  const mobileFooterRef = useRef<HTMLDivElement>(null);

  const footerMain = data.templates.find(
    ({ component }) => component === 'ResponsiveFooter'
  );

  const footerLink = data.templates.find(
    ({ component }) => component === 'FooterLink'
  );

  const footerBottom = data.templates.find(
    ({ component }) => component === 'FooterSecondary'
  );

  const stickyFooterLeft = data.templates.find(
    ({ component }) => component === 'StickyFooterLeft'
  );

  const stickyFooterRightOffer = data.templates.find(
    ({ component }) => component === 'StickyFooterRightOffer'
  );

  const stickyFooterMenuReference = data.templates.filter(
    ({ component }) => component === 'MenuReference'
  );

  const { inViewport: isDesktopFooterVisible } = useInViewport(
    footerRef,
    { rootMargin: '0px' },
    { disconnectOnLeave: false }
  );

  const { inViewport: isMobileFooterVisible } = useInViewport(
    footerRef,
    { rootMargin: '0px' },
    { disconnectOnLeave: false }
  );

  return (
    <footer className="relative z-[101] bg-navy color-white">
      <LivePersonLibraries />
      <StickyFooter
        className="w-full text-white sticky-footer"
        footerLeftTemplates={stickyFooterLeft?.data.templates}
        footerRightTemplates={stickyFooterRightOffer?.data.templates}
        isDesktopFooterVisible={isDesktopFooterVisible}
        isMobileFooterVisible={isMobileFooterVisible}
        stickyFooterMenuReference={stickyFooterMenuReference[0].data}
        language={data.language}
      />
      {
        <div className="md:mx-auto page-footer" ref={footerRef}>
          {footerMain?.data.templates && (
            <DesktopFooter templates={footerMain.data.templates} />
          )}
        </div>
      }

      {footerBottom && <FooterBottom templates={footerBottom.data.templates} />}
    </footer>
  );
}

export default FooterColumnsLayout;
