/* eslint-disable @typescript-eslint/ban-ts-comment, react-hooks/rules-of-hooks */
import { createContext, useContext, useMemo } from 'react';
import { useBreakpoint } from './useBreakpoint';
import { useOrientation } from './useOrientation';

export type DeviceSize = 'mobile' | 'tablet' | 'desktop';

interface IScreenInfoSetup<S extends keyof any> {
  sizes: S[];
  classNames: Record<S, string>;
  classNamesAndUp: Record<S, string>;
  classNamesAndDown: Record<S, string>;
}

export const ScreenInfoSetupContext = createContext<
  IScreenInfoSetup<DeviceSize>
>({
  sizes: ['mobile', 'desktop', 'tablet'],
  classNames: {
    mobile: 'block md:hidden',
    tablet: 'hidden md:block lg:hidden',
    desktop: 'hidden lg:block'
  },
  classNamesAndDown: {
    mobile: 'block md:hidden',
    tablet: 'block lg:hidden',
    desktop: 'block'
  },
  classNamesAndUp: {
    mobile: 'block',
    tablet: 'hidden md:block',
    desktop: 'hidden lg:block'
  }
});

type SuffixMap<T, S extends keyof any, E> = {
  [Property in T as `${string & Property}${string & S}`]: E;
};

export type DeviceSizes<S> = SuffixMap<S, '', boolean>;
export type DeviceSizesAndUp<S> = SuffixMap<S, 'AndUp', boolean>;
export type DeviceSizesAndDown<S> = SuffixMap<S, 'AndDown', boolean>;
export type DeviceSizeClassName<S> = SuffixMap<S, 'ClassName', string>;
export type DeviceSizeAndUpClassName<S> = SuffixMap<
  S,
  'AndUpClassName',
  string
>;
export type DeviceSizeAndDownClassName<S> = SuffixMap<
  S,
  'AndDownClassName',
  string
>;

export type IUSeScreenInfoResult<S extends keyof any> = DeviceSizes<S> &
  DeviceSizesAndUp<S> &
  DeviceSizesAndDown<S> &
  DeviceSizeClassName<S> &
  DeviceSizeAndUpClassName<S> &
  DeviceSizeAndDownClassName<S> & {
    isPortrait: boolean;
    isLandscape: boolean;
  };

export const useScreenInfo = <
  S extends keyof any = DeviceSize
>(): IUSeScreenInfoResult<S> => {
  const { sizes, classNames, classNamesAndUp, classNamesAndDown } = useContext(
    ScreenInfoSetupContext
  ) as unknown as IScreenInfoSetup<S>;
  const staticResponse = useMemo(() => {
    const staticResult: Partial<IUSeScreenInfoResult<S>> = {};

    sizes.forEach((sz, idx) => {
      // @ts-ignore
      staticResult[`${sz}ClassName`] = classNames[sz];
      // @ts-ignore
      staticResult[`${sz}AndUpClassName`] = classNamesAndUp[sz];
      // @ts-ignore
      staticResult[`${sz}AndDownClassName`] = classNamesAndDown[sz];
    });

    return staticResult;
  }, [classNames, classNamesAndUp, classNamesAndDown]);

  const { isPortrait, isLandscape } = useOrientation();

  const result: IUSeScreenInfoResult<S> = {
    isPortrait,
    isLandscape,
    ...staticResponse
  } as unknown as IUSeScreenInfoResult<S>;

  sizes.forEach((sz, idx) => {
    // @ts-ignore
    result[sz] = useBreakpoint(classNames[sz]).isMobile;
    // @ts-ignore
    result[`${sz}AndDown`] = useBreakpoint(classNamesAndDown[sz]).isMobile;
    // @ts-ignore
    result[`${sz}AndUp`] = useBreakpoint(classNamesAndUp[sz]).isMobile;
  });

  return result;
};
