export const MagnifyingGlass = (props: any) => {
  return (
    <svg
      width={18}
      height={18}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        transform="translate(-2 21) scale(1 -1)"
        fill="none"
        fillRule="evenodd"
      >
        <g fill="#000" fillOpacity={0.54}>
          <path d="M15.502 9.491h-.794l-.276.274A6.47 6.47 0 0 1 16 13.991a6.5 6.5 0 1 1-6.5-6.5 6.47 6.47 0 0 1 4.225 1.566l.276-.274v-.792L18.999 3l1.491 1.491-4.988 5Zm-6.002 0a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Z" />
        </g>
      </g>
    </svg>
  );
};

export default MagnifyingGlass;
