import { FOOTER_SIGN_UP } from '@gemini/shared/utils/testing';
import { EmailSignUp, IEmailSignUpCMSData } from './EmailSignUp';
import { EmailSmsSignUp, IEmailSmsSignUpCMSData } from './EmailSmsSignUp';
import { ISmsSignUpCMSData, SmsSignUp } from './SmsSignUp';
import { IToolTipCMSData, ToolTip } from './ToolTip';

export interface IFooterSignupProps {
  smsSignUp?: ISmsSignUpCMSData;
  emailSignUp?: IEmailSignUpCMSData;
  emailSmsSignUp?: IEmailSmsSignUpCMSData;
  toolTip?: IToolTipCMSData;
}

export function FooterSignup({
  smsSignUp,
  emailSignUp,
  emailSmsSignUp,
  toolTip
}: IFooterSignupProps) {
  return (
    <div
      data-testid={FOOTER_SIGN_UP}
      className="flex flex-col w-full md:flex-row md:w-1/2"
    >
      {emailSignUp && (
        <div className="w-full md:w-[49%] px-[25px] md:px-0 footer-signup-email">
          <EmailSignUp emailSignUpData={emailSignUp} />
        </div>
      )}
      {emailSmsSignUp && (
        <div className="w-full px-[20px] md:px-0 footer-signup-email">
          <EmailSmsSignUp emailSmsSignUpData={emailSmsSignUp} />
          {toolTip && <ToolTip toolTipData={toolTip} />}
        </div>
      )}
      {smsSignUp && (
        <div className="w-full md:w-[51%] px-[20px] md:px-0 pl-[20px] md:pl-[2%] footer-signup-sms">
          <SmsSignUp smsSignUpData={smsSignUp} />
        </div>
      )}
    </div>
  );
}

export default FooterSignup;
