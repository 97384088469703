export const Close = () => {
  return (
    <svg
      width={14}
      height={14}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(-5 -5)" fill="none" fillRule="evenodd">
        <g fill="#000" fillOpacity={0.54}>
          <path d="M19 6.4 17.6 5 12 10.6 6.4 5 5 6.4l5.6 5.6L5 17.6 6.4 19l5.6-5.6 5.6 5.6 1.4-1.4-5.6-5.6z" />
        </g>
      </g>
    </svg>
  );
};

export default Close;
