import {
  ITemplateFieldMenu,
  ITemplateFieldString,
  TemplateType
} from '@gemini/shared/services/content/next-mantlecms';

export interface IMenuReferenceProps extends TemplateType {
  data: {
    menuReference: ITemplateFieldMenu;
    class: ITemplateFieldString;
  };
}

export function MenuReference(props: IMenuReferenceProps) {
  const {
    data: {
      menuReference,
      class: { data: className = '' }
    }
  } = props;

  return (
    <div
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      className={`menu-reference ${className}`}
      dangerouslySetInnerHTML={{ __html: menuReference.data }}
    />
  );
}

export default MenuReference;
