import React, { ReactNode } from 'react';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import { HAMBURGER } from '@gemini/shared/utils/testing';

export interface IHamburgerProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  isOpen: boolean;
  iconMenu?: ReactNode;
  iconClose?: ReactNode;
}

export const Hamburger = ({
  onClick,
  isOpen = false,
  iconMenu,
  iconClose
}: IHamburgerProps) => {
  return (
    <div
      data-testid={HAMBURGER}
      className="flex items-center justify-center h-full w-38px"
      onClick={onClick}
      title="Navigation"
    >
      {!isOpen && (iconMenu || <Icon name="Menu" width="20" height="20" />)}
      {isOpen && (iconClose || <Icon name="Close" width="20" height="20" />)}
    </div>
  );
};

export default Hamburger;
