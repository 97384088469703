import { IBasicMenuCMSData } from './basicMenuDataMapper';

export interface ISearchCMSData {
  label: {
    data: string;
  };
  noResultsMessage: {
    data: string;
  };
  typeHeaderTitle: {
    data: string;
  };
  searchLinks: {
    data: {
      templates: IBasicMenuCMSData[];
    };
  };
}

export const searchDataMapper = (searchData: ISearchCMSData) => {
  return {
    placeholder: searchData.label.data,
    noResultsLabel: searchData.noResultsMessage.data,
    searchResultsTitle: searchData.typeHeaderTitle.data,
    searchLinks: searchData.searchLinks.data.templates
  };
};
