import { RefObject, useRef } from 'react';
import { useMegamenu } from '@gemini/brand/el/ui/organisms/use-megamenu';
import { BasicMenuRef } from '@gemini/brand/el/ui/templates/basic-menuref';
import { BasicMenuRefAerin } from '@gemini/brand/el/ui/templates/basic-menuref-aerin';
import { BasicMenuRefRenutriv } from '@gemini/brand/el/ui/templates/basic-menuref-renutriv';
import { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';

interface INavDesktopProps {
  navLinks: any[];
  classes?: string;
  menuRef: RefObject<HTMLDivElement>;
}

export const NavDesktop = ({ navLinks, classes }: INavDesktopProps) => {
  const { show, hide } = useMegamenu();

  const ULRef = useRef<HTMLUListElement>(null);

  return (
    <div className={classes}>
      <ul
        ref={ULRef}
        className={`flex flex-wrap items-center scroll-smooth mt-1px text-navy`}
      >
        {navLinks &&
          navLinks.map((navLink: TemplateType, idx) => {
            const key =
              navLink.data.url?.data || navLink.data.content?.data?.title;

            return (
              <li
                className={`px-1em first:pl-0 text-tiniest uppercase whitespace-nowrap font-bold hover:underline underline-offset-[3px] leading-default tracking-normal h-full flex items-center`}
                key={`${key}-${idx}`}
                onMouseOver={() => {
                  show(key);
                }}
                onMouseLeave={(e) => {
                  const mousePositionY = e.clientY;
                  const mousePositionX = e.clientX;
                  const rect = (
                    e.target as HTMLElement
                  ).getBoundingClientRect();
                  if (
                    mousePositionY >= rect.bottom ||
                    mousePositionX <= rect.left ||
                    mousePositionX >= rect.right
                  ) {
                    hide(key);
                  }
                }}
              >
                {navLink.component === 'BasicMenu' && (
                  <SmartLink
                    href={navLink.data.url?.data}
                    classes="basic-menu__link"
                  >
                    {navLink.data.title?.data}
                  </SmartLink>
                )}
                {navLink.component === 'BasicMenuRef' && (
                  <BasicMenuRef {...navLink} showCloseMenu />
                )}
                {navLink.component === 'BasicMenuRefRenutriv' && (
                  <BasicMenuRefRenutriv {...navLink} showCloseMenu />
                )}
                {navLink.component === 'BasicMenuRefAerin' && (
                  <BasicMenuRefAerin {...navLink} showCloseMenu />
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
};
