export const YouTube = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M17.846 5H6.154A4.154 4.154 0 0 0 2 9.154v5.845a4.154 4.154 0 0 0 4.154 4.154h11.692A4.154 4.154 0 0 0 22 14.999V9.154A4.154 4.154 0 0 0 17.846 5Zm-2.809 7.36-5.469 2.61a.22.22 0 0 1-.314-.198V9.39a.22.22 0 0 1 .319-.196l5.469 2.772a.22.22 0 0 1-.005.394Z" />
      </g>
    </g>
  </svg>
);

export default YouTube;
