export const Twitter = () => (
  <svg
    width={20}
    height={17}
    viewBox="0 0 20 17"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-2 -4)" fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M19.93 8.062c.026.105.039.274.039.508 0 1.875-.456 3.685-1.367 5.43-.938 1.849-2.253 3.32-3.946 4.414-1.849 1.224-3.971 1.836-6.367 1.836-2.292 0-4.388-.612-6.289-1.836.286.026.612.04.977.04 1.9 0 3.606-.587 5.117-1.759-.912 0-1.712-.267-2.403-.8a4.08 4.08 0 0 1-1.425-2.012c.26.026.507.039.742.039.364 0 .729-.04 1.094-.117a4.133 4.133 0 0 1-2.344-1.446 3.932 3.932 0 0 1-.938-2.578v-.078c.573.339 1.185.52 1.836.547a4.55 4.55 0 0 1-1.328-1.484 3.94 3.94 0 0 1-.508-1.973c0-.716.196-1.4.586-2.05a11.31 11.31 0 0 0 3.73 3.046 11.354 11.354 0 0 0 4.708 1.25 5.693 5.693 0 0 1-.078-.937c0-.73.182-1.413.546-2.051.365-.638.86-1.14 1.485-1.504A3.962 3.962 0 0 1 15.828 4c.599 0 1.152.117 1.66.352a4.64 4.64 0 0 1 1.348.937 8.345 8.345 0 0 0 2.617-.977c-.312.964-.911 1.72-1.797 2.266A8.81 8.81 0 0 0 22 5.914a9.016 9.016 0 0 1-2.07 2.148Z" />
      </g>
    </g>
  </svg>
);

export default Twitter;
