import { ITransformSrc } from './ISmartImageSetup';

export const MobileTransformSrc: ITransformSrc = ({ src }) => {
  const pathPrefixes = ['https:', 'http:', 'data:'];
  // starting slash required
  const pathRewritesPrefix = ['/media/'];

  const hasPrefix = pathPrefixes.some((p) => src.startsWith(p));
  if (!hasPrefix) {
    const path = src.startsWith('/') ? src : `/${src}`;
    const isRewritePath = pathRewritesPrefix.some((p) => path.startsWith(p));

    if (isRewritePath) {
      // assume mobile image - ok for desktop to do a redirect
      return `https://m.${process.env.NEXT_PUBLIC_HOST}${path}`;
    }
  }

  return src;
};
