import cn from 'classnames';
import { IconArrowRight } from '@gemini/brand/el/base-theme';
import {
  IMenuLinkProps,
  MenuLink
} from '@gemini/brand/el/ui/molecules/menu-link';
import {
  IMenuToutProps,
  MenuTout
} from '@gemini/brand/el/ui/organisms/menu-tout';
import {
  ITemplateFieldCheckbox,
  ITemplateFieldSelect,
  TemplateType
} from '@gemini/shared/services/content/next-mantlecms';
import type { MakeOptional } from '@gemini/shared/services/utils/global';
import { useBreakpoint, useScreenInfo } from '@gemini/shared/ui/utils/hooks';
import styles from './MenuColumn.module.scss';

export interface ISiteHeaderMenuFormatter extends TemplateType {
  data: {
    level: ITemplateFieldSelect;
    columnWidth: ITemplateFieldSelect;
    center: ITemplateFieldCheckbox;
  };
}

export interface ITemplateFieldNodeRefDrop {
  type: string;
  data: {
    id: string;
    title: string;
    language: string;
    path: string;
    metadata: any;
    product: {
      backend: string;
      references: {
        categories: any[];
        products: any[];
        skus: any[];
      };
    };
    templates: (IMenuColumnProps | IMenuLinkProps | IMenuToutProps)[];
    formatter: ISiteHeaderMenuFormatter;
  };
}

export interface IMenuColumnProps
  extends MakeOptional<TemplateType, 'component' | 'metadata'> {
  data: {
    nodeRef: {
      data: Pick<ITemplateFieldNodeRefDrop['data'], 'templates'>;
    };
    level?: ITemplateFieldSelect;
  };
  logo?: string;
}

const cl = (className: string) => styles[className] ?? '';

export const MenuColumn = (props: IMenuColumnProps) => {
  const menuLinkData = props.data.nodeRef.data;
  const { templates } = menuLinkData;
  let shouldHideMenuLink = false;

  const { isDesktop } = useBreakpoint('block md:hidden');

  const handleMenuLinkClick = (title: string) => {
    let isSelected = false;

    const selectedCategoryExpandable = templates
      .map((template: any) => {
        const data = template.dataMobile ?? template.data;
        const updatedTemplate = {
          ...template,
          data
        } as any;

        if (
          !isSelected &&
          updatedTemplate.data.categoryExpandable.data &&
          title === updatedTemplate.data.title
        ) {
          isSelected = true;

          return updatedTemplate;
        } else if (isSelected) {
          if (updatedTemplate.data.categoryExpandable.data) {
            isSelected = false;
          } else {
            return updatedTemplate;
          }
        }
      })
      .filter(Boolean);

    props.handleMenuLinkClick &&
      props.handleMenuLinkClick({
        ...menuLinkData,
        templates: selectedCategoryExpandable
      });
  };

  return (
    <div className={cl('menu-column')}>
      <ul>
        {(templates || []).map((template: any, i) => {
          const desktopData = { ...template, dataMobile: null };

          if (
            !shouldHideMenuLink &&
            desktopData.component === 'MenuLink' &&
            desktopData.data?.categoryHeader?.data &&
            desktopData.data?.categoryExpandable?.data
          ) {
            shouldHideMenuLink = true;
          }

          const data = desktopData as IMenuLinkProps;
          const menuToutTemplate = template as IMenuToutProps;

          const isMenuLinkComponent = data.component === 'MenuLink';
          const isMenuToutComponent = data.component === 'MenuTout';

          const className = isMenuLinkComponent
            ? isDesktop
              ? cn('menulink', {
                  'menulink--with-href': data.data.url?.data,
                  'menulink--without-href': !data.data.url?.data,
                  'menulink--has-category': data.data.categoryHeader?.data,
                  'menulink--category': !data.data.categoryHeader?.data,
                  'menulink--expandable': data.data.categoryExpandable?.data,
                  'menulink--first-element': data.firstElement
                })
              : `${i === 0 ? 'first-element' : ''}`
            : isMenuToutComponent
            ? isDesktop
              ? 'hidden md:block'
              : ''
            : '';

          const menuKey = isMenuLinkComponent
            ? data.data.title?.data.replace(/ /g, '+') || 'menu+link'
            : menuToutTemplate.data.toutHeader?.data.replace(/ /g, '+') ||
              'menu+tout';

          return (
            <li key={`menulink-${menuKey}-${i}`} className={className}>
              {isMenuLinkComponent && (
                <MenuLink
                  {...data}
                  hideMenuLink={shouldHideMenuLink}
                  showMenuItems={props.showMenuItems}
                  setShowMenuItems={props.setShowMenuItems}
                  showMenuLinkHeader={props.showMenuLinkHeader}
                  setShowMenuLinkHeader={props.setShowMenuLinkHeader}
                  showMenuLink={props.showMenuLink}
                  setShowMenuLink={props.setShowMenuLink}
                  selectedMenuItem={props.selectedMenuItem}
                  setSelectedMenuItem={props.setSelectedMenuItem}
                  selectedMenuLinkHeader={props.selectedMenuLinkHeader}
                  setSelectedMenuLinkHeader={props.setSelectedMenuLinkHeader}
                  selectedMenuLinkHeaderTitle={
                    props.selectedMenuLinkHeaderTitle
                  }
                  setSelectedMenuLinkHeaderTitle={
                    props.setSelectedMenuLinkHeaderTitle
                  }
                  showMenuTout={props.showMenuTout}
                  setShowMenuTout={props.setShowMenuTout}
                  handleMenuLinkClick={handleMenuLinkClick}
                  animate={props.animate}
                  setAnimate={props.setAnimate}
                  iconArrowRight={
                    <IconArrowRight name="ArrowRight" width="22" height="22" />
                  }
                />
              )}

              {isMenuToutComponent && (
                <div className="hidden lg-up:block">
                  <MenuTout {...menuToutTemplate} />
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MenuColumn;
