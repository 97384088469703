export const Minus = (props: any) => {
  return (
    <svg
      width={13}
      height={1}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g transform="translate(-5 -11)" fill="none" fillRule="evenodd">
        <g fill="#000" fillOpacity={0.54}>
          <path d="M19 13H5v-2h14z" />
        </g>
      </g>
    </svg>
  );
};

export default Minus;
