import { FooterSubLinks } from '@gemini/brand/el/ui/organisms/footer-sublinks';
import type { TemplateType } from '@gemini/shared/services/content/next-mantlecms';

export interface IFooterLinksGroupData {
  templates: TemplateType[];
  classes?: string;
}
export function FooterLinksGroup({
  classes,
  templates
}: IFooterLinksGroupData) {
  return (
    <div className={classes ? `${classes}` : `md:pt-78px`}>
      {templates &&
        templates.map(
          (column, i) =>
            column.data && <FooterSubLinks key={i} data={column.data} />
        )}
    </div>
  );
}

export default FooterLinksGroup;
