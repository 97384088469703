import { ComponentType, useEffect, useState, Suspense } from 'react';
import dynamic from 'next/dynamic';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IA {}

export const dynamicConditional =
  <T extends IA>(loadCondition: (p: T) => boolean, ...args: Parameters<typeof dynamic<T>>): ComponentType<T> =>
    (props: T) => {
      const [Component, setComponent] = useState<ComponentType<T> | null>(null);
      const [shouldMount, setShouldMount] = useState(false);

      const conditionResult = shouldMount || loadCondition(props);

      useEffect(() => {
        if (conditionResult) {
          setShouldMount(true);
        }
      }, [conditionResult]);

      useEffect(() => {
        if (shouldMount) {
          const loaded = dynamic(...args);

          setComponent(loaded);
        }
      }, [shouldMount]);

      return shouldMount && Component ? (<Suspense fallback={null}><Component {...props} /></Suspense>) : null;
    };
