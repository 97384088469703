import { PropsWithChildren, ReactNode } from 'react';
import { useAppContext } from '@gemini/shared/services/configuration/app-client';
import { Logo } from '@gemini/shared/ui/molecules/logo';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import { BRAND_LOGO } from '@gemini/shared/utils/testing';

export interface IBrandLogoCustomRenderProps {
  title: string;
}

export interface IBrandLogoCustomProps {
  className?: string;
  linkClassName?: string;
  dataTestId?: string;
}

export const BrandLogoCustom = ({
  linkClassName,
  dataTestId = BRAND_LOGO,
  children,
  className
}: PropsWithChildren<IBrandLogoCustomProps>) => {
  const {
    brand: { literal: brandName }
  }: any = useAppContext();

  return (
    <div data-testid={dataTestId} className={className}>
      <SmartLink href="/" ariaLabel={brandName} classes={linkClassName}>
        {children}
      </SmartLink>
    </div>
  );
};
