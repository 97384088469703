import { ProductJsonLd } from 'next-seo';
import { useRouter } from 'next/router';
import { ProductType } from '@gemini/shared/services/products/catalog';

interface IProductSchemaProps {
  product: ProductType;
}

function processSkus(
  productUrl: string,
  baseUrl?: string,
  product?: ProductType
) {
  const defaultValue = { images: [], skuIds: [], offers: [] };

  return product
    ? product.skus.reduce((previousValue: any, sku: any) => {
        const pattern = new RegExp('^(https?)://');
        let imageSrc = sku.media.large[0].src;
        if (!pattern.test(imageSrc)) {
          imageSrc = baseUrl + imageSrc;
        }
        previousValue.images.push(imageSrc);
        previousValue.skuIds.push(sku.skuId);
        previousValue.offers.push({
          availability:
            sku.inventoryStatus === 'Active'
              ? 'https://schema.org/InStock'
              : 'http://schema.org/OutOfStock',
          price: sku.prices[0].includeTax.price,
          priceCurrency: sku.prices[0].currency,
          url: productUrl,
          sku: sku.skuId
        });

        return previousValue;
      }, defaultValue)
    : defaultValue;
}

export function ProductSchema(props: IProductSchemaProps) {
  const { product } = props;
  const origin = process.env.NEXT_PUBLIC_ORIGIN;
  const router = useRouter();
  const productUrl = origin + (router ? router.asPath : '');
  const { images, skuIds, offers } = processSkus(productUrl, origin, product);
  const averageRating = {
    ratingValue: product?.averageRating,
    reviewCount: product?.totalReviewCount
  };

  return (
    <ProductJsonLd
      productName={`${product?.displayName}`}
      images={images}
      description={`${product?.shortDescription}`}
      url={productUrl}
      sku={skuIds ? skuIds[0] : ''}
      aggregateRating={averageRating}
      offers={offers}
    />
  );
}

export default ProductSchema;
