export const CheckboxOutlined = () => (
  <svg
    width={18}
    height={18}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M19 5v14H5V5h14Zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Z"
        id="a"
      />
    </defs>
    <g transform="translate(-3 -3)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fill="#000" fillOpacity={0.54}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default CheckboxOutlined;
