export const Shipping = () => (
  <svg
    width={22}
    height={16}
    viewBox="0 0 22 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-1 -4)" fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4Zm-.5 1.5 1.96 2.5H17V9.5h2.5ZM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Zm2.22-3c-.55-.61-1.33-1-2.22-1-.89 0-1.67.39-2.22 1H3V6h12v9H8.22ZM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Z" />
      </g>
    </g>
  </svg>
);

export default Shipping;
