import { useMemo } from 'react';
import { ITemplateFieldString } from '@gemini/shared/services/content/next-mantlecms';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import { FOOTER_EMAIL_SIGN_UP } from '@gemini/shared/utils/testing';
import { EmailSmsSignUpForm } from './EmailSmsSignUpForm';

interface IEmailSmsSignUpData {
  newsletterPlaceholder: ITemplateFieldString;
  newsletterSubmit: ITemplateFieldString;
  emailTermsText: ITemplateFieldString;
  newsletterSuccessText: ITemplateFieldString;
  newsletterSignupHeader: ITemplateFieldString;
  smsPlaceholder: ITemplateFieldString;
  smsTermsText: ITemplateFieldString;
}

export interface IEmailSmsSignUpCMSData {
  component: string;
  data: IEmailSmsSignUpData;
  dataMobile: IEmailSmsSignUpData | null;
}
export const emailSmsSignUpDataMapper = (data: IEmailSmsSignUpData) => {
  const {
    newsletterPlaceholder,
    newsletterSubmit,
    emailTermsText,
    newsletterSuccessText,
    newsletterSignupHeader,
    smsPlaceholder,
    smsTermsText
  } = data;

  return {
    newsletterPlaceholder: newsletterPlaceholder.data,
    submitText: newsletterSubmit.data,
    emailTermsText: emailTermsText.data,
    newsletterSuccessText: newsletterSuccessText.data,
    headerText: newsletterSignupHeader.data || 'Sign Up For Updates',
    smsPlaceholder: smsPlaceholder.data,
    smsTermsText: smsTermsText.data
  };
};

interface IEmailSmsSignUpProps {
  emailSmsSignUpData: IEmailSmsSignUpCMSData;
}

const params = {
  _SUBMIT: 'email_signup',
  LAST_SOURCE: 'gnav',
  COUNTRY_ID: '1',
  LANGUAGE_ID: '48',
  redirect_or_text: 'redirect',
  redirect: '',
  PC_EMAIL_PROMOTIONS: '1',
  VALIDATE_TERMS: '1',
  _SECONDARY_SUBMIT: 'sms',
  SMS_PROMOTIONS_PRESENT: '1'
};

export const EmailSmsSignUp = ({
  emailSmsSignUpData
}: IEmailSmsSignUpProps) => {
  const { isMobile, isDesktop } = useBreakpoint('block md:hidden');
  const emailSmsSignUpProps = useMemo(
    () => emailSmsSignUpDataMapper(emailSmsSignUpData.data),
    [emailSmsSignUpData]
  );
  const emailSmsSignUpPropsMobile = useMemo(
    () =>
      emailSmsSignUpDataMapper(
        emailSmsSignUpData.dataMobile
          ? emailSmsSignUpData.dataMobile
          : emailSmsSignUpData.data
      ),
    [emailSmsSignUpData]
  );

  return (
    <>
      {isMobile && (
        <EmailSmsSignUpForm
          {...emailSmsSignUpPropsMobile}
          emailType="footerEmail"
          smsType="footerSms"
          formEmailKey="PC_EMAIL_ADDRESS"
          formSmsKey="PHONE2"
          params={params}
          dataTestId={FOOTER_EMAIL_SIGN_UP}
          classes="md:hidden"
        />
      )}
      {isDesktop && (
        <EmailSmsSignUpForm
          {...emailSmsSignUpProps}
          emailType="footerEmail"
          smsType="footerSms"
          formEmailKey="PC_EMAIL_ADDRESS"
          formSmsKey="PHONE2"
          params={params}
          dataTestId={FOOTER_EMAIL_SIGN_UP}
          classes="hidden md:block"
        />
      )}
    </>
  );
};
