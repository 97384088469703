import { useEffect, useState } from 'react';

export const useOrientation = () => {
  const [orientation, setOrientation] = useState({
    isPortrait: true,
    isLandscape: true
  });

  useEffect(() => {
    let ORIENTATION_QUERY_LIST: MediaQueryList | null = null;
    if (typeof window !== 'undefined') {
      if (!ORIENTATION_QUERY_LIST) {
        ORIENTATION_QUERY_LIST = window.matchMedia('(orientation: portrait)');
        const isPortrait = ORIENTATION_QUERY_LIST.matches;
        const isLandscape = !isPortrait;
        setOrientation({ isPortrait, isLandscape });
      }
      const onChange = () => {
        const isPortrait = ORIENTATION_QUERY_LIST?.matches ?? false;
        const isLandscape = !isPortrait;
        setOrientation({ isPortrait, isLandscape });
      };
      ORIENTATION_QUERY_LIST.addEventListener('change', onChange);

      return () => {
        ORIENTATION_QUERY_LIST?.removeEventListener('change', onChange);
      };
    }

    return () => {
      // noop
    };
  }, []);

  return {
    isPortrait: orientation.isPortrait,
    isLandscape: orientation.isLandscape
  };
};
