export const AlipayWhite = () => (
  <svg width={78} height={22} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5 0a3.347 3.347 0 0 1 3.346 3.347V14.51c-2.314-.793-4.75-1.719-7.08-2.512 1.096-2.106 1.823-4.33 1.823-4.33h-4.233v-1.49h5.218v-.909h-5.218v-2.4h-2.43v2.4H4.71v.909h5.218v1.49H5.539v.799h8.48s-.47 1.445-1.26 2.875c-2.274-.703-4.372-1.193-6.057-1.193-4.04 0-4.936 2.03-4.745 3.886.154 1.481 1.256 3.643 4.623 3.643 3.075 0 5.558-1.763 7.086-3.86 2.914 1.34 5.991 2.994 8.18 4.213a3.348 3.348 0 0 1-3.347 3.313H3.847A3.347 3.347 0 0 1 .5 17.997V3.347A3.347 3.347 0 0 1 3.847 0H18.5Zm35.234 6.864c1.714 0 3.734 1.408 3.734 4.183s-1.959 4.979-4.51 4.979c-.757 0-1.319-.224-1.733-.538v3.864H48.49V7.704c0-.351.285-.636.636-.636h2.099v.794c.387-.353 1.264-.998 2.51-.998Zm16.596 0 2.806 5.474.068.132 2.461-5.606H77.5l-3.397 7.357-1.308 2.83-.106.206c-.666 1.186-1.87 1.54-2.427 1.605-.617.071-1.47 0-1.47 0v-1.148h.032c.255.006 2-.01 2.639-1.279l.448-1.02-4.383-8.552h2.802Zm-63.843 4.59c1.195 0 3.09.61 5.217 1.499-1.202 1.579-3.129 3.472-5.614 3.472-2.845 0-3.286-1.604-3.335-2.561-.046-.838.52-2.41 3.732-2.41Zm56.184-4.59c2.408 0 3.98.918 3.98 2.755v6.223h-2.735v-.532c-.524.325-1.308.716-2.06.716-1.286 0-3.245-.572-3.245-3.122s2.204-3.04 3.448-3.04c.838 0 1.508.156 1.857.26v-.505c0-1.082-.163-1.96-1.795-1.96-1.47 0-2.367.327-2.367.327l-.286-.612s.796-.51 3.203-.51Zm-21.404-2.98v11.958h-2.734V4.521c0-.351.285-.636.636-.636h2.098Zm5.06 3.184v8.774h-2.734V7.704c0-.351.285-.636.636-.636h2.098ZM31.775 3.886l.338.727 5.212 11.229h-3.016l-.919-1.98h-5.727l-.952 1.98h-1.848l5.751-11.956h1.161ZM53 8.292c-.626 0-1.35.607-1.775 1.03v4.704c.456.53 1.05 1.061 1.611 1.061 1.123 0 1.98-1.755 1.98-3.632 0-1.877-.878-3.163-1.816-3.163Zm9.916 2.632c-1 0-2.265.776-2.265 2.02 0 1.245.715 1.919 1.857 1.919.605 0 1.086-.297 1.408-.577v-3.042c-.186-.16-.496-.32-1-.32Zm-32.338-3.12-2.513 5.222h4.937l-2.424-5.223Zm14.382-4.72c.812 0 1.47.587 1.47 1.31 0 .723-.658 1.31-1.47 1.31-.81 0-1.469-.587-1.469-1.31 0-.723.658-1.31 1.47-1.31Z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);

export default AlipayWhite;
