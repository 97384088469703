import { useMemo } from 'react';
import { FooterCountryChooser } from '@gemini/brand/el/ui/organisms/footer-country-chooser';
import { FooterLinks } from '@gemini/brand/el/ui/organisms/footer-links';
import { FooterSignup } from '@gemini/brand/el/ui/organisms/footer-signup';
import type { TemplateType } from '@gemini/shared/services/content/next-mantlecms';

export interface IFooterSubLinksData {
  data: {
    templates?: TemplateType[];
    path?: string;
  };
}

interface IData {
  templates?: any[];
}

interface IMenu {
  class?: string;
  data?: string;
  menuReference?: string;
  html?: string;
}

function getFooterLinks(linkData: IData) {
  const footerLinks =
    linkData.templates?.map((listItem) => listItem.data) ?? [];
  const definedLinks = footerLinks.filter(Boolean);

  return definedLinks;
}

function rebuiltLinks(generalFooterLinks: IData[] | undefined) {
  const rebuiltMenu: IMenu[] | undefined = generalFooterLinks?.map(
    (column: any) => ({
      class: column.class?.data,
      menuReference: column.menuReference?.data,
      html: column.html?.data
    })
  );

  return rebuiltMenu;
}

export function FooterSubLinks({ data }: IFooterSubLinksData) {
  const generalFooterLinks: IData[] | undefined = data
    ? getFooterLinks(data)
    : undefined;

  const rebuiltMenu = useMemo(
    () => rebuiltLinks(generalFooterLinks),
    [generalFooterLinks]
  );

  const templateMap =
    data.templates?.flatMap((item) => item.data?.templates) ?? [];

  const countryChooser = templateMap.find(
    (item) => item?.component === 'CountryChooser'
  );

  const emailSignUp = templateMap.find(
    (item) => item?.component === 'EmailSignupForm'
  );

  const emailSmsSignUp = templateMap.find(
    (item) => item?.component === 'EmailSmsSignupForm'
  );

  const toolTip = templateMap.find((item) => item?.component === 'Text');

  const smsSignUp = templateMap.find((item) => item?.component === 'SmsForm');

  return (
    <div className={`md:flex m-auto w-full text-jordy-blue ${data.path || ''}`}>
      {rebuiltMenu &&
        rebuiltMenu.map((column, i) => (
          <FooterLinks
            key={i}
            menuHtml={column}
            className="block footer-desktop"
          />
        ))}

      {countryChooser ? (
        <div className="flex flex-col md:flex-row w-full m-auto md:justify-between max-w-or-footer text-jordy-blue px-1% py-0">
          <div className="w-full md:w-1/2">
            <FooterCountryChooser
              footerCountryChooserData={countryChooser.data}
            />
          </div>
          <FooterSignup
            smsSignUp={smsSignUp}
            emailSignUp={emailSignUp}
            emailSmsSignUp={emailSmsSignUp}
            toolTip={toolTip}
          />
        </div>
      ) : (
        <>
          {(smsSignUp || emailSignUp || emailSmsSignUp) && (
            <FooterSignup
              smsSignUp={smsSignUp}
              emailSignUp={emailSignUp}
              emailSmsSignUp={emailSmsSignUp}
              toolTip={toolTip}
            />
          )}
        </>
      )}
    </div>
  );
}

export default FooterSubLinks;
