import React, { createContext, useContext } from 'react';
import { IPageData } from '../interfaces';

export const defaultPageDataContext: IPageData = {
  path: '',
  metadata: {
    canonical: '',
    description: '',
    keywords: '',
    'og:description': '',
    'og:site_name': '',
    'og:title': '',
    'og:type': '',
    'og:url': '',
    robots: '',
    title: ''
  },
  title: '',
  language: '',
  created: '',
  modified: '',
  hreflangs: [],
  id: '',
  product: {
    references: {
      defaults: {
        product: null,
        category: null
      },
      categories: [],
      products: [],
      skus: []
    },
    backend: 'graphql'
  },
  section: '',
  type: ''
};

const PageDataContext = createContext(defaultPageDataContext);

export const PageDataProvider = ({
  value,
  children
}: React.PropsWithChildren<{ value: IPageData }>) => {
  return (
    <PageDataContext.Provider value={value}>
      {children}
    </PageDataContext.Provider>
  );
};

export function usePageDataContext() {
  return useContext(PageDataContext);
}
