import {
  DesktopView,
  IBasicMenurefProps,
  MobileView
} from '@gemini/brand/el/ui/templates/basic-menuref';
import styles from './BasicMenurefRenutriv.module.scss';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface IBasicMenurefRenutrivProps extends IBasicMenurefProps {}

export function BasicMenuRefRenutriv(props: IBasicMenurefRenutrivProps) {
  return (
    <>
      <div className={`hidden md:block ${styles['renutriv-menu']} h-full`}>
        <DesktopView
          {...props}
          logo="/media/boutiques/renutriv-landing/general/logo_renutriv_gnav.png"
        />
      </div>
      <div className="block md:hidden">
        <MobileView {...props} />
      </div>
    </>
  );
}

export default BasicMenuRefRenutriv;
