import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import styles from '../basic-tout.module.scss';

export interface IBasiToutLinkWrapper {
  children: JSX.Element;
  className?: string;
  link?: string;
}

const cl = (className: string) => styles[className] ?? '';

export const BasicToutLinkWrapper = ({
  children,
  className,
  link
}: IBasiToutLinkWrapper) => {
  const { isDesktop } = useBreakpoint('block md:hidden');

  return (
    <>
      {link && isDesktop ? (
        <>
          <SmartLink
            href={link}
            classes={`${cl('basic-tout__link-overlay')} ${className}`}
          />
          {children}
        </>
      ) : (
        children
      )}
    </>
  );
};
