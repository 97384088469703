import { Dropdown } from '@gemini/shared/ui/molecules/dropdown';
import { IDropdownItem } from '@gemini/shared/ui/molecules/dropdown-item';
import { LANGUAGE_DROPDOWN } from '@gemini/shared/utils/testing';

export interface ILanguageDropdownProps {
  languages: IDropdownItem[];
  dropUp?: boolean;
  showISOCode?: boolean;
  listClassName?: string;
  selectedItemClassName?: string;
  mobileFooterClassName?: string;
  mobileListClassName?: string;
  itemClassName?: string;
  footerDropdown?: string;
  hasFlag?: boolean;
}

export function LanguageDropdown(props: ILanguageDropdownProps) {
  const {
    languages,
    dropUp,
    showISOCode,
    listClassName,
    selectedItemClassName,
    mobileFooterClassName,
    mobileListClassName,
    itemClassName = '',
    footerDropdown = '',
    hasFlag
  } = props;
  const languageItems = showISOCode
    ? languages.map((language) => ({ ...language, label: language.ISO }))
    : languages;

  // @todo add handler for switching languages
  return (
    <Dropdown
      dataTestId={LANGUAGE_DROPDOWN}
      list={languageItems}
      selected={languageItems[0]}
      dropUp={dropUp}
      listClassName={listClassName}
      selectedItemClassName={selectedItemClassName}
      mobileFooterClassName={mobileFooterClassName}
      mobileListClassName={mobileListClassName}
      itemClassName={itemClassName}
      footerDropdown={footerDropdown}
      hasFlag={hasFlag}
    />
  );
}

export default LanguageDropdown;
