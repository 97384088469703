import cn from 'classnames';
import { ReactNode } from 'react';
import { Animate } from '@gemini/shared/services/configuration/utils';
import {
  ITemplateFieldCheckbox,
  ITemplateFieldColor,
  ITemplateFieldString,
  ITemplateFieldUrl,
  TemplateType
} from '@gemini/shared/services/content/next-mantlecms';
import type { MakeOptional } from '@gemini/shared/services/utils/global';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import { useScreenInfo } from '@gemini/shared/ui/utils/hooks';

export interface IMenuLinkProps
  extends MakeOptional<TemplateType, 'component' | 'metadata'> {
  data: {
    title: MakeOptional<ITemplateFieldString, 'type'>;
    url: MakeOptional<ITemplateFieldUrl, 'type'>;
    categoryExpandable?: ITemplateFieldCheckbox;
    categoryHeader?: ITemplateFieldCheckbox;
    firstLevelLink?: ITemplateFieldCheckbox;
    color?: ITemplateFieldColor;
  };
  firstElement?: boolean;
  iconArrowRight?: ReactNode;
}

// @TODO: This component should be a template

export const MenuLink = (props: IMenuLinkProps) => {
  const {
    data: {
      url,
      title,
      color,
      categoryExpandable,
      categoryHeader,
      firstLevelLink
    },
    iconArrowRight
  } = props;

  const handleArrowRightClick = () => {
    props.setAnimate(Animate.RIGHT);
    props.handleMenuLinkClick && props.handleMenuLinkClick(title);
    props.setSelectedMenuLinkHeaderTitle(title.data);
    props.setShowMenuLinkHeader(!props.showMenuLinkHeader);
    props.setShowMenuLink(!props.showMenuLink);
  };
  const { tabletAndUp } = useScreenInfo();
  const regex = /(<([^>]+)>)/gi;
  const titleDataTablet = title?.data.replace(regex, '');

  return (
    <>
      <div className="lg-up:hidden">
        {categoryHeader?.data ? (
          <div
            className="relative flex flex-col items-start justify-center font-bold list-none border-b border-solid cursor-pointer border-light-gray"
            onClick={() => {
              categoryExpandable?.data && handleArrowRightClick();
            }}
          >
            {categoryExpandable?.data ? (
              <>
                <span
                  className={cn(
                    'sm:text-[18px] sm:uppercase text-navy text-menu-link font-akzidenzgrotesk pl-5 leading-[41.1px]'
                  )}
                  dangerouslySetInnerHTML={{
                    __html: tabletAndUp ? titleDataTablet : title?.data
                  }}
                ></span>
                <button
                  className="absolute inset-y-0 appearance-none right-[18px] top-[10px] w-[22px] h-[22px]"
                  aria-label="Expand menu"
                >
                  {iconArrowRight || (
                    <Icon
                      asImg={true}
                      name="ArrowRight"
                      width="22"
                      height="22"
                      label="ArrowRight"
                    />
                  )}
                </button>
              </>
            ) : (
              <SmartLink
                href={url?.data}
                classes={cn(
                  'sm:text-[18px] text-navy text-menu-link font-akzidenzgrotesk pl-5 leading-[41.1px] w-full'
                )}
                style={{
                  color: color?.data?.hex as string
                }}
                dangerouslySetInnerHTML={{
                  __html: tabletAndUp ? titleDataTablet : title?.data
                }}
              />
            )}
          </div>
        ) : !props.hideMenuLink ? (
          <div className="relative flex flex-col items-start justify-center list-none border-b border-solid cursor-pointer border-light-gray">
            <SmartLink
              href={url?.data}
              classes={cn(
                'sm:text-[18px] text-menu-link font-akzidenzgrotesk pl-5 leading-[41.1px] w-full'
              )}
              style={{
                color: color?.data?.hex as string
              }}
              dangerouslySetInnerHTML={{
                __html: tabletAndUp ? titleDataTablet : title?.data
              }}
            />
          </div>
        ) : firstLevelLink?.data ? (
          <div
            key={`${title?.data}-mobile`}
            className={cn(
              'block',
              'menu-link cursor-pointer list-none relative flex items-center justify-center pl-5',
              'border-b border-solid border-light-gray',
              'portrait:lg:block',
              'landscape:lg:hidden'
            )}
          >
            <SmartLink
              href={url.data}
              classes={cn(
                'sm:text-[18px] text-navy leading-[41.1px] w-full text-menu-link font-akzidenzgrotesk',
                'portrait:tracking-normal'
              )}
              dangerouslySetInnerHTML={{ __html: title?.data }}
            />
          </div>
        ) : null}
      </div>
      <div className={cn('hidden lg-up:block')}>
        {url?.data ? (
          <SmartLink
            href={url?.data}
            style={{
              color: color?.data?.hex as string
            }}
            dangerouslySetInnerHTML={{ __html: title?.data }}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: title?.data }}></div>
        )}
      </div>
    </>
  );
};

export default MenuLink;
