import React from 'react';
import {
  FooterColumnsLayout,
  IFooterColumnsLayoutProps
} from '@gemini/brand/el/ui/layouts/footer-columns';
import {
  HeaderCenterLayout,
  IHeaderCenterLayoutProps
} from '@gemini/brand/el/ui/layouts/header-center';
import { PortalMount } from '@gemini/shared/ui/atoms/portal';

export interface IBasicLayoutProps {
  children?: React.ReactChild;
  data: {
    header: IHeaderCenterLayoutProps;
    footer: IFooterColumnsLayoutProps;
  };
}

export function BasicLayout({ children, data }: IBasicLayoutProps) {
  const { footer, header } = data || {};

  return (
    <>
      <PortalMount id="modal-portal" />
      {header?.data && <HeaderCenterLayout data={header.data} />}
      <main className="grow md:pt-header md:mt-[20px] lg:mt-0">{children}</main>
      {footer?.data && <FooterColumnsLayout data={footer.data} />}
    </>
  );
}

export default BasicLayout;
