/* eslint-disable @typescript-eslint/no-explicit-any */
import { updateDataLayer } from '@gemini/shared/services/analytics/data-layer';
import { processAnalyticsEvents } from '@gemini/shared/services/analytics/events';
import { jsonRPCFetch } from '@gemini/shared/services/utils/pg-jsonrpc';

type FormData = {
  params: Record<string, string>;
  method: string;
};

export const jsonRPCFetchResponse = async (res: Response) => {
  if (!res.ok || res instanceof Error) {
    return null;
  }

  const jsonResponse = await res.json();

  const result = jsonResponse[0].result.value || jsonResponse[0].result.data;

  if (result && result.dataLayer) {
    updateDataLayer(result.dataLayer);
    processAnalyticsEvents(result.dataLayer);
  }

  return jsonResponse[0];
};

export const pgFormFetch = async ({
  params,
  method
}: FormData): Promise<any> => {
  const request: any[] = [];

  request.push({
    method,
    params: [params]
  });

  const url = `/api/rpc/pgFormFetch?dbgmethod=${method}`;

  const res = await jsonRPCFetch({
    url,
    request,
    useCSRFTokenParam: true,
    useCSRFTokenHeader: true
  });

  const response: any = await jsonRPCFetchResponse(res as Response);

  return response;
};
