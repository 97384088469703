import { ITemplateFieldElcTextFormat } from '@gemini/shared/services/content/next-mantlecms';

export interface IElcTextProps {
  content: ITemplateFieldElcTextFormat;
  className?: string;
}

export const ElcText = (props: IElcTextProps) => {
  const { content, className: classes } = props;

  if (!content) {
    return null;
  }

  return (
    <div
      className={classes}
      dangerouslySetInnerHTML={{ __html: content.data }}
    />
  );
};

export default ElcText;
