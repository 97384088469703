import cn from 'classnames';
import { ITemplateFieldMantleCtaButton } from '@gemini/shared/services/content/next-mantlecms';
import { MantleCtaButton } from '@gemini/shared/ui/molecules/mantle-cta-button';
import styles from '../basic-tout.module.scss';

const cl = (className: string) => styles[className] ?? '';

interface ICtaButtonsProps {
  ctaButton1: ITemplateFieldMantleCtaButton;
  ctaButton2: ITemplateFieldMantleCtaButton;
}

export const CtaButtons = ({ ctaButton1, ctaButton2 }: ICtaButtonsProps) => {
  const ctaButton1Enabled = Boolean(ctaButton1.data.text);
  const ctaButton2Enabled = Boolean(ctaButton2.data.text);

  if (!ctaButton1Enabled && !ctaButton2Enabled) {
    return null;
  }

  return (
    <div className={cl('basic-tout__content-block__links')}>
      {ctaButton1Enabled && (
        <MantleCtaButton
          content={ctaButton1}
          className={cn(
            cl('basic-tout__content-block__line'),
            cl('basic_tout__content-block__line--link')
          )}
        />
      )}

      {ctaButton2Enabled && (
        <MantleCtaButton
          content={ctaButton2}
          className={cn(
            cl('basic-tout__content-block__line'),
            cl('basic_tout__content-block__line--link')
          )}
        />
      )}
    </div>
  );
};
