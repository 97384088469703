import { NextSeo } from 'next-seo';
import { OpenGraph } from 'next-seo/lib/types';
import {
  IBidirectionalAnnotation,
  IHrefLang,
  IMetadata
} from '@gemini/shared/services/content/next-mantlecms';

interface ISeoTagsProps {
  metadata: IMetadata;
  hreflangs: IHrefLang[];
  bidirectional: IBidirectionalAnnotation[];
}

function sanitizeHrefLang(hrefLangs: IHrefLang[]) {
  // Filter invalid iso combined format and sort by iso combined language GLSEO-545.
  const sanitizedHrefLangs =
    (!hrefLangs && []) ||
    hrefLangs
      .filter((hrefLang) => hrefLang.iso_combined.match(/\w-\w/))
      .map((hrefLang: IHrefLang) => {
        return { hrefLang: hrefLang.iso_combined, href: hrefLang.full_path };
      })
      .sort((a, b) => a.hrefLang.localeCompare(b.hrefLang));

  return sanitizedHrefLangs;
}

function parseRobots(robots: string, value: string) {
  return robots ? robots.split(',').includes(value) : false;
}

export function SeoTags(props: ISeoTagsProps) {
  const { metadata, hreflangs, bidirectional } = props;
  const { title, description, canonical, robots } = metadata
    ? metadata
    : { title: '', description: '', canonical: '', robots: '' };

  const sanitizedHrefLangs = sanitizeHrefLang(hreflangs);

  const openGraph: OpenGraph = {};
  if (metadata) {
    openGraph.title = metadata['og:title'] ?? title;
    openGraph.description = metadata['og:description'] ?? description;
    openGraph.type = metadata['og:type'] ?? '';
    openGraph.url = metadata['og:url'] ?? canonical;
    openGraph.site_name = metadata['og:site_name'] ?? '';
  }

  const noindex = parseRobots(robots, 'noindex');
  const nofollow = parseRobots(robots, 'nofollow');

  const bidirectionalAnnotation =
    bidirectional?.map((bidirectionalData: IBidirectionalAnnotation, idx) => {
      return {
        rel: 'alternate',
        href: bidirectionalData.href,
        media: bidirectionalData.media,
        keyOverride: idx.toString()
      };
    }) ?? [];

  return (
    <NextSeo
      languageAlternates={sanitizedHrefLangs}
      additionalLinkTags={bidirectionalAnnotation}
      title={title}
      description={description}
      canonical={canonical}
      openGraph={openGraph}
      noindex={noindex}
      nofollow={nofollow}
    />
  );
}

export default SeoTags;
