export const BRAND_LOGO_DESKTOP = 'brandLogoDesktop';
export const BRAND_LOGO_MOBILE = 'brandLogoMobile';
export const BRAND_LOGO = 'brandLogo';
export const LOGO = 'logo';
export const CART_OVERLAY_ICON = 'cartOverlayIcon';
export const CART_OVERLAY_ICON_COUNTER = 'cartOverlayIconCounter';
export const MAIN_MENU_MOBILE = 'mainMenuMobile';
export const MAIN_MENU_DESKTOP = 'mainMenuDesktop';
export const EMAIL_SIGN_UP_ICON = 'emailSignUpIcon';
export const EMAIL_SIGN_UP_CLOSE = 'emailSignUpClose';
export const RIGHT_MENU = 'rightMenu';
export const SEARCH_WRAPPER = 'searchWrapper';
export const SEARCH_RESULTS = 'searchResults';
export const HAMBURGER = 'hamburger';
export const HAMBURGER_CONTENT = 'hamburgerContent';
export const BREADCRUMBS = 'breadcrumbs';
export const HEADER_TOP = 'headerTop';
export const NAVIGATION_USER_HEADER = 'navigationUserHeader';
export const WISHLIST_ICON = 'wishlistIcon';
export const WISHLIST_HEADER_BTN = 'wishlistHeaderBtn';
export const WISHLIST_ICON_COUNTER = 'wishlistIconCounter';
export const LOYALTY_REGISTRATION_FORM = 'loyaltyRegistrationForm';
