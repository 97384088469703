import cn from 'classnames';
import { useRouter } from 'next/router';
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  elcServiceSearch as elcServiceSearchStyles,
  pgServiceCart as pgServiceCartStyles
} from '@gemini/brand/el/base-theme';
import {
  DesktopHeader,
  MobileHeader
} from '@gemini/brand/el/ui/organisms/navigation';
import {
  IMegamenu,
  MegamenuProvider
} from '@gemini/brand/el/ui/organisms/use-megamenu';
import { PromoBanner } from '@gemini/brand/el/ui/templates/promo-banner';
import { IEvent, root } from '@gemini/shared/services/configuration/utils';
import { SEARCH } from '@gemini/shared/services/content/cms-data-mapper';
import { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import { isCartOverlayDrawer } from '@gemini/shared/services/utils/global';
import { TemplateRenderer } from '@gemini/shared/ui/templates/template-renderer';
import {
  useBreakpoint,
  useDebouncedCallback,
  useEventListener
} from '@gemini/shared/ui/utils/hooks';

const SCROLL_TOP_BREAKPOINT = 100;

export interface IHeaderTemplate extends TemplateType {
  component: string;
  data: {
    templates: TemplateType[];
  };
}

export interface IHeaderCenterLayoutProps {
  data: {
    templates: IHeaderTemplate[];
    isSticky?: boolean;
    navItems: any;
    storeLocation?: {
      data: {
        title?: string;
        link?: string;
      };
    };
  };
}

// @todo move to /libs/shared/services/content/cms-data-mapper/src/lib/constants/templateNames.ts
enum HeaderComponent {
  CART_OVERLAY = 'CartOverlay',
  EMAIL_SIGN_UP = 'EmailSignup',
  GNAV_PRIMARY = 'Navigation',
  MENU_ITEM = 'MenuItem',
  NAVIGATION = 'Navigation',
  PROMO_BANNER = 'PromoBanner',
  UTILITIES = 'Utilities',
  UTILITY_NAV = 'UtilityNavLoyalty'
}

export function HeaderCenterLayout({ data }: IHeaderCenterLayoutProps) {
  const { isSticky = true, storeLocation } = data;
  const promoBanner = data.templates.find(
    (item) => item.component === HeaderComponent.PROMO_BANNER
  );

  const utilities = data.templates.find(
    (item) => item.component === HeaderComponent.UTILITIES
  );

  const searchData = data.templates.find(
    (item) => item.component === SEARCH
  )?.data;

  const cartOverlayData = data.templates.find(
    (item) => item.component === HeaderComponent.CART_OVERLAY
  )?.data;

  if (cartOverlayData && cartOverlayData.templates?.[0]) {
    cartOverlayData.templates[0].metadata = {
      serviceName: 'pg-service-cart',
      serviceViewName: 'cart-sidebar',
      serviceVersion: 'v3',
      templateSource: ''
    };
  }

  const gnavPrimary = data.templates.find(
    (item) => item.component === HeaderComponent.GNAV_PRIMARY
  );

  const navigation = data.templates.find(
    (item) => item.component === HeaderComponent.NAVIGATION
  );

  const navItems = navigation?.data.templates;
  const [smallDeskNav, setSmallDeskNav] = useState(false);
  const [showSubNavTout, setShowSubNavTout] = useState<boolean>(false);
  const [selectedNavItem, setSelectedNavItem] = useState<string>('');
  const [containerHeight] = useState<number>(0);
  const [isOpenHamburger, setIsOpenHamburger] = useState(false);

  const close = useCallback(() => {
    setShowSubNavTout(false);
    setSelectedNavItem('');
  }, [setShowSubNavTout]);
  const show = useCallback((target: string) => {
    setSelectedNavItem(target);
    setShowSubNavTout(true);
  }, []);
  const hide = useCallback(() => {
    setSelectedNavItem('');
    setShowSubNavTout(false);
  }, []);

  const megamenu = useMemo<IMegamenu>(
    () => ({
      close,
      show,
      hide,
      active: selectedNavItem
    }),
    [close, show, hide, selectedNavItem]
  );

  const scrollHandler = useDebouncedCallback(
    (event: IEvent) => {
      const target = event.target.scrollingElement;

      if (target.scrollTop > SCROLL_TOP_BREAKPOINT && !isOpenHamburger) {
        setSmallDeskNav(true);
      } else {
        setSmallDeskNav(false);
      }
    },
    50,
    []
  );

  useEventListener(root, 'scroll', scrollHandler);

  const triggerSearchOverlay = () => {
    const button: HTMLElement = document.getElementsByClassName(
      'elc-icon-search'
    )[0] as HTMLElement;
    button && button.click();
  };

  const { isMobile, isDesktop } = useBreakpoint('block md:hidden');

  // close hamburger and megamenu on route change
  const router = useRouter();
  useEffect(() => {
    const handlePageChange = () => {
      setIsOpenHamburger(false);
      setSelectedNavItem('');
      setShowSubNavTout(false);
    };
    router.events.on('routeChangeStart', handlePageChange);

    return () => {
      router.events.off('routeChangeStart', handlePageChange);
    };
  }, [router]);

  const onMouseLeave = useCallback<MouseEventHandler>(
    (e) => {
      const elem = e.target as HTMLElement;
      if (isDesktop && e.clientY <= elem.getBoundingClientRect().top) {
        close();
      }
    },
    [close, isDesktop]
  );

  return (
    <>
      <nav
        className={cn(
          'z-20 sticky top-0 w-full bg-white/100 border-b-[1px] border-b-gray-80 md:border-b-0 transition-all bg-white',
          {
            'z-30 border-b-0': isOpenHamburger,
            // @TODO - this was added just to reproduce the bug on production; remove once we get a green light
            'animate-fadeInDown': isSticky && smallDeskNav && !isMobile
          }
        )}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={cn(
            'flex items-center justify-center overflow-hidden transition-all duration-300 ease-linear max-h-screen'
          )}
        >
          <div className="w-full mx-auto text-white bg-navy">
            {promoBanner && <PromoBanner data={promoBanner.data} />}
          </div>
        </div>
        {isDesktop && gnavPrimary && (
          <div className="relative">
            <div
              className={cn({
                'absolute top-0 left-0 w-full header-center-layout md:min-h-[80px]':
                  isSticky
              })}
            >
              <div
                className={cn(
                  `hidden md:block transition-all duration-300 ease-linear`,
                  {
                    'bg-white/100': !isSticky || smallDeskNav || showSubNavTout,
                    'md:bg-white/[.65]':
                      isSticky && !smallDeskNav && !showSubNavTout
                  }
                )}
              >
                <MegamenuProvider value={megamenu}>
                  <DesktopHeader
                    gnavPrimary={gnavPrimary}
                    containerHeight={containerHeight}
                    setShowSubNavTout={setShowSubNavTout}
                    setSelectedNavItem={setSelectedNavItem}
                    smallView={smallDeskNav}
                    navLinks={navItems}
                    utilities={utilities}
                    searchData={searchData}
                    isOpenHamburger={isOpenHamburger}
                    setIsOpenHamburger={setIsOpenHamburger}
                    triggerSearchOverlay={triggerSearchOverlay}
                  />
                </MegamenuProvider>
              </div>
            </div>
          </div>
        )}
        <div className={elcServiceSearchStyles.search}>
          {searchData &&
            searchData.templates.map((template, index) => (
              <TemplateRenderer
                {...template}
                key={`${template.component}${index}`}
              />
            ))}
        </div>
        {isMobile && (
          <div className="block md:hidden">
            <MobileHeader
              gnavPrimary={gnavPrimary}
              utilities={utilities}
              searchData={searchData}
              menuItems={navItems}
              isOpenHamburger={isOpenHamburger}
              setIsOpenHamburger={setIsOpenHamburger}
              storeLocation={storeLocation}
              triggerSearchOverlay={triggerSearchOverlay}
            />
          </div>
        )}
      </nav>
      {isCartOverlayDrawer() && cartOverlayData && (
        <div className={pgServiceCartStyles.cart}>
          {cartOverlayData.templates.map((template, index) => (
            <TemplateRenderer
              {...template}
              key={`${template.component}${index}`}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default HeaderCenterLayout;
