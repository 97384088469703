import { useUpdateAtom } from 'jotai/utils';
import { ITemplateFieldString } from '@gemini/shared/services/content/next-mantlecms';
import { InnerHtml } from '@gemini/shared/ui/atoms/inner-html';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import {
  loyaltySignupModal,
  ModalPosition
} from '@gemini/shared/ui/state/loyalty-signup';
import { alfrescoLoader } from '@gemini/shared/ui/utils/image';
import {
  getFirstName,
  getLoyaltyLevel,
  getLoyaltyLevelName,
  getLoyaltyMemberStatus,
  getLoyaltyPoints
} from '@gemini/shared/utils/browser-storage';

export interface IFooterOffersProps {
  data: {
    loyaltyOfferImage: ITemplateFieldString;
    loyaltyOfferElistHeader: ITemplateFieldString;
    loyaltyOfferHeader: ITemplateFieldString;
    loyaltyOfferText: ITemplateFieldString;
    specialOfferImage: ITemplateFieldString;
    specialOfferHeader: ITemplateFieldString;
    specialOfferText: ITemplateFieldString;
    specialOfferCta: ITemplateFieldString;
    joinedHeader: ITemplateFieldString;
    joinSubHeader: ITemplateFieldString;
    points: ITemplateFieldString;
    loyaltyStatus: ITemplateFieldString;
    level: ITemplateFieldString;
    zeroPoints: ITemplateFieldString;
    accountDetails: ITemplateFieldString;
    benefitsText: ITemplateFieldString;
  };
  isDesktopFooterVisible: boolean;
  showFeed: boolean;
  setShowFeed: (showFeed: boolean) => void;
  language?: string;
}

const LoyaltyMemberText = ({ data }: { data: IFooterOffersProps['data'] }) => {
  const firstName = getFirstName();
  const loyaltyPoints = getLoyaltyPoints();
  const loyaltyLevelName = getLoyaltyLevelName();
  const loyaltyLevel = getLoyaltyLevel();

  return (
    <div className="ml-5">
      <span className="text-[22px] font-optimaroman">
        {`${data.joinedHeader.data} ${firstName}!`}
      </span>
      <InnerHtml
        classes="py-2 text-[16px] font-optimaroman"
        content={`${loyaltyPoints} ${data?.points.data}`}
      />
      <InnerHtml
        classes="my-1 text-[11px] loyalty-offer-text"
        content={`<strong>${data.loyaltyStatus.data}</strong> ${data.level.data} ${loyaltyLevel}, ${loyaltyLevelName}`}
      />
      <InnerHtml
        classes="my-1 text-[12px] uppercase md:w-2/3"
        content={data?.zeroPoints?.data}
      />
      <SmartLink href="account/loyalty/index.tmpl#rewards">
        <span className="uppercase underline text-[11px] hover:no-underline">
          {data.accountDetails.data}
        </span>
      </SmartLink>
    </div>
  );
};

export const FooterOffers = ({
  data,
  isDesktopFooterVisible,
  showFeed,
  setShowFeed,
  language
}: IFooterOffersProps) => {
  const hideFeed = () => {
    setShowFeed(false);
  };
  const isLoyaltyMember = getLoyaltyMemberStatus();
  const setLoyaltySignupModal = useUpdateAtom(loyaltySignupModal);

  const getTemplate = () => {
    return (
      <div className="flex p-7 w-100 sticky-feed-widget">
        <div className="flex w-1/2 ">
          <SmartImage
            width={164}
            height={161}
            src={data?.loyaltyOfferImage?.data}
            alt="loyalty-offer-image"
            style={{ height: '161px' }}
            loader={alfrescoLoader}
          />
          {isLoyaltyMember ? (
            <LoyaltyMemberText data={data} />
          ) : (
            <div className="relative ml-5">
              <span className="text-[22px] font-optimaroman">
                {data?.loyaltyOfferElistHeader?.data}
              </span>
              <InnerHtml
                classes="py-2 text-[16px] font-optimaroman"
                content={data?.loyaltyOfferHeader?.data}
              />
              <button
                onClick={() =>
                  setLoyaltySignupModal({
                    isOpen: true,
                    position: ModalPosition.CENTER
                  })
                }
              >
                <InnerHtml
                  classes="my-1 text-[16px] loyalty-offer-text"
                  content={data?.loyaltyOfferText?.data}
                />
              </button>
            </div>
          )}
        </div>
        <div className="flex w-1/2 ">
          <SmartImage
            width={164}
            height={161}
            style={{ height: '161px' }}
            src={data?.specialOfferImage?.data}
            alt="loyalty-offer-image"
            loader={alfrescoLoader}
          />
          <div className="ml-5">
            <InnerHtml
              classes="mt-3"
              content={data?.specialOfferHeader?.data}
            />
            <InnerHtml
              classes="my-1 text-[16px] font-optimaroman"
              content={data?.specialOfferText?.data}
            />
            <InnerHtml
              classes="my-1 pt-5 text-[16px] font-optimaroman"
              content={data?.specialOfferCta?.data}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`w-100 sticky-feed ${
        showFeed
          ? !isDesktopFooterVisible
            ? 'show-fixed-feed animate-fadeInUp'
            : 'show-feed animate-slideTop'
          : 'show-feed animate-slideBottom'
      }`}
      onMouseLeave={hideFeed}
    >
      {getTemplate()}
      <button
        className="absolute top-[6px] right-[2px] z-[3]"
        onClick={hideFeed}
      >
        <Icon width={40} height={20} fill="#ffffff" name="ArrowDown" />
      </button>
    </div>
  );
};

export default FooterOffers;
