export const Loading = () => (
  <svg
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm0 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Z"
        id="a"
      />
      <path
        d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0v2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10h2c0 6.627-5.373 12-12 12Z"
        id="c"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fill="#E0E0E0">
        <path d="M0 0h24v24H0z" />
      </g>
      <mask id="d" fill="#fff">
        <use xlinkHref="#c" />
      </mask>
      <g mask="url(#d)" fill="#000" fillOpacity={0.54}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default Loading;
