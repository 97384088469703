import { AllHTMLAttributes, FC, useMemo } from 'react';
import { useSmartImageSetup } from '@gemini/shared/ui/molecules/smart-image';

export type ISmartSourceProps = AllHTMLAttributes<HTMLSourceElement>;

export const SmartSource: FC<ISmartSourceProps> = ({ src, ...rest }) => {
  const { transformSrc } = useSmartImageSetup();

  const transformedSrc = useMemo(() => {
    return src && transformSrc ? transformSrc({ src }) : src;
  }, [src, transformSrc]);

  return <source src={transformedSrc} {...rest} />;
};
