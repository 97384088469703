import { AllHTMLAttributes, FC, useEffect, useMemo, useRef } from 'react';
import { useSmartImageSetup } from '@gemini/shared/ui/molecules/smart-image';

export type ISmartVideoProps = AllHTMLAttributes<HTMLVideoElement>;

export const SmartVideo: FC<ISmartVideoProps> = ({
  src,
  children,
  autoPlay,
  loop,
  ...rest
}) => {
  const { transformSrc } = useSmartImageSetup();

  const videoRef = useRef<HTMLVideoElement>(null);

  const transformedSrc = useMemo(() => {
    return src && transformSrc ? transformSrc({ src }) : src;
  }, [src, transformSrc]);

  useEffect(() => {
    /*
      Chrome sometimes fails to play the video under certain circunstances
    */
    if (autoPlay && loop && videoRef.current?.paused) {
      const playerPromise = videoRef.current?.play();

      if (playerPromise !== undefined) {
        const isPlaying = (ref: HTMLVideoElement) => {
          return (
            ref.currentTime > 0 &&
            !ref.paused &&
            !ref.ended &&
            ref.readyState > 2
          );
        };

        playerPromise.catch((error) => {
          const ref = videoRef.current;

          if (error.name === 'NotAllowedError' && ref && !isPlaying(ref)) {
            ref.addEventListener('click', function () {
              this.play();
            });
          }
        });
      }
    }
  }, [autoPlay, loop, videoRef.current]);

  return (
    <video
      ref={videoRef}
      src={transformedSrc}
      {...rest}
      loop={loop}
      autoPlay={autoPlay}
    >
      {children}
    </video>
  );
};
