export const Check = () => (
  <svg
    width={18}
    height={14}
    viewBox="0 0 18 14"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-3 -5)" fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
      </g>
    </g>
  </svg>
);

export default Check;
