export const ArrowLeft = (props: any) => {
  return (
    <svg
      width={8}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g transform="translate(-8 -1)" fill="current" fillRule="evenodd">
        <g fill="#000" fillOpacity={0.54}>
          <path d="M15.705 7.41 14.295 6l-6 6 6 6 1.41-1.41-4.58-4.59z" />
        </g>
      </g>
    </svg>
  );
};

export default ArrowLeft;
