import { ITemplateFieldString } from '@gemini/shared/services/content/next-mantlecms';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';

export interface ISignInLinkn {
  signIn: ITemplateFieldString;
  href: string;
}

export interface ISignInLinkProps {
  data: ISignInLinkn;
}

export function SignInLink({ data }: ISignInLinkProps) {
  const { signIn, href } = data;

  return (
    <SmartLink external={true} href={href} classes={'signed-out-link'}>
      <span className={'signed-out-link__content'}>{signIn.data}</span>
    </SmartLink>
  );
}

export default SignInLink;
