import { useRouter } from 'next/router';
import {
  createContext,
  DependencyList,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Direction, root } from '@gemini/shared/services/configuration/utils';
import { isBrowser } from '@gemini/shared/services/utils/global';

export { useDrag } from '@use-gesture/react';
export { useInViewport } from 'react-in-viewport';

export interface ILinkConfig<T> {
  name: string;
  disabled?: boolean;
}

export interface IUseTransformChainConfig<T> {
  links: ILinkConfig<T>[];
  initialData: T;
}

const computeInputIndexes = <T>(links: ILinkConfig<T>[]) =>
  links.reduce((acc, targetLink, i) => {
    let index = -1;
    if (i === 0) {
      index = -1;
    } else {
      let idx = i - 1;
      let link = links[idx];
      while (link && link.disabled) {
        idx -= 1;
        link = links[idx];
      }
      index = link ? idx : -1;
    }

    acc[targetLink.name] = index;

    return acc;
  }, {} as Record<string, number>);

const computeValueIndexes = <T>(links: ILinkConfig<T>[]) =>
  links.reduce((acc, link, index) => {
    acc[link.name] = index;

    return acc;
  }, {} as Record<string, number>);

const computeLastValueIndex = <T>(links: ILinkConfig<T>[]) => {
  for (let i = links.length - 1; i >= 0; i -= 1) {
    const link = links[i];
    if (!link.disabled) {
      return i;
    }
  }

  return -1;
};

/**
 * Utility to propagate input from one transformation/link to the next and allows to specify which transform is active or not.
 * If a link of a chain is disabled the previous link will be used as source and so on until the default value.
 *
 * Example:
 * Assume the chain is: 'default value' -> 'a' -> 'b' -> 'c'.
 * Then getInput('c') will return 'b'.
 * If 'b' is disabled then getInput('c') will return 'a'.
 * If 'a' and 'b' are both disabled then getInput('c') will return 'default value'.
 *
 * This is useful when you want to chain transformation but some of them may be disabled.
 * Using the getInput('name') method will abstract the fact that some links in the chain are disabled.
 */
export const useTransformChain = <T>(config: IUseTransformChainConfig<T>) => {
  const { initialData, links } = config;
  const [values, setValues] = useState<T[]>(() =>
    Array(links.length).fill(initialData)
  );

  const [inputIndexes, setInputIndexes] = useState(() =>
    computeInputIndexes(links)
  );
  const [valueIndexes, setValueIndexes] = useState(() =>
    computeValueIndexes(links)
  );
  const [lastValueIndex, setLastValueIndex] = useState(() =>
    computeLastValueIndex(links)
  );

  const setValue = (name: string, value: T) => {
    setValues((v) => {
      const valueIndex = valueIndexes[name];
      const newValues = v.slice();
      newValues[valueIndex] = value;

      return newValues;
    });
  };

  const getInput = (name: string) => {
    const inputIndex = inputIndexes[name];

    return inputIndex === -1 ? initialData : values[inputIndex];
  };

  const getValue = (name: string) => {
    const valueIndex = valueIndexes[name];

    return valueIndex === -1 ? initialData : values[valueIndex];
  };

  useEffect(() => {
    setValues((v) => [...v]);
  }, [initialData]);
  useEffect(() => {
    setInputIndexes(() => computeInputIndexes(links));
    setValueIndexes(() => computeValueIndexes(links));
    setLastValueIndex(() => computeLastValueIndex(links));
  }, [JSON.stringify(links)]);

  const getFinalValue = () => {
    return values.length > 0 && lastValueIndex >= 0
      ? values[lastValueIndex]
      : initialData;
  };

  return {
    values,
    getFinalValue,
    setValue,
    getInput,
    getValue
  };
};

export const containsNodeInParent = (child: Node, parent: Element) => {
  let node: Node | null = child;
  if (node === parent) {
    return true;
  }

  while (node.parentElement) {
    node = node?.parentElement;
    if (node === parent) {
      return true;
    }
  }

  return false;
};

const handleClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: () => void,
  event: Event
) => {
  // had to make our own logic until we have a version with bug https://github.com/facebook/react/issues/20325 fixed
  if (ref.current && !containsNodeInParent(event.target as Node, ref.current)) {
    event.stopPropagation();
    handler();
  }
};

export const useOnMouseElementEnter = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  handler: React.MouseEventHandler,
  deps?: DependencyList
) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const element = ref.current as T | undefined;

    const onMouseEnter: React.MouseEventHandler = (event) => {
      if (event.target === ref.current) {
        handler(event);
      }
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    element?.addEventListener('mouseenter', onMouseEnter, {
      capture: true
    });

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      element?.removeEventListener('mouseenter', onMouseEnter, {
        capture: true
      });
    };
  }, [handler, ref, enabled, ...(deps || [])]);
  useEffect(() => {
    setEnabled(true);
  }, []);
};

export const useOnMouseElementLeave = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  handler: React.MouseEventHandler,
  deps?: DependencyList
) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const element = ref.current as T | undefined;

    const onMouseLeave: React.MouseEventHandler = (event) => {
      if (event.target === ref.current) {
        handler(event);
      }
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    element?.addEventListener('mouseleave', onMouseLeave, {
      capture: true
    });

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      element?.removeEventListener('mouseleave', onMouseLeave, {
        capture: true
      });
    };
  }, [handler, ref, enabled, ...(deps || [])]);
  useEffect(() => {
    setEnabled(true);
  }, []);
};

export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: () => void
) => {
  useEffect(() => {
    const onMouseDown = (event: Event) =>
      handleClickOutside(ref, handler, event);

    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('touchstart', onMouseDown);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('touchstart', onMouseDown);
    };
  }, [handler]);
};

let prefersReducedMotionQuery: MediaQueryList | null = null;

export const usePrefersReducedMotion = () => {
  const [value, setValue] = useState(
    () => prefersReducedMotionQuery?.matches ?? false
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && window.matchMedia) {
      if (!prefersReducedMotionQuery) {
        prefersReducedMotionQuery = window.matchMedia(
          '(prefers-reduced-motion: reduce)'
        );
        setValue(prefersReducedMotionQuery?.matches);
      }
      const onChange = () => {
        setValue(prefersReducedMotionQuery?.matches ?? false);
      };
      if (prefersReducedMotionQuery?.addEventListener) {
        prefersReducedMotionQuery?.addEventListener('change', onChange);
      } else {
        prefersReducedMotionQuery?.addListener(onChange);
      }

      return () => {
        if (prefersReducedMotionQuery?.removeEventListener) {
          prefersReducedMotionQuery?.removeEventListener('change', onChange);
        } else {
          prefersReducedMotionQuery?.removeListener(onChange);
        }
      };
    }

    return () => {
      // noop
    };
  }, []);

  return value;
};

export const useScrollDirection = (() => {
  let previousYScroll = 0;

  return (currentScroll: number) => {
    const direction =
      previousYScroll === currentScroll
        ? Direction.Neutral
        : previousYScroll > currentScroll
        ? Direction.Up
        : Direction.Down;

    setTimeout(() => {
      previousYScroll = currentScroll;
    }, 0);

    return direction;
  };
})();

const currentTimestamp = (): number =>
  globalThis.performance?.now() ?? Date.now();

export const useThrottle = <
  Callback extends (...args: readonly any[]) => unknown,
  Deps extends readonly any[]
>(
  callback: Callback,
  time = 50,
  deps: Deps = [] as unknown as Deps
) => {
  const state = useMemo(() => ({ current: currentTimestamp() }), []);
  const result = useMemo<{ current: ReturnType<Callback> | null }>(
    () => ({ current: null }),
    []
  );

  return useCallback(
    (...args: Parameters<Callback>): ReturnType<Callback> => {
      const invokationTime = currentTimestamp();

      if (invokationTime - state.current >= time) {
        result.current = callback(...args) as ReturnType<Callback>;
        state.current = invokationTime;
      }

      return result.current as ReturnType<Callback>;
    },
    [time, ...deps]
  );
};

export const useDebounce = (fn: (arg: any) => void, wait = 333) => {
  let inThrottle: boolean;
  let lastFn: ReturnType<typeof setTimeout>;
  let lastTime: number;

  return function (this: any, ...args: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    const argument = args;
    if (!inThrottle) {
      fn.apply(context, argument);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, argument);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export const useOnWindowScroll = (fn: EventListener) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', fn);

      return () => window.removeEventListener('scroll', fn);
    }

    return () => {
      /* noop */
    };
  }, [fn]);
};

export enum DimensionsResize {
  WIDTH = 'width',
  HEIGHT = 'height',
  ALL = 'all'
}

export const useOnResize = <T extends HTMLElement>(
  ref: RefObject<T>,
  onResize: () => void,
  dimensions: DimensionsResize = DimensionsResize.ALL
) => {
  const lastWidth = useRef<number>(-1);
  const lastHeight = useRef<number>(-1);

  useEffect(() => {
    const e = ref.current;
    lastWidth.current = e ? e.clientWidth : -1;
    lastHeight.current = e ? e.clientHeight : -1;
  }, [ref.current]);

  useEffect(() => {
    const e = ref.current;
    if (e && typeof ResizeObserver !== 'undefined') {
      const observer = new ResizeObserver(() => {
        const w = e.clientWidth;
        const h = e.clientHeight;
        let shouldNotify = false;

        if (lastWidth.current !== w) {
          lastWidth.current = w;

          if (
            [DimensionsResize.ALL, DimensionsResize.WIDTH].includes(dimensions)
          ) {
            shouldNotify = true;
          }
        }
        if (lastHeight.current !== h) {
          lastHeight.current = h;
          if (
            [DimensionsResize.ALL, DimensionsResize.HEIGHT].includes(dimensions)
          ) {
            shouldNotify = true;
          }
        }
        if (shouldNotify) {
          onResize();
        }
      });
      observer.observe(e);

      return () => {
        observer.disconnect();
      };
    }

    return () => {
      /* noop */
    };
  }, [ref.current, onResize]);
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0
  });

  useEffect(() => {
    if (isBrowser()) {
      const handleResize = () => {
        setWindowSize({
          width: root.innerWidth,
          height: root.innerHeight
        });
      };

      root.addEventListener('resize', handleResize);

      handleResize();

      return () => root.removeEventListener('resize', handleResize);
    }

    return () => null;
  }, []);

  return windowSize;
};

export const useEventListener = (
  target: any,
  eventType: string,
  handler: any
) => {
  if (isBrowser()) {
    target.addEventListener(eventType, handler);

    return () => {
      target.removeEventListener(eventType, handler);
    };
  }

  return () => null;
};

export const useSPARouter = (allowedPaths: string[]) => {
  const router = useRouter();

  useEffect(() => {
    const paths = allowedPaths.map((path) => `/${path}`);

    router.beforePopState(({ url, as, options }) => {
      // `as`: The relative path of the page we're going to

      if (!paths.includes(as)) {
        // This says to not use next/router unless the new URL is in `paths`, AND
        // the user is navigating via the back/forward button
        window.location.href = as;

        return false;
      }

      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);
};

export const useCursorProgress = () => {
  const router = useRouter();
  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      router.events.on('routeChangeStart', () =>
        body.classList.add('cursor-progress')
      );

      router.events.on('routeChangeComplete', () =>
        body.classList.remove('cursor-progress')
      );

      router.events.on('routeChangeError', () =>
        body.classList.remove('cursor-progress')
      );
    }
  }, [router]);
};

export const useProductPage = () => {
  const { route } = useRouter();

  return route.includes('/product/');
};

export const useDebouncedValue = <T>(value: T, timeout: number): T => {
  const [staleValue, setStaleValue] = useState<T>(value);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const updateValue = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setStaleValue(value);
    }, timeout);
  }, [value]);

  useEffect(updateValue, [value]);

  return staleValue;
};

/**
 * Returns a debounced function for the callback
 * @param callback The callback function
 * @param timeout Timeout for debounce
 *
 * If the callback or the timeout changes previous scheduled calls to the callback functions will not be performed.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const useDebouncedFunction = <T extends Function>(
  callback: T,
  timeout: number
) => {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const fnRef = useRef(callback);

  const debouncedFn = useMemo<T>(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    fnRef.current = callback;

    const deferredFn = (...args: unknown[]) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => fnRef.current?.(...args), timeout);
    };

    return deferredFn as unknown as T;
  }, [callback, timeout]);

  return debouncedFn;
};

/**
 * Generates a debounced callback function given a function, timeout and dependencies.
 * @param fn The function
 * @param timeout Timeout
 * @param deps Dependencies
 *
 * If the callback, timeout or dependencies change previous scheduled calls to the callback functions will not be performed.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const useDebouncedCallback = <T extends Function>(
  fn: T,
  timeout: number,
  deps: DependencyList
) => {
  const callback = useCallback(fn, deps);

  return useDebouncedFunction(callback, timeout);
};

// only triggers if value has changed
export const useDebounceEffect = (
  callback: (...args: any) => any,
  wait = 500,
  deps?: DependencyList
) => {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    timerRef && timerRef.current && clearTimeout(timerRef.current);

    timerRef.current = setTimeout((...args) => {
      callback.apply(this, args);
    }, wait);

    return () => {
      timerRef && timerRef.current && clearTimeout(timerRef.current);
    };
  }, deps);
};
