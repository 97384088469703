export const Pinterest = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-2 -2)" fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M21.375 11.687a9.39 9.39 0 0 1-1.309 4.844 9.789 9.789 0 0 1-3.535 3.535 9.39 9.39 0 0 1-4.844 1.309 9.43 9.43 0 0 1-2.851-.43c.625-1.041 1.016-1.888 1.172-2.539l.625-2.304c.156.312.442.572.86.78.416.21.872.313 1.366.313a4.39 4.39 0 0 0 2.559-.8c.768-.534 1.367-1.257 1.797-2.168.43-.912.644-1.908.644-2.989 0-1.08-.26-2.05-.78-2.91a5.423 5.423 0 0 0-2.15-2.012c-.91-.481-1.926-.722-3.046-.722-1.328 0-2.5.273-3.516.82a5.573 5.573 0 0 0-2.129 2.129 5.817 5.817 0 0 0-.761 2.91c0 .755.188 1.504.566 2.246.378.742.853 1.244 1.426 1.504.182.078.286.04.312-.117l.274-1.094a.271.271 0 0 0-.078-.273 3.108 3.108 0 0 1-.508-.996 3.985 3.985 0 0 1-.196-1.23c0-.73.176-1.414.528-2.052a4.07 4.07 0 0 1 1.504-1.543c.677-.416 1.458-.625 2.343-.625 1.198 0 2.175.372 2.93 1.114.755.742 1.133 1.686 1.133 2.832 0 1.302-.293 2.37-.879 3.203-.586.833-1.309 1.25-2.168 1.25-.495 0-.885-.176-1.172-.528-.286-.351-.377-.761-.273-1.23.052-.286.169-.703.351-1.25.287-.964.43-1.634.43-2.012 0-.377-.104-.69-.313-.937-.208-.248-.507-.371-.898-.371-.495 0-.911.221-1.25.664-.338.443-.508 1.002-.508 1.68 0 .572.091 1.054.274 1.445L8.21 17.937c-.156.652-.17 1.576-.04 2.774-1.848-.703-3.339-1.875-4.472-3.516C2.566 15.555 2 13.72 2 11.687a9.39 9.39 0 0 1 1.309-4.843 9.789 9.789 0 0 1 3.535-3.535A9.39 9.39 0 0 1 11.687 2a9.39 9.39 0 0 1 4.844 1.309 9.789 9.789 0 0 1 3.535 3.535 9.39 9.39 0 0 1 1.309 4.843Z" />
      </g>
    </g>
  </svg>
);

export default Pinterest;
