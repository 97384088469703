import { ITemplateFieldOriginsCustomTextFormat } from '@gemini/shared/services/content/next-mantlecms';

interface ICustomTextProps {
  options: ITemplateFieldOriginsCustomTextFormat;
  content: string;
  className?: string;
}

export function CustomText(props: ICustomTextProps) {
  const { options, content, className: classes } = props;
  const {
    data: { html_tag: Element = false, font_style: fontStyle }
  } = options;

  if (!Element) {
    return null;
  }

  let fontStyleClasses: string | false = '';
  if (fontStyle) {
    fontStyleClasses = fontStyle;
  }

  return (
    <Element
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      className={`${classes} ${fontStyleClasses}`}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}
