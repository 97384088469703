import cn from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { useOnClickOutside } from '@gemini/shared/ui/utils/hooks';
import styles from './FooterCountryChooser.module.scss';

export interface IFooterCountryChooserData {
  countryChooserHeading: {
    data: string;
  };
  countryChooserDefault: {
    data: string;
  };
  countryChooserMenu: {
    data: string;
  };
}

export interface IFooterCountryChooserProps {
  footerCountryChooserData: IFooterCountryChooserData;
}

export function FooterCountryChooser({
  footerCountryChooserData
}: IFooterCountryChooserProps) {
  const [isActive, setIsActive] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    wrapperRef,
    useCallback(() => setIsActive(false), [])
  );

  return (
    <div className={styles['country-chooser']}>
      <div className="country-chooser__header footer-header">
        {footerCountryChooserData.countryChooserHeading.data}
      </div>
      <div ref={wrapperRef} className="country-chooser__dropdown">
        <div
          className={cn('country-chooser__selector', { active: isActive })}
          onClick={(e) => {
            e.preventDefault();
            setIsActive(!isActive);
          }}
        >
          <div>{footerCountryChooserData.countryChooserDefault.data}</div>
          <div
            className={cn(
              'css-caret',
              isActive ? 'css-caret--up' : 'css-caret--down'
            )}
          />
        </div>
        <div
          className="field-menu-wrapper"
          dangerouslySetInnerHTML={{
            __html: footerCountryChooserData.countryChooserMenu.data
          }}
        ></div>
      </div>
    </div>
  );
}

export default FooterCountryChooser;
