export interface ILink {
  text: string;
  url: string;
  'aria-label'?: string;
}

export function Link({
  children,
  className,
  ...otherProps
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  return (
    <a className={`${className || ''}`} {...otherProps}>
      {children}
    </a>
  );
}
