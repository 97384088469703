import cn from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import {
  DropdownItem,
  IDropdownItem
} from '@gemini/shared/ui/molecules/dropdown-item';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import { DropdownList } from '@gemini/shared/ui/molecules/lists';
import { useOnClickOutside } from '@gemini/shared/ui/utils/hooks';

export interface IDropdownProps {
  selected: IDropdownItem;
  list: IDropdownItem[];

  handler?(value: IDropdownItem | string, index: number): void;

  disableFirst?: boolean;
  dataTestId?: string;
  dropUp?: boolean;
  dropUpDefaultMaxHeight?: string;
  listClassName?: string;
  mobileFooterClassName?: string;
  mobileListClassName?: string;
  mobileSelectedItemFooterDropdown?: boolean;
  selectedItemContainerClassName?: string;
  selectedItemClassName?: string;
  itemClassName?: string;
  footerDropdown?: string;
  hasFlag?: boolean;
  innerTextClassName?: string;
  setDropdownIcon?: (shadeData: IDropdownItem) => JSX.Element;
  dropdownListClasses?: string;
  dropdownItemClasses?: string;
  cssDropdownArrow?: boolean;
  dropdownCallback?: (value: boolean) => void;
  isDefaultOnMobile?: boolean;
}

export function Dropdown(props: IDropdownProps) {
  const {
    selected,
    list,
    handler,
    disableFirst,
    dataTestId,
    listClassName = '',
    mobileFooterClassName = '',
    mobileListClassName = '',
    mobileSelectedItemFooterDropdown,
    selectedItemClassName,
    selectedItemContainerClassName,
    itemClassName,
    footerDropdown,
    dropUp,
    dropUpDefaultMaxHeight,
    innerTextClassName,
    setDropdownIcon,
    dropdownListClasses,
    dropdownItemClasses,
    cssDropdownArrow,
    dropdownCallback,
    isDefaultOnMobile = true
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelected] = useState(selected);

  React.useEffect(() => {
    setSelected(selected);
  }, [selected]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const clickHandler = (item: IDropdownItem, index: number) => {
    if (handler) {
      handler(item, index);
    }
    setIsOpen(false);
    setSelected(item);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    dropdownCallback && dropdownCallback(!isOpen);
  };

  useOnClickOutside(
    wrapperRef,
    useCallback(() => {
      setIsOpen(false);
      dropdownCallback && dropdownCallback(false);
    }, [])
  );

  return (
    <>
      <div
        className={cn(
          'relative z-[1]',
          mobileFooterClassName,
          isDefaultOnMobile ? 'block md:hidden' : 'hidden'
        )}
      >
        <span className="absolute top-1/2 -translate-y-1/2 right-2 z-[-1]">
          {mobileSelectedItemFooterDropdown ? (
            <Icon asImg name={'ArrowDownFooter'} width="14" height="14" />
          ) : (
            <Icon
              asImg
              name="ArrowDown"
              fill="#000000"
              width="12"
              height="12"
              label="ArrowDown"
            />
          )}
        </span>
        <select
          data-testid={dataTestId ? `${dataTestId}_MOBILE` : null}
          className={cn(
            'relative border w-fit custom-arrow',
            mobileListClassName
          )}
          value={selected.value ?? selected.label}
          onChange={({ target }) =>
            handler?.(target?.value?.toString(), target.selectedIndex)
          }
        >
          {list.map((item) => (
            <option value={item.value ?? item.label} key={item.label}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div
        data-testid={dataTestId ? `${dataTestId}_DESKTOP` : null}
        aria-label="Select an option"
        role="listbox"
        ref={wrapperRef}
        className={cn(
          'relative border rounded',
          isOpen ? 'shadow-md border-0' : 'border-gray-400',
          isDefaultOnMobile ? 'hidden md:block' : 'block',
          listClassName
        )}
      >
        <DropdownItem
          hasFlag
          selected
          isOpen={isOpen}
          onClick={toggleOpen}
          item={selectedValue}
          itemClassName={selectedItemClassName}
          innerTextClassName={innerTextClassName}
          classes={selectedItemContainerClassName}
          dropdownItemClasses={dropdownItemClasses}
          footerDropdown={footerDropdown}
          setDropdownIcon={setDropdownIcon}
          cssDropdownArrow={cssDropdownArrow}
        />
        <DropdownList
          classes={cn(
            'absolute w-full z-10',
            dropUp &&
              !dropUpDefaultMaxHeight &&
              'bottom-full overflow-auto max-h-48',
            dropUp &&
              dropUpDefaultMaxHeight &&
              `bottom-full overflow-auto ${dropUpDefaultMaxHeight}`,
            dropdownListClasses
          )}
          isOpen={isOpen}
        >
          {list.map((item: IDropdownItem, index: number) => {
            const classes = cn(
              itemClassName || 'bg-light hover:bg-gray-100',
              {
                'pointer-events-none opacity-80': disableFirst && index === 0,
                'bg-dark-gray': footerDropdown && selectedValue === item
              },
              'flex items-center cursor-pointer p-2'
            );

            const onClick = () => clickHandler(item, index);

            return (
              <DropdownItem
                onClick={onClick}
                classes={classes}
                key={index.toString()}
                item={item}
                selected={false}
                innerTextClassName={innerTextClassName}
                setDropdownIcon={setDropdownIcon}
              />
            );
          })}
        </DropdownList>
      </div>
    </>
  );
}

export default Dropdown;
