export const Accessibility = () => {
  return (
    <svg width={63} height={24} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="m35.086 11.513-4.187-4.197a.461.461 0 0 0-.655 0l-.892.894a.464.464 0 0 0 0 .656l2.973 2.98-2.973 2.982a.464.464 0 0 0 0 .656l.892.894c.18.182.474.182.655 0l4.187-4.198a.461.461 0 0 0 .134-.333.462.462 0 0 0-.134-.334"
          fill="#B9DCF4"
        />
        <path
          d="M24.912 11.916a6.723 6.723 0 0 1 5.128-6.534C27.916 2.141 24.258 0 20.1 0h-8.214C5.32 0 0 5.335 0 11.916c0 6.58 5.321 11.915 11.886 11.915H20.1c4.158 0 7.816-2.14 9.94-5.382a6.723 6.723 0 0 1-5.128-6.533M51.037 0h-8.215c-4.128 0-7.763 2.112-9.894 5.315a6.726 6.726 0 0 1 5.41 6.6 6.726 6.726 0 0 1-5.41 6.601c2.131 3.204 5.766 5.315 9.894 5.315h8.215c6.564 0 11.885-5.335 11.885-11.916C62.922 5.335 57.601 0 51.037 0"
          fill="#B9DCF4"
        />
        <path
          d="M24.578 17.26a.632.632 0 0 0-.873-.194l-.768.492-2.861-3.827-.012-.008a.942.942 0 0 0-.737-.36h-4.678v-1.28h3.255a.95.95 0 0 0 .948-.952.95.95 0 0 0-.948-.95h-3.255V7.63a.95.95 0 1 0-1.898 0v6.684a.95.95 0 0 0 .95.95h5.925l2.653 3.525a.631.631 0 0 0 .845.153l1.261-.808a.635.635 0 0 0 .193-.875"
          fill="#133C65"
        />
        <path
          d="M18.316 15.805a.79.79 0 0 0-.978.543 4.575 4.575 0 0 1-4.378 3.303c-2.511 0-4.554-2.048-4.554-4.565a4.594 4.594 0 0 1 2.923-4.265.793.793 0 0 0-.566-1.48 6.188 6.188 0 0 0-3.939 5.745c0 3.391 2.753 6.15 6.136 6.15a6.164 6.164 0 0 0 5.898-4.45.793.793 0 0 0-.542-.98M13.66 5.222a1.38 1.38 0 0 0 1.38-1.383 1.38 1.38 0 0 0-1.38-1.382 1.38 1.38 0 0 0-1.378 1.382 1.38 1.38 0 0 0 1.379 1.383M43.13 13.48h10.188V4.377H43.13v9.101Zm-1.442 1.492H54.76V2.885H41.688v12.087ZM51.86 20.276h1.582v-1.585h-1.581v1.585Zm-2.213 0h1.581v-1.585h-1.58v1.585Zm-2.214 0h1.582v-1.585h-1.582v1.585Zm-2.213 0h1.58v-1.585h-1.58v1.585Zm-2.214 0h1.581v-1.585h-1.581v1.585Zm1.107-2.12h1.581v-1.585h-1.581v1.585Zm2.214 0h1.58v-1.585h-1.58v1.585Zm2.213 0h1.582v-1.585H48.54v1.585Zm2.214 0h1.581v-1.585h-1.581v1.585Zm-9.076 2.653H54.75v-4.746H41.678v4.746ZM60.84 22.363h-.54v1.468h-.433v-1.468h-.53v-.335h1.504zM61.593 22.028l.402 1.254.4-1.254h.57v1.803h-.434v-.421l.042-.862-.435 1.283h-.287l-.436-1.284.042.863v.42h-.434v-1.802z"
          fill="#133C65"
        />
      </g>
    </svg>
  );
};

export default Accessibility;
