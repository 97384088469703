export const Plus = (props: any) => {
  return (
    <svg
      width={13}
      height={13}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g transform="translate(-5 -5)" fill="none" fillRule="evenodd">
        <g fill="#000" fillOpacity={0.54}>
          <path d="M11 5v6H5v1h6v6h1v-6h6v-1h-6V5z" />
        </g>
      </g>
    </svg>
  );
};

export default Plus;
