import * as React from 'react';
import { useState } from 'react';
import { IconTypes, togglesMap } from '@gemini/shared/ui/atoms/toggles-map';
import { ACCORDION } from '@gemini/shared/utils/testing';

export interface IAccordionProps {
  title: string;
  children: React.ReactNode;
  isOpen?: boolean;
  className?: string;
  onOpenClassName?: string;
  contentClassName?: string;
  titleClassName?: string;
  iconType?: IconTypes;
}

export const Accordion = ({
  title,
  children,
  isOpen = false,
  className = '',
  onOpenClassName = '',
  contentClassName = '',
  titleClassName = '',
  iconType = IconTypes.default
}: IAccordionProps) => {
  const [isOpenState, setIsOpenState] = useState(isOpen);
  const toggleOpen = () => setIsOpenState(!isOpenState);

  const Opened = togglesMap[iconType].opened;
  const Closed = togglesMap[iconType].closed;

  return (
    <div
      data-testid={ACCORDION}
      className={`w-full my-2 text-gray-800 ${className} ${
        isOpenState ? onOpenClassName : ''
      }`}
    >
      <div
        className={`flex flex-row items-center justify-between w-full p-2 cursor-pointer ${titleClassName}`}
        onClick={toggleOpen}
      >
        <div className="flex pl-2 ">{title}</div>
        <div className="flex pr-2 text-gray-600">
          {isOpenState ? <Opened /> : <Closed />}
        </div>
      </div>
      {isOpenState && (
        <div className={`flex pl-4 pb-2 ${contentClassName}`}>{children}</div>
      )}
    </div>
  );
};

export default Accordion;
