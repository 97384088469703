import { FC, PropsWithChildren } from 'react';
import { IMegamenu } from './IMegamenu';
import { MegamenuContext } from './MegamenuContext';

export interface IMegamenuProviderProps {
  value: IMegamenu;
}

export const MegamenuProvider: FC<
  PropsWithChildren<IMegamenuProviderProps>
> = ({ children, value }) => (
  <MegamenuContext.Provider value={value}>{children}</MegamenuContext.Provider>
);
