import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useCallback,
  useState
} from 'react';
import { noop } from '@gemini/shared/services/utils/global';

type PageFormatterContextValueType = number;
type PageFormatterContextIndexesValueType = number[];
type PageFormatterContextType = {
  activeIndex: PageFormatterContextValueType;
  indexes: PageFormatterContextIndexesValueType;
  setActiveIndex: Dispatch<React.SetStateAction<PageFormatterContextValueType>>;
  addIndex: (value: number) => void;
};

export const defaultPageFormatterContextValue: PageFormatterContextValueType = 0;
export const defaultPageFormatterContextIndexesValue: PageFormatterContextIndexesValueType =
  [];

const defaultPageFormatterContext: PageFormatterContextType = {
  activeIndex: defaultPageFormatterContextValue,
  setActiveIndex: noop,
  indexes: defaultPageFormatterContextIndexesValue,
  addIndex: noop
};

export const PageFormatterContext = createContext<PageFormatterContextType>(
  defaultPageFormatterContext
);

export const PageFormatterProvider = ({ children }: PropsWithChildren) => {
  const [activeIndex, setActiveIndex] = useState(
    defaultPageFormatterContextValue
  );

  const [indexes, setIndexes] = useState(
    defaultPageFormatterContextIndexesValue
  );

  const addIndex = useCallback((element: number) => {
    setIndexes((prev) => [...prev, element]);
  }, []);

  return (
    <PageFormatterContext.Provider
      value={{ activeIndex, setActiveIndex, indexes, addIndex }}
    >
      {children}
    </PageFormatterContext.Provider>
  );
};
