export const ArrowDropRight = () => (
  <svg
    width={5}
    height={10}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="a" d="m10 17 5-5-5-5z" />
    </defs>
    <g transform="translate(-10 -7)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fill="#000" fillOpacity={0.54}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default ArrowDropRight;
