import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import {
  ITemplateFieldCheckbox,
  ITemplateFieldElcTextFormat,
  ITemplateFieldMantleCustomTextFormat,
  ITemplateFieldMantleTemplate,
  ITemplateFieldString,
  TemplateType
} from '@gemini/shared/services/content/next-mantlecms';
import { ElcText } from '@gemini/shared/ui/molecules/elc-text';
import { MantleCustomText } from '@gemini/shared/ui/molecules/mantle-custom-text';

export interface IFaqItemProps {
  data: {
    header?:
      | ITemplateFieldString
      | ITemplateFieldElcTextFormat
      | ITemplateFieldMantleCustomTextFormat;
    copy?:
      | ITemplateFieldString
      | ITemplateFieldElcTextFormat
      | ITemplateFieldMantleCustomTextFormat;
    openedOnLoad?: ITemplateFieldCheckbox;
    widthRules?: ITemplateFieldMantleTemplate;
  };
}

export interface IFaqItemTemplateItemData {
  type: string;
  textfield?: string;
  textarea?: string;
  data: any;
}

export const getSuitableTemplate = (
  field: any,
  className = '',
  forcedTag = 'div'
) => {
  const Element = forcedTag as unknown as typeof React.Component;
  if (field.type === 'string') {
    return (
      <Element
        className={className}
        dangerouslySetInnerHTML={{ __html: field.data }}
      />
    );
  }
  if (field.type === 'elc_text_format') {
    return <ElcText content={field} className={className} />;
  }
  if (field.type === 'mantle_custom_text_format') {
    return <MantleCustomText options={field} className={className} />;
  }

  return null;
};

const getValueFromFaqItem = (data: IFaqItemTemplateItemData) => {
  let value;
  if (data.type) {
    switch (data.type) {
      case 'string':
      case 'elc_text_format':
        value = data.data;
        break;
      case 'mantle_custom_text_format':
        value = data.data.text_content;
        break;
      default:
        value = '';
    }
  } else {
    if (data.textfield || data.textarea) {
      value = data.data.text_content;
    }
  }

  return value || '';
};

export const getQuestionAndAnswer = (faqItem: TemplateType) => {
  const data = faqItem.data.schema ? faqItem.data.schema : faqItem.data;

  return {
    question: getValueFromFaqItem(data.header),
    answer: getValueFromFaqItem(data.copy)
  };
};

export const FaqItem = ({
  data: { header, copy, openedOnLoad }
}: IFaqItemProps) => {
  const [faqOpened, setFaqOpened] = useState<boolean>(
    Boolean(openedOnLoad?.data)
  );
  const router = useRouter();

  useEffect(() => setFaqOpened(Boolean(openedOnLoad?.data)), [router?.asPath]);

  return (
    <div className="faq">
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={() => setFaqOpened(!faqOpened)}
      >
        {header && getSuitableTemplate(header, 'faq__headline', 'h3')}
        <span className={`faq-plus ${faqOpened ? 'open' : ''}`} />
      </div>
      {copy &&
        getSuitableTemplate(copy, `faq__content ${!faqOpened ? 'hidden' : ''}`)}
    </div>
  );
};

export default FaqItem;
