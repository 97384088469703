import cn from 'classnames';
import { useState } from 'react';
import { TemplateType } from '@gemini/shared/services/content/next-mantlecms';
import { Link } from '@gemini/shared/ui/atoms/links';
import { IconTypes, togglesMap } from '@gemini/shared/ui/atoms/toggles-map';
import { TemplateRenderer } from '@gemini/shared/ui/templates/template-renderer';

export interface ISubNav extends TemplateType {
  showAccordions?: boolean;
  data: { templates: TemplateType[] };
}

export interface INavItemData {
  label: string;
  path: string;
  subNav?: ISubNav;
  iconType?: IconTypes;
  redirectOnClick?: boolean;
}

export interface INavItemCommon {
  toggleSubOpen(): void;
  subOpen?: boolean;
  showAccordions?: boolean;
}
export interface INavItem extends INavItemCommon {
  data: INavItemData;
}

export interface INavItemMobile extends INavItemCommon {
  subNav?: ISubNav;
  navItem: INavItemData;
}

function SubNav({ subNav }: { subNav: ISubNav }) {
  return (
    <>
      {(subNav.data.templates || []).map((data, key) => (
        <TemplateRenderer
          key={key}
          {...data}
          showAccordions={subNav.showAccordions}
        />
      ))}
    </>
  );
}

function NavItemMobile({
  subNav,
  navItem,
  toggleSubOpen,
  subOpen,
  showAccordions
}: INavItemMobile) {
  const { iconType, label, redirectOnClick, path } = navItem;
  const [subNavVisibility, setSubNavVisibility] = useState(false);
  const icon = iconType || IconTypes.default;
  const [menuOpen, setMenuOpen] = useState(false);
  const Opened = togglesMap[icon].opened;
  const Closed = togglesMap[icon].closed;

  const expandNav = () => {
    setSubNavVisibility(true);
    if (!showAccordions) {
      toggleSubOpen();
    } else {
      setMenuOpen(!menuOpen);
    }
  };
  const closeNav = () => {
    setSubNavVisibility(false);
    toggleSubOpen();
  };
  const onNavClick = () => !redirectOnClick && expandNav();

  return (
    <>
      <div
        className={cn(
          'justify-between p-4',
          (!subOpen && !showAccordions) || (showAccordions && subNavVisibility)
            ? 'hidden'
            : 'flex'
        )}
      >
        <Link
          href={!subNav?.data || redirectOnClick ? path : '#'}
          onClick={onNavClick}
          className="w-4/5"
        >
          {label}
        </Link>
        {subNav && subNav.data ? (
          <Closed className="w-auto" onClick={expandNav} />
        ) : (
          ''
        )}
      </div>
      {subNav && subNav.data && subNavVisibility && (
        <>
          <Link href="#">
            <div
              className={`p-4 flex ${
                showAccordions
                  ? 'flex-row-reverse justify-between items-center'
                  : ''
              }`}
              onClick={closeNav}
            >
              <Opened />
              <div className={`${!showAccordions ? 'pl-4' : ''}`}>{label}</div>
            </div>
          </Link>
          <SubNav subNav={subNav} />
        </>
      )}
    </>
  );
}

export function NavItem(props: INavItem) {
  const { data: navItem, toggleSubOpen, subOpen, showAccordions } = props;

  const subNav = navItem.subNav;

  const [subNavVisibility, setSubNavVisibility] = useState(true);

  const bannerNavigationMenuClasses = cn(
    'hidden group-hover:flex absolute top-full left-0 gap-10 py-8 px-20 leading-loose bg-light w-screen border-b border-t border-solid border-gray-300 z-10'
  );

  const menuItemClasses = cn('py-2 hidden md:block', {
    'border-b-2 border-solid border-transparent hover:border-gray-800 transition group':
      subNav && subNav.data
  });

  return (
    <>
      <div
        data-testid="navItem"
        className={menuItemClasses}
        key={navItem.path}
        onMouseEnter={() =>
          subNavVisibility ? null : setSubNavVisibility(true)
        }
      >
        {navItem.path ? (
          <Link href={navItem.path}>{navItem.label}</Link>
        ) : (
          <div className="cursor-pointer">{navItem.label}</div>
        )}
        {subNav && subNav.data && subNavVisibility && (
          <div className={bannerNavigationMenuClasses}>
            <SubNav subNav={subNav} />
          </div>
        )}
      </div>
      <div
        data-testid="navItemMobile"
        key={navItem.label}
        className="block w-full md:hidden"
      >
        <NavItemMobile
          navItem={navItem}
          subNav={subNav}
          toggleSubOpen={toggleSubOpen}
          subOpen={subOpen}
          showAccordions={showAccordions}
        />
      </div>
    </>
  );
}

export default NavItem;
