export const Home = () => (
  <svg
    width={20}
    height={17}
    viewBox="0 0 20 17"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-2 -3)" fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </g>
    </g>
  </svg>
);

export default Home;
