export enum EColor {
  DARK = 'dark',
  LIGHT = 'light'
}

export enum EFont {
  SERIF = 'serif',
  SANS_SERIF = 'sans-serif',
  MONO = 'monospace'
}

export enum EFontSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export enum EAlign {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

export enum EPosition {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom'
}

export interface ITextProps {
  value: string;
}

export function Text({ value }: ITextProps) {
  return <div>{value}</div>;
}

export default Text;
