export function Button({
  children,
  className,
  dataTestId,
  ...otherProps
}: React.ButtonHTMLAttributes<HTMLButtonElement> & { dataTestId?: string }) {
  return (
    <button
      className={
        className ||
        'flex items-center justify-center p-2 bg-gray-100 text-xs text-gray-900 rounded-sm'
      }
      data-testid={dataTestId}
      {...otherProps}
    >
      {children}
    </button>
  );
}
