import cn from 'classnames';
import { Flags } from '@gemini/shared/ui/atoms/flags';
import { ArrowDown, ArrowUp } from '@gemini/shared/ui/atoms/icons';
import { Shade } from '@gemini/shared/ui/atoms/shade';
import { Icon } from '@gemini/shared/ui/molecules/icon';

export interface IDropdownItem {
  [key: string]: string;
}

interface IItem {
  item: IDropdownItem;
  hasFlag?: boolean;
  itemClass?: string;
}
export interface IDropdownItemProps extends IItem {
  classes?: string;
  itemClassName?: string;
  onClick(): void;
  selected: boolean;
  isOpen?: boolean;
  footerDropdown?: string;
  hasFlag?: boolean;
  innerTextClassName?: string;
  cssDropdownArrow?: boolean;
  setDropdownIcon?(shadeData: IDropdownItem): JSX.Element;
  dropdownItemClasses?: string;
}

const Item = (props: IItem) => {
  const {
    item: { name, shade, label, hasFlag },
    itemClass = ''
  } = props;

  return (
    <div className={`flex items-center whitespace-nowrap ${itemClass}`}>
      {shade && <Shade width={5} height={5} color={shade} />}
      {name && hasFlag && <Flags name={name} label={label} />}
      <div>{label}</div>
    </div>
  );
};

const SelectedListItem = ({
  classes,
  itemClassName = '',
  onClick,
  isOpen,
  item,
  footerDropdown = '',
  cssDropdownArrow,
  dropdownItemClasses = '',
  hasFlag,
  setDropdownIcon,
  innerTextClassName = ''
}: Omit<IDropdownItemProps, 'selected'>) => (
  <div
    className={cn(
      'flex items-center current cursor-pointer',
      isOpen &&
        (!footerDropdown
          ? `${
              dropdownItemClasses
                ? dropdownItemClasses
                : 'border-b border-gray-400'
            }`
          : ''),
      classes
    )}
    onClick={onClick}
  >
    <div
      className={`grow border-gray-400 flex px-4 py-2 align-center selected-item ${itemClassName}`}
      aria-label={`Select ${item.label}`}
      role="option"
      aria-selected={true}
    >
      {setDropdownIcon ? setDropdownIcon(item) : ''}
      <Item item={item} hasFlag={hasFlag} itemClass={innerTextClassName} />
    </div>
    <div
      className={
        cssDropdownArrow !== true
          ? `flex-none mx-4 auto ${footerDropdown}`
          : `${footerDropdown}`
      }
    >
      {cssDropdownArrow !== true ? (
        footerDropdown ? (
          <Icon
            asImg
            name={isOpen ? 'ArrowUpFooter' : 'ArrowDownFooter'}
            width="14"
            height="14"
          />
        ) : isOpen ? (
          <ArrowUp />
        ) : (
          <ArrowDown fill="#000000" />
        )
      ) : isOpen ? (
        <div className="css-caret css-caret--up" />
      ) : (
        <div className="css-caret css-caret--down" />
      )}
    </div>
  </div>
);

const ListItem = ({
  onClick,
  item,
  classes,
  innerTextClassName,
  setDropdownIcon
}: Pick<
  IDropdownItemProps,
  'onClick' | 'classes' | 'item' | 'innerTextClassName' | 'setDropdownIcon'
>) => (
  <li onClick={onClick} role="option" aria-label={`Select ${item.label}`}>
    <a href={item.url} className={classes}>
      {setDropdownIcon ? setDropdownIcon(item) : ''}
      <Item item={item} itemClass={innerTextClassName} />
    </a>
  </li>
);

export const DropdownItem = (props: IDropdownItemProps) => {
  if (props.selected) {
    return <SelectedListItem {...props} />;
  }

  return <ListItem {...props} />;
};

export default DropdownItem;
