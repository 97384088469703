export const Delete = () => (
  <svg
    width={14}
    height={18}
    viewBox="0 0 14 18"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-5 -3)" fill="none" fillRule="evenodd">
      <g fill="#000" fillOpacity={0.54}>
        <path d="M18.984 3.984V6H5.016V3.984h3.48L9.528 3h4.968l1.032.984h3.456ZM6 18.984v-12h12v12c0 .544-.2 1.016-.6 1.416-.4.4-.872.6-1.416.6H8.016C7.472 21 7 20.8 6.6 20.4c-.4-.4-.6-.872-.6-1.416Z" />
      </g>
    </g>
  </svg>
);

export default Delete;
