import {
  DesktopView,
  IBasicMenurefProps,
  MobileView
} from '@gemini/brand/el/ui/templates/basic-menuref';
import styles from './BasicMenurefAerin.module.scss';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface IBasicMenurefAerinProps extends IBasicMenurefProps {}

export function BasicMenuRefAerin(props: IBasicMenurefAerinProps) {
  return (
    <>
      <div className={`hidden md:block ${styles['aerin-menu']} h-full`}>
        <DesktopView
          {...props}
          logo="/media/boutiques/aerin-landing/general/logo_aerin_purple_gnav.png"
        />
      </div>
      <div className="block md:hidden">
        <MobileView {...props} />
      </div>
    </>
  );
}

export default BasicMenuRefAerin;
