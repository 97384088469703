export const CarouselRight = () => (
  <svg
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <circle id="a" cx={12} cy={12} r={12} />
      <path
        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5Zm-1.35 5.969 3.66 3.812a1.053 1.053 0 0 1 0 1.438l-3.66 3.812a.941.941 0 0 1-1.086.21l-.048-.025-.025-.05a1.048 1.048 0 0 1 .2-1.136L13.076 12 9.69 8.47a1.044 1.044 0 0 1-.203-1.133l.024-.049.049-.025a.956.956 0 0 1 1.088.206Z"
        id="c"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fill="#FFF">
        <path d="M0 0h24v24H0z" />
      </g>
      <mask id="d" fill="#fff">
        <use xlinkHref="#c" />
      </mask>
      <g mask="url(#d)" fill="#000" fillOpacity={0.88}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default CarouselRight;
