export const ChatBubbleFilled = () => {
  return (
    <svg
      width={20}
      height={20}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(-2 -2)" fill="none" fillRule="evenodd">
        <g fill="#000" fillOpacity={0.64}>
          <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2Z" />
        </g>
      </g>
    </svg>
  );
};

export default ChatBubbleFilled;
