import { FC, PropsWithChildren } from 'react';
import { ISmartImageSetup } from './ISmartImageSetup';
import { SmartImageSetupContext } from './SmartImageSetupContext';

export interface ISMartImageSetupProps {
  setup: ISmartImageSetup;
}

export const SmartImageSetup: FC<PropsWithChildren<ISMartImageSetupProps>> = ({
  setup,
  children
}) => (
  <SmartImageSetupContext.Provider value={setup}>
    {children}
  </SmartImageSetupContext.Provider>
);
