import { useCallback, useEffect, useState } from 'react';
import {
  IconArrowLeft,
  IconArrowRight,
  IconClose
} from '@gemini/brand/el/base-theme';
import { useTranslations } from '@gemini/shared/services/content/translations';
import {
  IconConfigKeys,
  IPromoBannerProps as ISharedPromoBannerProps,
  PromoBanner as SharedPromoBanner
} from '@gemini/shared/ui/organisms/promo-banner';
import { getStoredBannerViewed, setStoredBannerViewed } from './banner';
import { PromoBannerScript } from './PromoBannerScript';

export type IPromoBannerProps = Pick<
  ISharedPromoBannerProps,
  'iconConfig' | 'data'
>;

const promoBannerIconConfig: IPromoBannerProps['iconConfig'] = {
  [IconConfigKeys.ARROW]: {
    width: 18,
    height: 18,
    fill: '#FFFFFF'
  },
  [IconConfigKeys.CLOSE]: {
    width: 12,
    height: 12,
    fill: '#FFFFFF'
  }
};

export function PromoBanner({
  data,
  iconConfig = promoBannerIconConfig
}: IPromoBannerProps) {
  const [active, setActive] = useState(true);
  const [mounted, setMounted] = useState(false);

  const {
    elc_general: { next: nextLabel, previous: prevLabel, close: closeLabel }
  } = useTranslations();

  const onActiveChanged = useCallback((isActive: boolean) => {
    setActive(isActive);
    setStoredBannerViewed(!isActive);
  }, []);

  useEffect(() => {
    if (mounted) {
      setActive(!getStoredBannerViewed());
    }
  }, [mounted]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <PromoBannerScript />
      <SharedPromoBanner
        data={data}
        active={active}
        onActiveChange={onActiveChanged}
        iconConfig={iconConfig}
        nextLabel={nextLabel}
        nextIcon={<IconArrowRight {...iconConfig?.arrow} />}
        prevLabel={prevLabel}
        prevIcon={<IconArrowLeft {...iconConfig?.arrow} />}
        closeLabel={closeLabel}
        closeIcon={<IconClose {...iconConfig?.close} />}
      />
    </>
  );
}

export default PromoBanner;
