export const FOOTER_SIGN_UP = 'footerSignUp';
export const FOOTER_SOCIAL_SHARE = 'footerSocialShare';
export const COPYRIGHT = 'copyright';
export const REGION_DROPDOWN = 'regionDropdown';
export const LANGUAGE_DROPDOWN = 'languageDropdown';
export const FOOTER_LEGAL_SECTION = 'footerLegalSection';
export const FOOTER_LINKS = 'footerLinks';
export const FOOTER_BOTTOM = 'footerBottom';
export const FOOTER_LINKSGROUP = 'footerLinksGroup';
export const FOOTER_LINKSTOP = 'footerLinksTop';
export const FOOTER_MAIN = 'footerMain';
export const FOOTER_TOP = 'footerTop';
export const ACCORDION = 'accordion';
export const FOOTER_EMAIL_SIGN_UP = 'footerEmailSignUp';
export const FOOTER_SMS_SIGN_UP = 'footerSmsSignUp';
