import {
  ITemplateFieldCheckbox,
  ITemplateFieldMantleCtaButton,
  ITemplateFieldMantleCustomTextFormat,
  ITemplateFieldSelect
} from '@gemini/shared/services/content/next-mantlecms';
import { MantleCustomText } from '@gemini/shared/ui/molecules/mantle-custom-text';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import styles from '../basic-tout.module.scss';
import { BasicToutLinkWrapper } from './BasicToutLinkWrapper';
import { CtaButtons } from './CtaButtons';

export interface IDisplayContentBelowImage {
  type: 'component_group';
  data: {
    eyebrowBelowImage: ITemplateFieldMantleCustomTextFormat;
    headerBelowImage: ITemplateFieldMantleCustomTextFormat;
    contentBelowImage: ITemplateFieldMantleCustomTextFormat;
    textAlignmentBelowImage: ITemplateFieldSelect;
    ctaButtonBelowMedia: ITemplateFieldMantleCtaButton;
    ctaButton2BelowMedia: ITemplateFieldMantleCtaButton;
    contentBelowImageHideMobile: ITemplateFieldCheckbox;
    contentBelowImageHidePc: ITemplateFieldCheckbox;
  };
}

interface IContentBelowImage {
  content: IDisplayContentBelowImage;
  link?: string;
}

const cl = (className: string) => styles[className] ?? '';

const processOptions = (
  data: ITemplateFieldMantleCustomTextFormat
): ITemplateFieldMantleCustomTextFormat => {
  if (!data.data) {
    return data;
  }

  return {
    ...data,
    data: {
      ...data.data,
      font_style: cl(data.data.font_style),
      font_color: cl(data.data.font_color)
    }
  };
};

export const ContentBelowImage = ({ content, link }: IContentBelowImage) => {
  if (!content.data) {
    return null;
  }

  const { isMobile, isDesktop } = useBreakpoint('block md:hidden');

  const {
    eyebrowBelowImage,
    headerBelowImage,
    contentBelowImage,
    ctaButtonBelowMedia,
    ctaButton2BelowMedia,
    contentBelowImageHideMobile,
    contentBelowImageHidePc
  } = content.data;

  const hideMobile = isMobile && Boolean(contentBelowImageHideMobile.data);
  const hideDesktop = isDesktop && Boolean(contentBelowImageHidePc.data);

  return (
    <div className={cl('basic-tout__content-below-block')}>
      {(!hideMobile || !hideDesktop) && (
        <BasicToutLinkWrapper link={link}>
          <div className={cl('basic-tout__content-below')}>
            {eyebrowBelowImage?.data.text_content && (
              <MantleCustomText
                options={processOptions(eyebrowBelowImage)}
                className={cl('basic-tout__eyebrow')}
              />
            )}
            {headerBelowImage?.data.text_content && (
              <MantleCustomText
                options={processOptions(headerBelowImage)}
                className={cl('basic-tout__header')}
              />
            )}
            {contentBelowImage?.data.text_content && (
              <MantleCustomText
                options={processOptions(contentBelowImage)}
                className={cl('basic-tout__content')}
              />
            )}
            <CtaButtons
              ctaButton1={ctaButtonBelowMedia}
              ctaButton2={ctaButton2BelowMedia}
            />
          </div>
        </BasicToutLinkWrapper>
      )}
    </div>
  );
};
