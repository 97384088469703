import cn from 'classnames';
import * as React from 'react';

export interface IDropdownListProps {
  classes: string;
  isOpen: boolean;
  children: React.ReactNode;
}

export const DropdownList = ({
  classes,
  isOpen,
  children
}: IDropdownListProps) => (
  <ul
    className={cn(classes, 'list leading-relaxed', isOpen ? 'block' : 'hidden')}
  >
    {children}
  </ul>
);

export default DropdownList;
