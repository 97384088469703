export const AfterPay = () => (
  <svg width={22} height={14} xmlns="http://www.w3.org/2000/svg">
    <g fill="none">
      <path
        d="m11.597 5.776 2.245-1.3c-.248-.436-.187-.33-.413-.742-.24-.435-.15-.606.345-.61 1.441-.01 2.882-.011 4.323-.004.428.003.532.186.317.563a376.26 376.26 0 0 1-2.16 3.748c-.233.397-.436.396-.679.007-.249-.4-.182-.3-.45-.753l-2.238 1.3c.044.115.1.189.147.27.554.964.802 1.425 1.375 2.38.679 1.13 2.102 1.198 2.91.153.09-.116.173-.24.246-.367 1.36-2.355 2.72-4.71 4.072-7.07.137-.24.26-.5.32-.767A1.695 1.695 0 0 0 20.298.512a619.454 619.454 0 0 0-8.742.007 1.713 1.713 0 0 0-1.542 2.51c.19.38.418.74.631 1.11l.952 1.637"
        fill="#306E9A"
      />
      <path
        d="m4.77 13.053.004-2.585s-.358-.004-.842-.004c-.493 0-.595-.174-.352-.6a347.96 347.96 0 0 1 2.15-3.711c.216-.366.407-.398.642.009.715 1.238 1.433 2.476 2.138 3.72.226.397.124.571-.33.586h-.871v2.592h3.031c1.308-.019 2.076-1.206 1.582-2.42a3.25 3.25 0 0 0-.192-.393c-1.344-2.34-2.686-4.681-4.038-7.017a2.798 2.798 0 0 0-.498-.656 1.681 1.681 0 0 0-2.605.39A617.176 617.176 0 0 0 .24 10.47a1.7 1.7 0 0 0 1.384 2.573c.421.027 2.218.011 3.147.011"
        fill="#1F4066"
      />
    </g>
  </svg>
);

export default AfterPay;
